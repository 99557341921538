<template>
  <component-tooltip custom-class="bg-white border-1" :placement="placement" hold-on-mouseover>
    <component-icon class="cursor-help" :class="iconClass || 'text-blue-600 text-2xl'">info</component-icon>
    <template #tooltip>
      <div
        :id="`info-${id}`"
        ref="tooltipEl"
        class="text-sm leading-tight text-gray-900 bg-white font-normal"
        :class="customClass"
        role="tooltip"
      >
        <slot name="content" />
      </div>
    </template>
  </component-tooltip>
</template>

<script>
  import {onBeforeMount, ref} from "vue";

  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";

  export default {
    name: "ComponentInfoIcon",

    components: {
      ComponentTooltip,
      ComponentIcon,
    },

    props: {
      iconClass: {
        type: String,
        default: null,
      },
      placement: {
        type: String,
        default: "right-start",
        // top, top-start, top-end
        // right, right-start, right-end
        // bottom, bottom-start, bottom-end
        // left, left-start, left-end
      },
      customClass: {
        type: String,
        default: "",
      },
    },

    setup(props) {
      const id = ref(null);

      onBeforeMount(() => {
        id.value = "info-" + Math.random().toString(36).slice(4);
      });

      return {id};
    },
  };
</script>
