<template>
  <template v-if="indicationsOptions?.length > 0">
    <medication-component-indication-checkbox-row
      v-for="(indication, index) in indicationsOptions"
      ref="selectList"
      :key="indication.abdata_key_miv + '.' + hashId + '-' + index"
      :is-checked="isChecked(indication)"
      class="pb-3"
      :data-hash-id="hashId"
      :indication-data="indication"
      @set-indication="setIndication"
    />
  </template>

  <template v-else>
    <div class="text-sm pb-3">Keine Indikation nach ABDATA hinterlegt.</div>
  </template>
</template>

<script>
  import {cloneDeep} from "lodash";

  import MedicationComponentIndicationCheckboxRow from "@pages/Records/Components/Sections/MedicationBar/Components/Indications/IndicationCheckboxRow.vue";

  export default {
    name: "MedicationComponentIndicationCheckbox",

    components: {MedicationComponentIndicationCheckboxRow},

    props: {
      type: {
        type: String,
        default: "default",
      },
      isLoading: {
        type: Boolean,
        default: true,
      },
      indicationsOptions: {
        type: Object,
        required: true,
        default: () => {},
      },
      newIndicationList: {
        type: Object,
        require: true,
        default: () => {},
      },
      indicationsUnknown: {
        type: Boolean,
        required: true,
        default: false,
      },
      hashId: {
        type: String,
        required: true,
        default: "",
      },
    },

    emits: ["formChange"],

    setup(props, {emit}) {
      const setIndication = (newValue, newIndication) => {
        let currentIndications = cloneDeep(props.newIndicationList);

        if (currentIndications.length > 0) {
          if (newValue) {
            const check = currentIndications.some(
              (indication) => indication.abdata_key_miv === newIndication.abdata_key_miv,
            );

            if (check) return;

            currentIndications.push(newIndication);
          } else {
            currentIndications = currentIndications.filter((indication) => {
              return indication.abdata_key_miv !== newIndication.abdata_key_miv;
            });
          }
        } else {
          currentIndications.push(newIndication);
        }

        emit("formChange", {key: "indications", newValue: currentIndications});
      };

      const isChecked = (indication) => {
        return props.newIndicationList.some(
          (checkedIndications) => checkedIndications.abdata_key_miv === indication.abdata_key_miv,
        );
      };

      return {setIndication, isChecked};
    },
  };
</script>
