<template>
  <footer class="bottom-0 w-full py-3 bg-white shadow-[0_10px_15px_8px_rgba(0,0,0,0.3)] flex md:items-center px-4">
    <div class="flex flex-1 justify-between sm:flex-row gap-2 sm:gap-0 flex-wrap">
      <div class="flex items-center">
        <template v-if="isDevelop">
          <div
            class="-mx-2 px-2 -my-1 py-1 bg-red-500 text-yellow-400 font-bold cursor-pointer rounded"
            @click="enableMedicalCertification"
          >
            Dev / Test System (
            <span v-text="environment" />
            )
          </div>
        </template>

        <template v-else>
          <template v-if="showCeLogo">
            <div class="flex items-center">
              <img alt="CeLogo" src="/resources/assets/images/ce-with-number.png" class="h-5 ml-2 mr-1 mt-1.5" />

              <span class="text-gray-500">{{ productName }}</span>

              <img
                alt="MedicalDeviceSymbol"
                src="/resources/assets/images/MedicalDeviceSymbol-20221027-121211.png"
                class="h-6 mx-0.5"
              />
            </div>
          </template>
        </template>
      </div>

      <div class="flex items-center">
        <a
          class="text-sm md:text-base"
          href="https://www.abda.de/fuer-apotheker/arzneimittelkommission/berichtsbogen-formulare"
          target="_blank"
        >
          UAW an AMK melden
        </a>

        <div class="w-px h-5 bg-gray-400 mx-2" />

        <a class="text-sm md:text-base" @click="openLabellingDialog">Kennzeichnung</a>

        <div class="w-px h-5 bg-gray-400 mx-2" />

        <a class="text-sm md:text-base" href="https://www.pharma4u.de/allgemein/impressum/" target="_blank">
          Impressum
        </a>
      </div>

      <div class="flex items-center">
        <span class="text-gray-500 pr-1">Version {{ version }}</span>
        <component-process-saved :seconds-to-hide-date="10" />
      </div>
    </div>

    <component-dialog ref="dialogRef" dialog-title="Kennzeichnung" dialog-type="information" dialog-width="medium">
      <template #content>
        <h4 class="text-md leading-6 font-medium">{{ distributorLabel }}</h4>
        <div class="flex mb-8 mt-1">
          <p>
            pharma4u GmbH
            <br />
            Apothekerhaus
            <br />
            Carl-Mannich-Straße 26
            <br />
            65760 Eschborn
          </p>
        </div>

        <h4 class="text-md leading-6 font-medium">Hersteller und UDI</h4>

        <div class="flex flex-col sm:flex-row gap-8 mb-8">
          <div class="flex mt-2">
            <img alt="iso" class="mt-2 h-16" src="/resources/assets/images/iso_grs_7000_3082.png" />
            <p class="self-center ml-3">
              Johner Medical GmbH,
              <br />
              Niddastr. 91,
              <br />
              60329 Frankfurt
            </p>
          </div>
          <div class="flex">
            <img
              alt="MedicalDeviceSymbol"
              class="h-16 -ml-1"
              src="/resources/assets/images/MedicalDeviceSymbol-20221027-121211.png"
            />
            <p class="ml-3 mt-2">
              UDI:
              <span>{{ udi }}</span>
            </p>
          </div>
        </div>

        <h4 class="text-md leading-6 font-medium">Meldungen von Vorkommnissen MediCheck</h4>
        <div class="flex mt-1">
          <p>
            Fehler oder Vorkommnisse, die in Zusammenhang mit der Nutzung der Software MediCheck auftreten, melden Sie
            bitte an die Adresse
            <!-- prettier-ignore -->
            <span><a class="focus:ring-0" href="mailto:meldung@wir-machen-amts.de">meldung@wir-machen-amts.de</a>.</span>
          </p>
        </div>
      </template>
    </component-dialog>
  </footer>
</template>

<script>
  import {computed, ref} from "vue";
  import {usePage} from "@inertiajs/vue3";

  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentProcessSaved from "@components/ProcessSaved.vue";

  export default {
    name: "LayoutFooter",

    components: {ComponentProcessSaved, ComponentDialog},

    setup() {
      const page = usePage();
      const dialogRef = ref(null);
      const medicalCertification = ref(false);

      const environment = computed(() => page.props.environmentConfig.environment);
      const version = computed(() => page.props.environmentConfig.version);
      const isDevelop = computed(() => page.props.environmentConfig.isDevelop && medicalCertification.value === false);
      const udi = computed(() => page.props.udi);
      const thirdPartyIsOnlyP4u = computed(() => page.props.thirdPartyIsOnlyP4u);
      const showCeLogo = computed(() => !page.props.can?.is_education);

      const distributorLabel = computed(() => {
        if (thirdPartyIsOnlyP4u.value) return "Vertreiber/Händler und Lizenzgeber";
        return "Betreiber";
      });

      const productName = computed(() => {
        if (thirdPartyIsOnlyP4u.value) return "MediCheck";
        return "MediCheck Warenwirtschaft";
      });

      const enableMedicalCertification = () => {
        medicalCertification.value = true;

        window.setTimeout(() => {
          medicalCertification.value = false;
        }, 1000);
      };

      const openLabellingDialog = () => {
        if (dialogRef.value) {
          dialogRef.value.open();
        }
      };

      return {
        dialogRef,
        environment,
        version,
        isDevelop,
        udi,
        showCeLogo,
        distributorLabel,
        productName,
        enableMedicalCertification,
        openLabellingDialog,
      };
    },
  };
</script>
