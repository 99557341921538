<template>
  <component-breadcrumb
    class="mt-2"
    :links="[
      {
        label: 'Kontakte',
        url: $route('contacts.index'),
      },
      {
        label: contact !== null ? 'Kontakt bearbeiten' : 'Neuer Kontakt',
      },
    ]"
  />

  <section class="mt-6 bg-white max-w-screen-xl">
    <div class="pt-4 px-4">
      <h2 class="text-2xl font-semibold" v-text="contact !== null ? 'Kontakt bearbeiten' : 'Neuer Kontakt'" />
    </div>

    <article class="p-4 flex items-start flex-col space-y-6 lg:flex-row lg:space-y-0 lg:space-x-6">
      <!-- article::main -->
      <div class="w-full lg:max-w-screen-md space-y-6">
        <contacts-contact-form :contact="contact" />
      </div>

      <!-- article::aside -->
      <!--      <div class="grid gap-6 grid-cols-1 md:grid-cols-2 lg:w-96 lg:grid-cols-1">right</div>-->
    </article>
  </section>
</template>

<script>
  import ComponentBreadcrumb from "@components/Breadcrumb/Breadcrumb.vue";
  import ContactsContactForm from "@pages/Contacts/Components/ContactForm.vue";

  export default {
    name: "PagesContactCreate",

    components: {
      ContactsContactForm,
      ComponentBreadcrumb,
    },

    props: {
      contact: {
        type: Object,
        default: null,
      },
    },
  };
</script>
