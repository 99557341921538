<template>
  <component-card
    title="Dokumente"
    subtitle="Übergreifende Dokumente für diesen Patienten."
    class="rounded-lg border-2 border-gray-200"
  >
    <ul class="space-y-2">
      <li class="flex items-start">
        <button v-if="patient.datasecurityconfirmed" class="mr-2 rounded-full" @click="setPrivacy(false)">
          <component-icon class="text-2xl text-mcgreen">check_circle</component-icon>
        </button>
        <button v-else class="mr-2 group/todo rounded-full" @click="setPrivacy(true)">
          <component-icon class="group-hover/todo:hidden text-2xl text-gray-600">radio_button_unchecked</component-icon>
          <component-icon class="hidden group-hover/todo:block text-2xl text-gray-600">
            check_circle_outline
          </component-icon>
        </button>

        <div>
          <div>
            Datenschutzeinwilligung
            <a href="/static/Einwilligung_Datenschutz.pdf" target="_blank">(PDF)</a>
          </div>
          <div class="text-xs">
            <span v-if="patient.datasecurityconfirmed">am {{ patient.datasecurityconfirmed }} Uhr</span>
            <a v-else href="#" @click.prevent="setPrivacy(true)">als unterzeichnet kennzeichnen</a>
          </div>
        </div>
      </li>

      <li class="flex items-start">
        <button
          v-if="patient.releasefromconfidentialityconfirmed"
          class="mr-2 rounded-full"
          @click="setReleaseFromConfidentiality(false)"
        >
          <component-icon class="text-2xl text-mcgreen">check_circle</component-icon>
        </button>
        <button v-else class="mr-2 group/todo rounded-full" @click="setReleaseFromConfidentiality(true)">
          <component-icon class="group-hover/todo:hidden text-2xl text-gray-600">radio_button_unchecked</component-icon>
          <component-icon class="hidden group-hover/todo:block text-2xl text-gray-600">
            check_circle_outline
          </component-icon>
        </button>

        <div>
          <div>Schweigepflichtentbindung</div>
          <div class="text-xs">
            <span v-if="patient.releasefromconfidentialityconfirmed">
              am {{ patient.releasefromconfidentialityconfirmed }} Uhr
            </span>
            <a v-else href="#" @click.prevent="setReleaseFromConfidentiality(true)">als unterzeichnet kennzeichnen</a>
          </div>
        </div>
      </li>
    </ul>
  </component-card>
</template>

<script>
  import ComponentCard from "@components/Cards/Card.vue";
  import {router} from "@inertiajs/vue3";
  import ComponentIcon from "@components/Icons/Icon.vue";

  export default {
    name: "PatientsPatientDocuments",
    components: {ComponentIcon, ComponentCard},
    props: {
      patient: Object,
    },
    setup(props) {
      function setPrivacy(value) {
        router.patch(
          route("patients.update", {patient: props.patient.id}),
          {datasecurityconfirmed: value},
          {
            preserveScroll: true,
          },
        );
      }
      function setReleaseFromConfidentiality(value) {
        router.patch(
          route("patients.update", {patient: props.patient.id}),
          {releasefromconfidentialityconfirmed: value},
          {
            preserveScroll: true,
          },
        );
      }

      return {setPrivacy, setReleaseFromConfidentiality};
    },
  };
</script>
