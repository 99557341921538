<template>
  <form @submit.prevent="save">
    <component-card class="relative group hover:bg-gray-100">
      <div class="flex items-baseline justify-between">
        <h6 class="text-xl">Sonde</h6>

        <component-spinner v-if="form.processing" class="size-4" />
        <component-unsaved-changes v-else-if="form.isDirty || form.recentlySuccessful" :form="form" />
        <span v-else class="text-xs text-gray-500">Aktualisiert {{ attribute.updated_at }}</span>
      </div>

      <template v-if="activeEdit">
        <div class="mt-4 grid grid-cols-4 gap-8">
          <component-select
            v-model="form.payload.tube_position"
            class="col-span-2"
            label="Endlage"
            :validation="form.errors['payload.tube_position']"
            :options="tubePatientTubePosition"
          />
        </div>
      </template>

      <template v-else>
        <div class="mt-0.5">
          <span v-if="tubePatientTubePosition[attribute.payload?.tube_position]">
            Der Patient hat eine Sonde mit Endlage im
            {{ tubePatientTubePosition[attribute.payload?.tube_position] }}.
          </span>
          <span v-else>Der Patient hat eine Sonde.</span>
        </div>

        <div class="absolute hidden group-hover:flex right-2 bottom-2 flex-row-reverse space-x-4 space-x-reverse">
          <component-icon-button type="button" class="p4umc-primary" icon="edit" @click="activeEdit = true" />
          <component-icon-button type="button" icon="delete" @click="confirmRemove()" />
        </div>
      </template>

      <template v-if="activeEdit" #actions>
        <component-button class="p4umc-primary" label="Speichern" :disabled="form.processing" />
        <component-button type="button" label="Abbrechen" :disabled="form.processing" @click="cancelEdit()" />
      </template>
    </component-card>
  </form>

  <component-confirmation-dialog
    ref="confirmationDialog"
    title="Sind Sie sicher?"
    content="Die Angabe „Sonde“ wird gelöscht."
    @confirmed="remove()"
  />
</template>

<script>
  import ComponentCard from "@components/Cards/Card.vue";
  import ComponentSelect from "@components/Selects/Select.vue";
  import {useForm} from "@inertiajs/vue3";
  import ComponentUnsavedChanges from "@components/Alerts/UnsavedChanges.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import {inject, ref, watch} from "vue";
  import ComponentConfirmationDialog from "@components/Dialogs/ConfirmationDialog.vue";
  import {tubePatientTubeModel, tubePatientTubePosition} from "@pages/Patients/Components/PatientAttributes/Enums.js";
  import ComponentIconButton from "@components/Buttons/IconButton.vue";
  import ComponentSpinner from "@components/Spinner.vue";

  export default {
    name: "PatientsPatientAttributesTubePatientAttribute",

    components: {
      ComponentIconButton,
      ComponentSpinner,
      ComponentConfirmationDialog,
      ComponentButton,
      ComponentUnsavedChanges,
      ComponentSelect,
      ComponentCard,
    },

    props: {
      attribute: Object,
      patient: Object,
      recentlyAdded: {
        type: Boolean,
        default: false,
      },
    },

    setup(props) {
      const broadcast = inject("$broadcast");

      const confirmationDialog = ref(null);
      const activeEdit = ref(props.recentlyAdded);

      watch(
        () => props.recentlyAdded,
        (newValue) => {
          activeEdit.value = newValue;
        },
      );

      const form = useForm({
        type: props.attribute.type,
        payload: {
          tube_position: props.attribute?.payload?.tube_position,
        },
      });

      function cancelEdit() {
        // was recently added AND was NOT saved once
        if (props.recentlyAdded && !form.wasSuccessful) {
          remove();
        } else {
          form.reset();
          activeEdit.value = false;
        }
      }

      function save() {
        form.put(route("attributes.update", {patient: props.patient.id, attribute: props.attribute.id}), {
          preserveScroll: true,
          onSuccess: () => {
            activeEdit.value = false;
            broadcast.patient.postMessage(props.patient.id, {action: "updated"});
          },
        });
      }

      function confirmRemove() {
        confirmationDialog.value.open();
      }

      function remove() {
        form.delete(route("attributes.destroy", {patient: props.patient.id, attribute: props.attribute.id}), {
          preserveScroll: true,
          onSuccess: () => {
            broadcast.patient.postMessage(props.patient.id, {action: "updated"});
          },
        });
      }

      return {
        activeEdit,
        cancelEdit,
        tubePatientTubeModel,
        tubePatientTubePosition,
        form,
        save,
        confirmRemove,
        remove,
        confirmationDialog,
      };
    },
  };
</script>
