<template>
  <patientinput-component-scanner-dialog
    ref="scannerDialog"
    dialog-title="Medikationsplan (BMP) scannen"
    dialog-type="abort"
    :formats="formats"
    loader-class="aspect-square"
    scanner-class="aspect-square"
    :primary-button-disabled="totalPages === 0 || totalPages !== pagesScanned.length"
    :paused="paused"
    @detect="onDetect"
  >
    <p v-if="pagesScanned.length === 0 && !error">
      Bitte scannen Sie den Datamatrix-Code von Ihrem Bundeseinheitlichen Medikationsplan (BMP).
    </p>

    <p v-else-if="totalPages !== pagesScanned.length && !paused">
      Bitte Seite {{ nextPage }} von {{ totalPages }} scannen.
    </p>

    <template #paused>
      <component-alert v-if="error" type="error" class="w-full !mb-0">
        <p>{{ error }}</p>

        <component-button class="mt-2 p4umc-primary" @click="paused = false">Weiterscannen</component-button>
      </component-alert>

      <template v-else-if="bmpForm.processing">
        <component-spinner class="w-12" />

        <component-alert type="succes" class="w-full !mb-0">
          Der gescannte Medikationsplan wird verarbeitet. Bitte haben Sie einen kurzen Augenblick Geduld.
        </component-alert>
      </template>

      <component-alert v-else type="succes" class="w-full !mb-0">
        <p>Seite {{ currentScannedPage }} von {{ totalPages }} wurde gescannt.</p>

        <component-button class="mt-2 p4umc-primary" @click="paused = false">Weiterscannen</component-button>
      </component-alert>
    </template>
  </patientinput-component-scanner-dialog>
</template>

<script>
  import {inject, ref} from "vue";
  import {useForm} from "@inertiajs/vue3";
  import {Symbology} from "scandit-web-datacapture-barcode";

  import Bmp from "@utils/Bmp.js";
  import {pdfRouter} from "@utils/pdfRouter/pdfRouter.js";
  import {coreRouter} from "@utils/coreRouter/coreRouter.js";

  import ComponentSpinner from "@components/Spinner.vue";
  import ComponentAlert from "@components/Alerts/Alert.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import PatientinputComponentScannerDialog from "@pages/Patientinput/Components/Scanners/ScannerDialog.vue";

  export default {
    name: "PatientinputComponentBmpScannerDialog",

    components: {
      PatientinputComponentScannerDialog,
      ComponentButton,
      ComponentAlert,
      ComponentSpinner,
    },

    props: {
      form: {
        type: Object,
        required: true,
      },
    },

    emits: ["ready"],

    setup(props, ctx) {
      const /** @type {Ref} */ scannerDialog = ref(null);

      const /** @type {Ref<Boolean>} */ paused = ref(false);

      const /** @type {Ref<Number>} */ totalPages = ref(0);
      const /** @type {Ref<Number>} */ nextPage = ref(0);
      const /** @type {{value: Number[]}} */ pagesScanned = ref([]);
      const /** @type {Ref<Number>} */ currentScannedPage = ref(0);

      const formats = [Symbology.DataMatrix];

      let uniqueInstanceId = null;

      const error = ref(null);

      const bmpForm = useForm({
        pages: [],
      });

      const axios = inject("$axios");

      const open = () => {
        scannerDialog.value.open();
      };

      const close = () => {
        scannerDialog.value.close();
      };

      const onDetect = (detectedCodes) => {
        paused.value = true;

        if (!Bmp.validateXml(detectedCodes[0]._data)) {
          error.value = "Es wurde kein gültiger Medikationsplan erkannt.";
          return;
        }

        error.value = null;

        const xml = detectedCodes[0]._data;

        const currentPageNumber = Bmp.getCurrentPageNumber(xml);
        const totalPageNumber = Bmp.getTotalPageNumber(xml);
        const scannedUniqueInstanceId = Bmp.getUniqueInstanceId(xml);

        if (uniqueInstanceId && uniqueInstanceId !== scannedUniqueInstanceId) {
          error.value = "Bitte scannen Sie nur Seiten des gleichen Medikationsplans.";
        } else if (pagesScanned.value.includes(currentPageNumber)) {
          error.value = "Sie haben diese Seite bereits gescannt.";
        } else if (pagesScanned.value.length <= totalPageNumber) {
          currentScannedPage.value = currentPageNumber;
          totalPages.value = totalPageNumber;

          pagesScanned.value.push(currentPageNumber);

          bmpForm.pages.push(xml);

          uniqueInstanceId = scannedUniqueInstanceId;

          if (pagesScanned.value.length === totalPageNumber) {
            parseXml();
          } else if (pagesScanned.value.length < totalPageNumber) {
            if (!pagesScanned.value.includes(1)) {
              nextPage.value = 1;
            } else if (!pagesScanned.value.includes(2)) {
              nextPage.value = 2;
            } else if (!pagesScanned.value.includes(3)) {
              nextPage.value = 3;
            }
          }
        } else {
          error.value =
            "Es ist ein unbekannter Fehler aufgetreten. Bitte klicken Sie auf „Abbrechen“ und starten den Scan erneut.";
        }
      };

      const parseXml = () => {
        bmpForm.processing = true;

        new Promise((resolve, reject) => {
          pdfRouter.patientinputDecodeXml(bmpForm.data(), {
            onSuccess: (res) => {
              const parsedData = res.data;

              ["firstname", "lastname", "birthdate", "gender", "insurancenumber"].forEach((key) => {
                if (props.form.masterdata.patient[key] === null) {
                  props.form.masterdata.patient[key] = parsedData.patient[key];
                }
              });

              props.form.medication.pzns.push(...new Set(parsedData.pzns)); // Set to avoid duplicates
              props.form.medication.bmp = res.data_raw;

              ["size", "weight"].forEach((key) => {
                if (props.form.laborvalues[key] === null) {
                  props.form.laborvalues[key] = parsedData.laborvalues[key] ?? null;
                }
              });

              resolve();
            },
            onError: reject,
          });
        })
          .then(async () => {
            await new Promise((resolve, reject) => {
              coreRouter.searchPacForPatientinput(props.form.medication.pzns.join(","), {
                onSuccess: resolve,
                onError: reject,
              });
            });
          })
          .then(() => {
            bmpForm.processing = false;
            ctx.emit("ready");
          })
          .catch(() => {
            error.value = "Beim Verarbeiten gab es einen Fehler. Bitte versuchen Sie es erneut.";
            bmpForm.pages = [];
            totalPages.value = 0;
            nextPage.value = 0;
            pagesScanned.value = [];
            currentScannedPage.value = 0;
            bmpForm.processing = false;
          });
      };

      return {
        scannerDialog,
        paused,
        totalPages,
        pagesScanned,
        nextPage,
        currentScannedPage,
        error,
        open,
        close,
        onDetect,
        bmpForm,
        formats,
      };
    },
  };
</script>
