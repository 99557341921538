<template>
  <div
    v-if="open && isVisible"
    class="border-l-4 px-2 py-2 text-left mb-4 flex justify-between"
    :class="{
      'bg-orange-100 border-orange-400 text-orange-900': type === 'warning',
      'bg-mcred-100 border-mcred text-mcred-900': type === 'error',
      'bg-blue-100 border-blue-600 text-blue-900': type === 'info',
      'bg-mcgreen-100 border-mcgreen text-mcgreen-900': type === 'succes',
    }"
  >
    <div>
      <slot name="title" />
      <div v-if="expanded">
        <slot />
      </div>
    </div>

    <div v-if="allowToggle" class="mr-2 cursor-pointer" @click="toggle()">
      <component-icon v-if="expanded" class="text-2xl">expand_less</component-icon>
      <component-icon v-else class="text-2xl">expand_more</component-icon>
    </div>
    <div v-else-if="allowClosing" class="mr-2 cursor-pointer" @click="close()">
      <component-icon class="text-2xl">close</component-icon>
    </div>
  </div>
</template>

<script>
  import {computed, ref} from "vue";
  import ComponentIcon from "@components/Icons/Icon.vue";

  export default {
    name: "ComponentAlert",
    components: {ComponentIcon},

    props: {
      allowClosing: {
        type: Boolean,
        default: false,
      },
      allowToggle: {
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
        default: "warning",
      },
    },

    setup(props, {slots}) {
      const open = ref(true);
      const expanded = ref(!props.allowToggle);

      const isVisible = computed(() => {
        return !props.allowToggle || (slots.default && slots.default);
      });

      function close() {
        open.value = false;
      }

      function toggle() {
        expanded.value = !expanded.value;
      }

      return {open, expanded, isVisible, close, toggle};
    },
  };
</script>
