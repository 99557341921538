<template>
  <form @submit.prevent="save">
    <component-card title="Stammdaten" class="rounded-lg border-2 border-gray-200">
      <component-alert v-if="existingPatients.length" type="error" :allow-toggle="isUpdate" :allow-closing="true">
        <template #title>
          <h2 class="text-xl">Eventuell existiert dieser Patient bereits?</h2>
        </template>
        <p class="mt-2">
          Sie möchten einen {{ isUpdate ? "bestehenden Patienten bearbeiten" : "neuen Patienten erstellen" }}, jedoch
          <template v-if="existingPatients.length === 1">existiert bereits ein Patient</template>
          <template v-if="existingPatients.length > 1">existieren bereits mehrere Patienten</template>
          mit gleichem
          <em>Namen</em>
          und
          <em>Geburtsdatum.</em>
          <br />
          <template v-if="isUpdate">Vermeiden Sie, Personen doppelt anzulegen.</template>
          <template v-else>
            Wechseln Sie ggf. zum bestehenden Patienten und vermeiden Sie, eine Person doppelt anzulegen.
          </template>
        </p>

        <table class="w-full mt-2">
          <thead>
            <tr class="border-b-2 border-b-gray-400 leading-tight">
              <th class="px-0.5 py-2 font-normal text-left align-top">
                <div class="">Name, Vorname</div>
                <div class="text-xs">Geburtsdatum, Versichertennummer</div>
              </th>
              <th class="px-0.5 py-2 font-normal text-left align-top">
                <div>Letzte Änderung</div>
              </th>
              <th><!-- action --></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="existingPatient in existingPatients" :key="existingPatient.id">
              <td>
                <div v-if="existingPatient.suffix || existingPatient.prefix" class="text-sm">
                  {{ existingPatient.suffix }} {{ existingPatient.prefix }}
                </div>
                <div class="text-gray-700 text-lg font-semibold">
                  {{ existingPatient.lastname }}, {{ existingPatient.firstname }}
                </div>
                <div class="text-sm">{{ existingPatient.birthdate }} {{ existingPatient.insurancenumber }}</div>
              </td>
              <td>
                <div>{{ existingPatient.updated_at }}</div>
                <div class="text-sm">{{ existingPatient.username }}</div>
              </td>
              <td class="text-right">
                <inertia-link :href="$route('patients.show', {patient: existingPatient.id})">
                  <component-icon class="inline-block hover:no-underline">launch</component-icon>
                  zum bestehenden Patienten wechseln
                </inertia-link>
              </td>
            </tr>
          </tbody>
        </table>
      </component-alert>

      <div class="grid grid-cols-4 gap-8">
        <component-select
          v-model="form.salutation"
          class=""
          label="Anrede"
          :validation="form.errors.salutation"
          :options="patientSalutation"
          :nullable="true"
          placeholder-text="keine"
        />

        <component-input
          v-model="form.title"
          class="col-start-1"
          label="Titel"
          :hide-clear="true"
          :validation="form.errors.title"
          maxlength="20"
        />
        <component-input
          v-model="form.firstname"
          class="col-span-2"
          label="Vorname*"
          :hide-clear="true"
          :encrypted="true"
          :validation="form.errors.firstname"
          maxlength="45"
          @decrypted.once="clip('firstname', $event)"
          @keyup="clip('firstname', $event.target.value)"
        />
        <component-input
          v-model="form.suffix"
          class=""
          label="Namenszusatz"
          :hide-clear="true"
          :encrypted="true"
          :validation="form.errors.suffix"
          autocomplete="off"
          :datalist="suffixAutocomplete"
          maxlength="20"
          @decrypted.once="clip('suffix', $event)"
          @keyup="clip('suffix', $event.target.value)"
        />

        <component-input
          v-model="form.prefix"
          class="col-start-1"
          label="Vorsatzwort"
          :hide-clear="true"
          :encrypted="true"
          :validation="form.errors.prefix"
          autocomplete="off"
          :datalist="prefixAutocomplete"
          maxlength="20"
          @decrypted.once="clip('prefix', $event)"
          @keyup="clip('prefix', $event.target.value)"
        />
        <component-input
          v-model="form.lastname"
          class="col-span-2"
          label="Nachname*"
          :hide-clear="true"
          :encrypted="true"
          :validation="form.errors.lastname"
          maxlength="45"
          @decrypted.once="clip('lastname', $event)"
          @keyup="clip('lastname', $event.target.value)"
        />

        <div class="relative">
          <component-input
            v-model="form.birthdate"
            class=""
            label="Geburtsdatum*"
            type="date"
            :hide-clear="true"
            :encrypted="true"
            :helper-text="age"
            :validation="form.errors.birthdate ?? birthdateValidationMessage"
            @decrypted.once="clip('birthdate', $event)"
            @blur="
              clip('birthdate', $event.target.value);
              validateBirthdate($event.target.value);
            "
          />
          <component-info-icon
            v-if="patient?.is_fake"
            icon-class="text-blue-600 text-xl"
            class="absolute right-0 top-1"
            placement="left"
          >
            <template #content>
              <div class="w-44">
                Bei geteilten Patienten wird nicht das echte Geburtsdatum übertragen, sondern lediglich ein Datum, aus
                dem sich das korrekte Alter des Patienten ergibt.
              </div>
            </template>
          </component-info-icon>
        </div>

        <component-select
          v-model="form.gender"
          class="col-start-1"
          label="Geschlecht*"
          :validation="form.errors.gender"
          :options="patientGender"
        />
        <component-input
          v-model="form.insurancenumber"
          class="col-span-2"
          label="Versichertennummer"
          :hide-clear="true"
          :encrypted="true"
          :validation="form.errors.insurancenumber"
          pattern="[A-Z]\d{9}"
          maxlength="20"
          @decrypted.once="clip('insurancenumber', $event)"
          @keyup="clip('insurancenumber', $event.target.value)"
        />

        <div v-if="!isUpdate" class="col-span-4">
          <component-checkbox v-model="form.datasecurityconfirmed" value="1" color="red">
            Datenschutzeinwilligung liegt bereits vor
          </component-checkbox>
        </div>
      </div>

      <div class="text-sm text-right">* Pflichtangaben</div>

      <template #actions>
        <template v-if="continueWith === 'record'">
          <component-button
            class="p4umc-primary"
            label="Weiter"
            :disabled="form.processing"
            @click="redirectAfterCreate('records.create')"
          />
        </template>
        <template v-else>
          <component-button
            class="p4umc-primary"
            label="Speichern"
            :disabled="form.processing"
            @click="redirectAfterCreate(null)"
          />
          <component-button
            v-if="!isUpdate"
            label="Weitere Informationen hinzufügen"
            :disabled="form.processing"
            @click="redirectAfterCreate('patients.edit')"
          />
          <component-unsaved-changes v-if="isUpdate" :form="form" />
        </template>
      </template>
    </component-card>
  </form>
</template>

<script>
  import ComponentSelect from "@components/Selects/Select.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentInput from "@components/Inputs/Input.vue";
  import ComponentCard from "@components/Cards/Card.vue";
  import {useForm, Link as InertiaLink} from "@inertiajs/vue3";
  import {
    patientGender,
    patientSalutation,
    suffixAutocomplete,
    prefixAutocomplete,
  } from "@pages/Patients/Enums/Enums.js";
  import ComponentUnsavedChanges from "@components/Alerts/UnsavedChanges.vue";
  import {computed, inject, onMounted, reactive, ref, watch} from "vue";
  import {debounce} from "lodash";
  import filterExistingPatients from "@utils/Helpers/FilterExistingPatients.js";
  import ComponentAlert from "@components/Alerts/Alert.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentCheckbox from "../../../Components/Checkboxes/Checkbox.vue";
  import ComponentInfoIcon from "@components/Icons/InfoIcon.vue";

  export default {
    name: "PatientsPatientMasterData",
    components: {
      ComponentInfoIcon,
      ComponentCheckbox,
      ComponentIcon,
      ComponentAlert,
      ComponentUnsavedChanges,
      ComponentCard,
      ComponentInput,
      ComponentButton,
      ComponentSelect,
      InertiaLink,
    },
    props: {
      patient: {
        type: Object,
        default: null,
      },
      continueWith: {
        type: String,
        default: null,
      },
    },

    setup(props) {
      const axios = inject("$axios");
      let cancelToken;
      const privacy = inject("$privacy");
      const broadcast = inject("$broadcast");

      const validBirthdate = ref(false);
      const birthdateValidationMessage = ref(null);

      const age = ref(null);

      function validateBirthdate(value) {
        if (!value) {
          validBirthdate.value = false;
          birthdateValidationMessage.value = null; // not set should not show validation message
          return;
        }

        let year = null;
        let month = null;
        let day = null;

        if (/^\d{4}-\d{1,2}-\d{1,2}$/.test(value)) {
          // YYYY-MM-DD
          year = value.split("-")[0] ?? null;
          month = value.split("-")[1] ?? null;
          day = value.split("-")[2] ?? null;
        } else if (/^\d{1,2}.\d{1,2}.\d{4}$/.test(value)) {
          year = value.split(".")[2] ?? null;
          month = value.split(".")[1] ?? null;
          day = value.split(".")[0] ?? null;
        } else {
          // invalid like "11980-04-30"
          validBirthdate.value = false;
          birthdateValidationMessage.value = "Bitte geben Sie ein sinnvolles Geburtsdatum ein";
          return;
        }

        // date is not set at all
        if (!year) {
          validBirthdate.value = false;
          birthdateValidationMessage.value = null; // not set should not show validation message
          return;
        }

        // do not allow birthdate < 1900
        if (parseInt(year) < 1900) {
          validBirthdate.value = false;
          birthdateValidationMessage.value = "Bitte geben Sie ein sinnvolles Geburtsdatum ein";
          return;
        }

        const inputDate = new Date(year, month - 1, day); // month is based on zero
        const now = new Date();
        inputDate.setHours(0, 0, 0, 0);
        now.setHours(0, 0, 0, 0);

        // do not allow future dates
        if (inputDate > now) {
          validBirthdate.value = false;
          birthdateValidationMessage.value = "Das Geburtsdatum darf nicht in der Zukunft liegen";
          return;
        }

        validBirthdate.value = true;
        birthdateValidationMessage.value = null;
      }

      const form = useForm({
        salutation: props?.patient?.salutation ?? null,
        title: props?.patient?.title ?? null,
        firstname: props?.patient?.firstname ?? null,
        suffix: props?.patient?.suffix ?? null,
        prefix: props?.patient?.prefix ?? null,
        lastname: props?.patient?.lastname ?? null,
        birthdate: props?.patient?.birthdate ?? null,
        gender: props?.patient?.gender ?? null,
        insurancenumber: props?.patient?.insurancenumber ?? null,
        datasecurityconfirmed: null,
      });

      // separate clipped values from form, as they would
      // always falsely trigger form.isDirty state
      const clippedValues = reactive({
        firstname_clipped: null,
        suffix_clipped: null,
        prefix_clipped: null,
        lastname_clipped: null,
        birthdate_clipped: null,
        insurancenumber_clipped: null,
      });

      const existingPatients = ref([]);

      watch(
        () => form.gender,
        () => {
          searchExistingPatients();
        },
      );

      watch(
        clippedValues,
        debounce(() => {
          searchExistingPatients();
        }, 500),
      );

      watch(
        () => form.isDirty,
        () => {
          // when updating, always search whenever form gets dirty
          if (isUpdate) {
            searchExistingPatients();
          }
        },
      );

      function searchExistingPatients() {
        // early exit if updating and form is not dirty
        if (isUpdate && !form.isDirty) {
          return;
        }

        // early exit if required fields are not set
        if (
          !clippedValues.firstname_clipped ||
          !clippedValues.lastname_clipped ||
          !clippedValues.birthdate_clipped ||
          !form.data().gender
        ) {
          return;
        }

        if (typeof cancelToken !== "undefined") {
          cancelToken.abort();
        }

        let controller = new AbortController();

        axios
          .post(route("api.patients.search"), clippedValues, {
            signal: controller.signal,
          })
          .then((response) => {
            Promise.all(response.data.data.map(async (patient) => await privacy.decryptPatient(patient))).then(
              (foundPatients) => {
                if (foundPatients.length > 0) {
                  privacy.decryptPatient(form.data()).then((currentPatient) => {
                    existingPatients.value = filterExistingPatients(
                      {id: props.patient?.id, ...currentPatient},
                      foundPatients,
                      ["firstname", "lastname", "gender", "birthdate", "insurancenumber"],
                    ).map((patient) => {
                      let d = new Date(patient.birthdate ?? null);
                      patient.birthdate = isNaN(d.getTime())
                        ? null
                        : d.toLocaleDateString("de-DE", {dateStyle: "medium"});
                      return patient;
                    });
                  });
                }
              },
            );
          })
          .catch((error) => {
            if (error.name === "AbortError") {
              // expected to happen
            }
          });

        cancelToken = controller;
      }

      const isUpdate = props.patient !== null;
      let redirectTo = null;

      function clip(field, value) {
        switch (field) {
          case "birthdate":
            if (/^\d{4}-\d{1,2}-\d{1,2}$/.test(value)) {
              // YYYY-MM-DD
              clippedValues["birthdate_clipped"] = value.split("-")[0] ?? null;

              // because we're already here, we calculate the age...
              let d = new Date(value);
              // early exit
              if (isNaN(d.getTime())) return "";

              const today = new Date();

              // calculate age
              let calcAge = today.getFullYear() - d.getFullYear();
              const monthDiff = today.getMonth() - d.getMonth();
              if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < d.getDate())) {
                calcAge--;
              }
              age.value = calcAge + " Jahre";
            } else if (/^\d{1,2}.\d{1,2}.\d{4}$/.test(value)) {
              clippedValues["birthdate_clipped"] = value.split(".")[2] ?? null;
            } else {
              clippedValues["birthdate_clipped"] = null;
            }
            break;
          case "firstname":
          case "suffix":
          case "prefix":
          case "lastname":
          case "insurancenumber":
            clippedValues[field + "_clipped"] = (value ?? "").substring(0, 3) || null;
            break;
        }
      }

      function save() {
        if (isUpdate) {
          form
            .transform((data) => ({
              ...data,
              ...clippedValues,
            }))
            .patch(route("patients.update", {id: props.patient.id}), {
              preserveScroll: true,
              onSuccess: () => {
                broadcast.patient.postMessage(props.patient.id, {action: "updated"});
              },
            });
        } else {
          form
            .transform((data) => ({
              ...data,
              ...clippedValues,
            }))
            .post(route("patients.store", {redirect: redirectTo}), {
              preserveScroll: redirectTo === "patients.edit",
            });
        }
      }

      function redirectAfterCreate(value) {
        redirectTo = value;
      }

      return {
        existingPatients,
        isUpdate,
        clip,
        form,
        save,
        redirectAfterCreate,
        patientGender,
        patientSalutation,
        suffixAutocomplete,
        prefixAutocomplete,
        age,
        birthdateValidationMessage,
        validateBirthdate,
      };
    },
  };
</script>
