<template>
  <div class="z-0 w-full relative">
    <label v-if="label.length > 0" class="-z-1 origin-0 cursor-default block mb-1" :for="inputId">{{ label }}</label>

    <div class="relative" range>
      <input
        :id="inputId"
        type="range"
        :min="min"
        :max="max"
        :step="step"
        :value="modelValue"
        autocomplete="off"
        @input="$emit('update:modelValue', parseFloat($event.target.value))"
      />

      <div v-if="showValue" model-value>
        {{ modelValue }}
      </div>
    </div>

    <div
      v-if="datalist.length > 0"
      :id="datalistId"
      class="flex flex-row place-content-between text-gray-500 -mt-1 text-sm select-none"
    >
      <div v-for="(dLabel, index) in datalist" :key="index" class="w-0">
        <div
          class="whitespace-nowrap flex"
          :class="{
            'justify-start': index === 0,
            'justify-center': index > 0 && index < datalist.length - 1,
            'justify-end': index === datalist.length - 1,
          }"
        >
          {{ dLabel }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {computed} from "vue";

  import tailwindConfig from "@/tailwind.config.js";

  export default {
    name: "ComponentRange",

    props: {
      label: {
        type: String,
        default: "",
      },
      modelValue: {
        type: [String, Number],
        default: 0,
      },
      min: {
        type: [String, Number],
        default: 0,
      },
      max: {
        type: [String, Number],
        default: 100,
      },
      step: {
        type: [String, Number],
        default: 1,
      },
      color: {
        type: [String],
        default() {
          return tailwindConfig.theme.colors["mcred"]["DEFAULT"];
        },
      },
      colorHover: {
        type: [String],
        default() {
          return tailwindConfig.theme.colors["mcred"]["450"];
        },
      },
      datalist: {
        type: Array,
        default: () => [],
      },
      showValue: {
        type: Boolean,
        default: false,
      },
    },

    emits: ["update:modelValue"],

    setup() {
      const inputId = computed(() => {
        return `range-${crypto.randomUUID()}`;
      });

      const datalistId = computed(() => {
        return `datalist-${crypto.randomUUID()}`;
      });

      return {inputId, datalistId};
    },
  };
</script>

<style scoped lang="postcss">
  /* Source (modified): https://toughengineer.github.io/demo/slider-styler */

  div[range] {
    --max: v-bind(max);
    --min: v-bind(min);
    --value: v-bind(modelValue);

    --range: calc(var(--max) - var(--min));
    --ratio: calc((var(--value) - var(--min)) / var(--range));
    --sx: calc(0.5 * 2em + var(--ratio) * (100% - 2em));

    --linear-gradient: linear-gradient(v-bind(color), v-bind(color));
    --linear-gradient-hover: linear-gradient(v-bind(colorHover), v-bind(colorHover));

    --color-primary: v-bind(color);
    --color-primary-hover: v-bind(colorHover);
    --color-light: theme("colors.gray.200");

    input[type="range"] {
      @apply h-9 appearance-none bg-transparent w-full cursor-pointer;

      &:focus {
        @apply outline-0;
      }

      &:active {
        @apply cursor-grabbing;
      }

      &::-webkit-slider-thumb {
        @apply appearance-none w-8 h-8 rounded-full border-0 drop-shadow shadow;
        margin-top: calc(max((1em - 1px - 1px) * 0.5, 0px) - 2em * 0.5);
        background: var(--color-primary);
      }

      &::-moz-range-thumb {
        @apply appearance-none w-8 h-8 rounded-full border-0 drop-shadow shadow;
        background: var(--color-primary);
      }

      &::-webkit-slider-runnable-track {
        @apply h-4 border-0 rounded shadow-none;
        background: var(--color-light);
      }

      &::-moz-range-track {
        @apply border-0 rounded shadow-none;
        height: max(calc(1em - 1px - 1px), 0px);
        background: var(--color-light);
      }

      &::-webkit-slider-thumb:hover,
      &::-webkit-slider-thumb:active {
        background: var(--color-primary-hover);
      }

      &::-moz-range-thumb:hover,
      &::-moz-range-thumb:active {
        background: var(--color-primary-hover);
      }

      &:hover::-webkit-slider-runnable-track,
      &:active::-webkit-slider-runnable-track {
        background: var(--color-light);
      }

      &:hover::-moz-range-track,
      &:active::-moz-range-track {
        background: var(--color-light);
      }

      &::-webkit-slider-runnable-track {
        background:
          var(--linear-gradient) 0 / var(--sx) 100% no-repeat,
          var(--color-light);
      }

      &::-moz-range-track {
        background:
          var(--linear-gradient) 0 / var(--sx) 100% no-repeat,
          var(--color-light);
      }

      &:hover::-webkit-slider-runnable-track,
      &:active::-webkit-slider-runnable-track {
        background:
          var(--linear-gradient-hover) 0 / var(--sx) 100% no-repeat,
          var(--color-light);
      }

      &:hover::-moz-range-track,
      &:active::-moz-range-track {
        background:
          var(--linear-gradient-hover) 0 / var(--sx) 100% no-repeat,
          var(--color-light);
      }
    }

    div[model-value] {
      @apply absolute w-0 top-1.5 font-semibold select-none flex justify-center pointer-events-none;

      color: var(--color-light);
      left: var(--sx);
    }
  }
</style>
