<template>
  <component-breadcrumb
    :links="[
      {
        label: 'Patienten',
        url: patient.is_fake ? $route('patients.index', {filter: 'shared-with-me'}) : $route('patients.index'),
      },
      {encryptedLabel: [patient.firstname, patient.lastname], url: $route('patients.show', {patient: patient.id})},
      {
        label: 'Eingabe',
        subLabel: {
          component: 'component-record-label',
          props: {
            record: record,
          },
        },
      },
    ]"
  />

  <section class="relative mt-6 flex items-start space-x-6">
    <div class="sticky top-20 z-10">
      <component-patient-sidebar :patient="patient" :record="record" />
    </div>

    <article class="flex-1 flex flex-col max-w-screen-2xl">
      <div class="flex-1">
        <div class="flex justify-between items-center p-4">
          <h2 class="text-2xl font-semibold">Medikationsanalyse</h2>

          <div class="flex space-x-1">
            <span class="text-xs m-auto mr-2">
              Gespeichert:
              <span class="text-xs" v-text="record.updated_at_datetime" />
            </span>

            <component-record-progress :record="record" />
          </div>
        </div>

        <div class="mt-4">
          <record-component-section-user-bar
            :record="record"
            :is-editable="currentIsEditable"
            :change-value="changeValue"
            :save-record="saveRecord"
          />
        </div>

        <component-alert v-if="via && via.startsWith('import')" :allow-closing="true" type="warning" class="mt-8">
          <h4 class="font-bold">Importierte Medikationsanalyse</h4>
          <p class="mt-2">
            Bitte überprüfen Sie bei einer importierten Medikationsanalyse
            <span v-if="via === 'import-bmp'">(BMP),</span>

            <span v-else-if="via === 'import-json'">(JSON-Datei),</span>

            <span v-else-if="via === 'import-wawi'">(WaWi),</span>
            ob alle Informationen vollständig übernommen wurden.
          </p>

          <template v-if="failed?.length > 0">
            <p class="mt-3">
              Bitte beachten Sie, dass die Medikation unvollständig importiert wird, da folgende PZNs/FAMs keinem
              Arzneimittel zugeordnet werden können. Bitte ergänzen Sie diese Arzneimittel manuell.
            </p>

            <ul class="list-dash">
              <li v-for="(pzn, index) in failed" :key="index" v-text="pzn" />
            </ul>
          </template>
        </component-alert>

        <component-alert v-else-if="via && via === 'patientinput'" allow-closing type="warning" class="mt-8">
          <h4 class="font-bold">
            Daten aus
            <template v-if="record.origin === recordOriginKeys.carerinput">Pflegecheckliste</template>

            <template v-else>Patientenfragebogen</template>
          </h4>

          <p>
            Diese Medikationsanalyse wurde mit Daten aus
            <template v-if="record.origin === recordOriginKeys.carerinput">der Pflegecheckliste</template>

            <template v-else>dem Patientenfragebogen</template>
            vorbefüllt.

            <template v-if="record.origin === recordOriginKeys.carerinput">
              Daten zum Heim und zu individuellen Besonderheiten finden Sie ggf. in der linken Seitenleiste.
            </template>

            <template v-else>
              Die Compliance-Antworten und ggf. individuelle Besonderheiten finden Sie in der linken Seitenleiste.
            </template>
          </p>

          <template v-if="record.metadata.patientinput.failed?.length > 0">
            <p class="mt-3">
              Bitte beachten Sie, dass die Medikation unvollständig importiert wird, da folgende PZNs/FAMs keinem
              Arzneimittel zugeordnet werden können. Bitte ergänzen Sie diese Arzneimittel manuell.
            </p>

            <ul class="list-dash">
              <li v-for="(pzn, index) in record.metadata.patientinput.failed" :key="index" v-text="pzn" />
            </ul>
          </template>
        </component-alert>

        <div id="form-components" class="bg-white shadow-md pt-8 mt-8">
          <component-stepper
            step-id="medicationbar"
            step-label="Medikation"
            :step-description="medicationBarMessage()"
            step-number="1"
            :active="rememberForm.active"
            :warning="unloadableMedicationsUnsolved.length > 0 || unloadableMedicationsSolved.length > 0"
            @click="setActive('medicationbar')"
          >
            <template #content>
              <record-component-section-medication-bar
                :is-loading="isMedicationLoading"
                :is-editable="currentIsEditable"
                :change-is-editable="changeIsEditable"
                :record-data="recordData"
                :unloadable-medications-solved="unloadableMedicationsSolved"
                :unloadable-medications-unsolved="unloadableMedicationsUnsolved"
              />
            </template>
          </component-stepper>

          <component-stepper
            step-id="symptombar"
            step-label="Probleme / Symptome"
            :step-description="barMessage('symptoms', 'symptom')"
            step-number="2"
            :active="rememberForm.active"
            :warning="unloadableSymptomsUnsolved.length > 0 || unloadableSymptomsSolved.length > 0"
            @click="setActive('symptombar')"
          >
            <template #content>
              <record-component-section-symptom-bar
                :is-loading="isSymptomLoading"
                :is-editable="currentIsEditable"
                :change-is-editable="changeIsEditable"
                :record-data="recordData"
                :unloadable-symptoms-solved="unloadableSymptomsSolved"
                :unloadable-symptoms-unsolved="unloadableSymptomsUnsolved"
              />
            </template>
          </component-stepper>

          <component-stepper
            step-id="laborbar"
            step-label="Labor- / Vitalwerte"
            :step-description="laborBarMessage()"
            :optional-stepper="true"
            step-number="3"
            :active="rememberForm.active"
            :warning="unloadableLaborvaluesUnsolved.length > 0 || unloadableLaborvaluesSolved.length > 0"
            @click="setActive('laborbar')"
          >
            <template #content>
              <record-component-section-labor-bar
                :is-loading="isLaborvalueLoading"
                :is-editable="currentIsEditable"
                :change-is-editable="changeIsEditable"
                :record-data="recordData"
                :unloadable-laborvalues-solved="unloadableLaborvaluesSolved"
                :unloadable-laborvalues-unsolved="unloadableLaborvaluesUnsolved"
              />
            </template>
          </component-stepper>

          <component-stepper
            step-id="diseasebar"
            step-label="Erkrankungen / Allergien / Lebensumstände"
            :step-description="barMessage('diseases', 'disease')"
            :optional-stepper="true"
            step-number="4"
            :active="rememberForm.active"
            :warning="unloadableDiseasesUnsolved.length > 0 || unloadableDiseasesSolved.length > 0"
            :last-step="true"
            @click="setActive('diseasebar')"
          >
            <template #content>
              <record-component-section-disease-bar
                :is-loading="isDiseaseLoading"
                :is-editable="currentIsEditable"
                :change-is-editable="changeIsEditable"
                :record-data="recordData"
                :unloadable-diseases-solved="unloadableDiseasesSolved"
                :unloadable-diseases-unsolved="unloadableDiseasesUnsolved"
              />
            </template>
          </component-stepper>
        </div>

        <component-card v-if="currentIsEditable || canExportJson" class="mt-6">
          <div v-if="currentIsEditable" class="grid">
            <p v-if="analysisButtonDisabled" class="text-red-500 justify-self-center">
              Bitte überprüfen Sie ungültige oder veraltete Datensätze in den oben markierten Bereichen.
            </p>

            <component-button
              :disabled="analysisButtonDisabled"
              class="p4umc-primary justify-self-center my-3"
              label="Speichern und analysieren"
              @click="setDoneAndAnalyze"
            />
          </div>

          <div v-if="canExportJson" class="grid justify-center py-3">
            <record-component-json-exporter />
          </div>
        </component-card>
      </div>

      <!-- Dialog for before analyse -->
      <record-component-before-analyse-dialog
        ref="refRecordEditFinishedDialog"
        :analysis-button-disabled="analysisButtonDisabled"
        @close-and-emit-analysis-start="closeAndEmitAnalysisStart"
      />
    </article>
  </section>

  <!-- Dialogs -->
  <record-component-edit-again-dialog
    ref="refEditAgainDialog"
    :patient="patient"
    :record="record"
    :share-status="shareStatus"
  />

  <component-record-invalidated-dialog :record="record" :ignore-reset="true" />

  <component-confirmation-dialog
    ref="refMedicationDeleteConfirmDialog"
    title="Sind Sie sicher?"
    content="Die ausgewählte Zeile wird gelöscht."
    @confirmed="deleteRow"
  />
</template>

<script>
  import {computed, inject, onBeforeUnmount, onMounted, provide, reactive, ref, watch} from "vue";
  import {router, useForm, usePage, useRemember} from "@inertiajs/vue3";
  import {each, forEach} from "lodash";

  import Window from "@utils/Helpers/Window.js";
  import {coreRouter} from "@utils/coreRouter/coreRouter.js";
  import {getAge} from "@utils/Helpers/AgeCalulator.js";

  import ComponentAlert from "@components/Alerts/Alert.vue";
  import ComponentBreadcrumb from "@components/Breadcrumb/Breadcrumb.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentCard from "@components/Cards/Card.vue";
  import ComponentPatientSidebar from "@components/Sidebars/PatientSidebar.vue";
  import ComponentRecordProgress from "@pages/Records/Components/RecordProgress.vue";
  import ComponentStepper from "@components/Stepper.vue";

  import {
    bloodpressureKey,
    bmiKey,
    bodySizeKey,
    bodyWeightKey,
    creatinineKey,
    gfrKey,
    pulseKey,
  } from "@pages/Records/Components/Sections/LaborBar/enums.js";
  import {recordOriginKeys} from "@pages/Records/Enums/Enums.js";
  import {circumstance} from "@pages/Records/Components/Sections/MedicationBar/Components/Indications/enum.js";
  import {addDisease} from "@pages/Records/Components/Sections/DiseaseBar/Utils/diseaseHelper.js";

  import RecordComponentBeforeAnalyseDialog from "@pages/Records/Components/AnalyseDialog.vue";
  import RecordComponentSectionDiseaseBar from "@pages/Records/Components/Sections/DiseaseBar/DiseaseBar.vue";
  import RecordComponentSectionLaborBar from "@pages/Records/Components/Sections/LaborBar/LaborBar.vue";
  import RecordComponentSectionMedicationBar from "@pages/Records/Components/Sections/MedicationBar/MedicationBar.vue";
  import RecordComponentSectionSymptomBar from "@pages/Records/Components/Sections/SymptomBar/SymptomBar.vue";
  import RecordComponentSectionUserBar from "@pages/Records/Components/Sections/UserBar/UserBar.vue";
  import RecordComponentEditAgainDialog from "@pages/Records/Components/EditAgainDialog.vue";
  import RecordComponentJsonExporter from "@pages/Records/Components/JsonExporter.vue";
  import ComponentRecordInvalidatedDialog from "@components/Dialogs/RecordInvalidatedDialog.vue";
  import ComponentConfirmationDialog from "@components/Dialogs/ConfirmationDialog.vue";

  export default {
    name: "PagesRecordsShow",

    components: {
      ComponentConfirmationDialog,
      ComponentRecordInvalidatedDialog,
      RecordComponentJsonExporter,
      ComponentAlert,
      ComponentBreadcrumb,
      ComponentButton,
      ComponentCard,
      ComponentPatientSidebar,
      ComponentRecordProgress,
      ComponentStepper,
      RecordComponentBeforeAnalyseDialog,
      RecordComponentSectionDiseaseBar,
      RecordComponentSectionLaborBar,
      RecordComponentSectionMedicationBar,
      RecordComponentSectionSymptomBar,
      RecordComponentSectionUserBar,
      RecordComponentEditAgainDialog,
    },

    props: {
      patient: {
        type: Object,
        default: () => {},
      },
      record: {
        type: Object,
        default: () => {},
      },
      progressStates: {
        type: Object,
        default: () => {},
      },
      via: {
        type: String,
        default: null,
      },
      failed: {
        type: Array,
        default: () => [],
      },
      isEditable: {
        type: Boolean,
        default: false,
      },
      shareStatus: {
        type: String,
        default: null,
      },
    },

    setup(props) {
      const page = usePage();
      const privacy = inject("$privacy");
      const broadcast = inject("$broadcast");

      const refEditAgainDialog = ref(null);
      const refMedicationDeleteConfirmDialog = ref(null);
      const refRecordEditFinishedDialog = ref(null);

      const currentUser = ref(null);
      const recordData = reactive(props.record);
      const currentIsEditable = ref(props.isEditable);

      const unloadableMedicationsSolved = ref([]);
      const unloadableMedicationsUnsolved = ref([]);

      const unloadableSymptomsSolved = ref([]);
      const unloadableSymptomsUnsolved = ref([]);

      const unloadableDiseasesSolved = ref([]);
      const unloadableDiseasesUnsolved = ref([]);

      const isMedicationLoading = ref(true);
      const isSymptomLoading = ref(true);
      const isLaborvalueLoading = ref(true);
      const isDiseaseLoading = ref(true);
      const invalidDosageFields = ref([]);

      const rememberForm = useRemember(
        {
          active: "medicationbar",
          showDiseaseDialog: true,
        },
        `Record/Edit:${props.record.id}`,
      );

      const recordForm = useForm({
        username: null,
        userstatus: null,
      });

      const unloadableLaborvaluesSolved = computed(() => page.props.unloadable.laborvalues.solved?.data ?? []);
      const unloadableLaborvaluesUnsolved = computed(() => page.props.unloadable.laborvalues.unsolved?.data ?? []);

      const analysisButtonDisabled = computed(() => {
        return (
          unloadableMedicationsUnsolved.value.length > 0 ||
          unloadableMedicationsSolved.value.length > 0 ||
          unloadableSymptomsUnsolved.value.length > 0 ||
          unloadableSymptomsSolved.value.length > 0 ||
          unloadableLaborvaluesUnsolved.value.length > 0 ||
          unloadableLaborvaluesSolved.value.length > 0 ||
          unloadableDiseasesUnsolved.value.length > 0 ||
          unloadableDiseasesSolved.value.length > 0 ||
          invalidDosageFields.value.length > 0
        );
      });

      watch(
        () => props.record,
        (value, oldValue) => {
          Object.assign(recordData, props.record);

          checkMedications();
          checkSymptoms();
          checkLaborValues();
          checkDiseases();
        },
      );

      watch(
        () => props.record.medications || props.record.medications.length,
        () => {
          recordData.medications = props.record.medications;
          checkMedications();
        },
      );

      watch(
        () => props.record.symptoms || props.record.symptoms.length,
        () => {
          recordData.symptoms = props.record.symptoms;
          checkSymptoms();
        },
      );

      watch(
        () => props.record.diseases || props.record.diseases.length,
        () => {
          recordData.diseases = props.record.diseases;
          checkDiseases();
        },
      );

      watch(
        () => props.record.laborvalues || props.record.laborvalues.length,
        () => {
          recordData.laborvalues = props.record.laborvalues;
          checkLaborValues();
        },
      );

      onBeforeUnmount(() => {
        broadcast.record.offMessage(props.record.id, handleBroadcastMessage);
      });

      onMounted(() => {
        recordForm.username = props.record.username ?? privacy.getCurrentUser()?.name;
        recordForm.userstatus = props.record.userstatus ?? "pharmacist";

        const check = props.record.diseases.some(
          (disease) =>
            disease.abdata_key_miv === circumstance.OLDER_PATIENT ||
            disease.abdata_key_miv === circumstance.GERIATRIC_PATIENT,
        );

        if (
          props.record.created_at === props.record.updated_at_datetime &&
          !check &&
          recordData.previous_record_id === null
        ) {
          privacy.decryptValue(props.patient.birthdate).then((birthDate) => {
            const age = getAge(birthDate);

            if (age >= 65 && age < 75) {
              addAgeDisease(circumstance.OLDER_PATIENT);
            } else if (age >= 75) {
              addAgeDisease(circumstance.GERIATRIC_PATIENT);
            }
          });
        }

        currentUser.value = privacy.getCurrentUser();

        checkMedications();
        checkSymptoms();
        checkLaborValues();
        checkDiseases();

        broadcast.record.onMessage(props.record.id, handleBroadcastMessage);
      });

      const addAgeDisease = (keyMiv) => {
        coreRouter.loadMinCData(
          {filter: "diseases", "q[key_miv]": keyMiv},
          {
            onSuccess: (response) => {
              if (response.data.length === 1) {
                addDisease(props.patient.id, props.record.id, response.data[0]);
              }
            },
          },
        );
      };

      const handleBroadcastMessage = (data) => {
        switch (data?.action) {
          case "analyze":
            currentIsEditable.value = false;
            break;
          case "reset":
            currentIsEditable.value = true;
            break;
          case "reload":
            router.reload();
            break;
        }
      };

      const canExportJson = computed(() => page.props.can.json_export);

      const checkMedications = () => {
        isMedicationLoading.value = true;
        unloadableMedicationsSolved.value = [];
        unloadableMedicationsUnsolved.value = [];

        Promise.all(
          recordData.medications.map((medication, index) => {
            return new Promise((resolve) => {
              recordData.medications[index].core = {};
              recordData.medications[index].core.substancesText = getSubstances(medication.substances);

              coreRouter.loadMedicationPac(`?q=${medication.selected_package_pzn}`, {
                onSuccess: (response) => {
                  if (response.data.length > 0) {
                    const coreMedicationData = response.data[0];

                    // Add key fam to pzn objects
                    recordData.medications[index].abdata_key_fam = coreMedicationData.Key_FAM;

                    // Add further core data
                    recordData.medications[index].core.manufactureText = coreMedicationData.Anbieter;

                    recordData.medications[index].core.selectedPackageLabel =
                      coreMedicationData.Vertriebsstatus === "außer Vertrieb"
                        ? coreMedicationData.Vertriebsstatus
                        : medication.selected_package_pzn;

                    recordData.medications[index].core.unitList = coreMedicationData.does.map((dosageUnit) => {
                      return {keyDoe: dosageUnit.Key_DOE, unit: dosageUnit.Ausdruck};
                    });

                    recordData.medications[index].core.package = coreMedicationData.Packung;
                    recordData.medications[index].core.unloadable = false;

                    resolve();
                  } else {
                    coreRouter.loadMedicationPac(`?key_fam=${medication.abdata_key_fam}`, {
                      onSuccess: (response) => {
                        if (response.data.length === 1) {
                          const array = {
                            old: medication,
                            new: response.data[0],
                          };

                          unloadableMedicationsSolved.value.push(array);
                        } else {
                          const isAlreadyInArray = unloadableMedicationsUnsolved.value.some(
                            (item) => item.id === medication.id,
                          );

                          if (!isAlreadyInArray) {
                            unloadableMedicationsUnsolved.value.push(medication);
                            recordData.medications[index].core.unloadable = true;
                          }
                        }
                      },
                    });
                  }
                },
                onFinish: () => {
                  resolve();
                },
              });
            });
          }),
        ).then(() => {
          isMedicationLoading.value = false;
        });
      };

      const getSubstances = (substances) => {
        return substances
          .map((substance) => {
            return (substance.name + " " + substance.strength).trim();
          })
          .join(", ");
      };

      const checkSymptoms = () => {
        isSymptomLoading.value = true;
        unloadableSymptomsSolved.value = [];
        unloadableSymptomsUnsolved.value = [];

        Promise.all(
          recordData.symptoms.map((symptom, index) => {
            return new Promise((resolve) => {
              coreRouter.searchSymptoms(`&q[key_miv]=${symptom.abdata_key_miv}&q[gender]=${props.patient.gender}`, {
                onSuccess: (response) => {
                  if (response.data.length > 0) {
                    recordData.symptoms[index].core = {};

                    const responseSymptom = response.data.find(
                      (responseValue) => responseValue.Name === symptom.symptom,
                    );

                    recordData.symptoms[index].core.name = responseSymptom.Name;
                    recordData.symptoms[index].core.gender = responseSymptom.Geschlecht;
                    recordData.symptoms[index].core.keyMiv = responseSymptom.Key_MIV;
                    recordData.symptoms[index].core.preferredDrawing = responseSymptom.Vorzugsbezeichnung;
                    recordData.symptoms[index].core.preferredTermLayperson = responseSymptom.Vorzugsbezeichnung_Laie;
                    recordData.symptoms[index].core.counter = responseSymptom.Zaehler;
                  } else {
                    coreRouter.searchSymptoms(`&q[name]=${symptom.symptom}&q[gender]=${props.patient.gender}`, {
                      onSuccess: (response) => {
                        if (response.data.length < 0) {
                          forEach(response.data, (match) => {
                            if (
                              typeof match.key_miv_is_wording_match_for_search !== "undefined" &&
                              match.key_miv_is_wording_match_for_search === true
                            ) {
                              const array = {
                                old: symptom,
                                new: match,
                              };

                              unloadableSymptomsSolved.value.push(array);
                            }
                          });
                        } else {
                          const isAlreadyInArray = unloadableSymptomsUnsolved.value.some(
                            (item) => item.id === symptom.id,
                          );

                          if (!isAlreadyInArray) {
                            unloadableSymptomsUnsolved.value.push(symptom);
                            recordData.symptoms[index].core.unloadable = true;
                          }
                        }
                      },
                    });
                  }
                },
                onFinish: () => {
                  resolve();
                },
              });
            });
          }),
        ).then(() => {
          isSymptomLoading.value = false;
        });
      };

      const checkLaborValues = () => {
        isLaborvalueLoading.value = true;

        const mainLaborvalueList = [
          bloodpressureKey,
          bmiKey,
          bodySizeKey,
          bodyWeightKey,
          creatinineKey,
          gfrKey,
          pulseKey,
        ];

        const currentKeys = recordData.laborvalues.map((laborvalue) => laborvalue.mc_laborvalue_key);

        _searchMainLaborvalue(mainLaborvalueList, currentKeys)
          .then(() => {
            isLaborvalueLoading.value = false;
          })
          .catch(() => {});
      };

      const _searchMainLaborvalue = (keyList, currentKeys) => {
        return new Promise((resolve, reject) => {
          if (keyList.length === 0) {
            return resolve("main");
          }

          coreRouter.searchForLaborvaluesByKey(keyList.join(","), {
            onSuccess: (response) => {
              forEach(response.data, (laborvalueData) => {
                if (laborvalueData.default === true && !currentKeys.includes(laborvalueData.key)) {
                  const laborvalue = {
                    id: null,
                    type: laborvalueData.name,
                    date: null,
                    deviation: "normal",
                    value: null,
                    mc_laborvalue_id: laborvalueData.id,
                    mc_laborvalue_key: laborvalueData.key,
                    mc_unit_id: null,
                    unit: null,
                    core: {
                      type: laborvalueData.name ?? null,
                      mc_laborvalue_id: laborvalueData.id ?? null,
                      mc_laborvalue_key: laborvalueData.key ?? null,
                      unitList: laborvalueData.unitList ?? [],
                      referenceValueList: laborvalueData.referenceValueList ?? [],
                      referenceSymptoms: laborvalueData.referenceSymptoms ?? [],
                    },
                  };

                  recordData.laborvalues.push(laborvalue);
                }
              });

              resolve("main");
            },
            onError: () => {
              reject("main");
            },
          });
        });
      };

      const checkDiseases = () => {
        isDiseaseLoading.value = true;
        unloadableDiseasesSolved.value = [];
        unloadableDiseasesUnsolved.value = [];

        Promise.all(
          recordData.diseases.map((disease, index) => {
            return new Promise((resolve) => {
              coreRouter.searchDiseases(`&q[key_miv]=${disease.abdata_key_miv}&q[gender]=${props.patient.gender}`, {
                onSuccess: (response) => {
                  if (response.data.length > 0) {
                    recordData.diseases[index].core = {};
                    recordData.diseases[index].core.gender = response.data[0].Geschlecht;
                    recordData.diseases[index].core.keyMiv = response.data[0].Key_MIV;
                    recordData.diseases[index].core.name = response.data[0].Name;
                    recordData.diseases[index].core.preferredDrawing = response.data[0].Vorzugsbezeichnung;
                    recordData.diseases[index].core.preferredTermLayperson = response.data[0].Vorzugsbezeichnung_Laie;
                    recordData.diseases[index].core.counter = response.data[0].Zaehler;
                  } else {
                    coreRouter.searchDiseases(`&q[name]=${disease.disease}&q[gender]=${props.patient.gender}`, {
                      onSuccess: (response) => {
                        if (response.data.length > 0) {
                          const array = {
                            old: disease,
                            new: response.data[0],
                          };

                          unloadableDiseasesSolved.value.push(array);
                        } else {
                          const isAlreadyInArray = unloadableDiseasesUnsolved.value.some(
                            (item) => item.id === disease.id,
                          );

                          if (!isAlreadyInArray) {
                            unloadableDiseasesUnsolved.value.push(disease);
                            recordData.diseases[index].core.unloadable = true;
                          }
                        }
                      },
                    });
                  }
                },
                onFinish: () => {
                  resolve();
                },
              });
            });
          }),
        ).then(() => {
          isDiseaseLoading.value = false;
        });
      };

      const setActive = (stepId) => {
        rememberForm.value.active = stepId;
      };

      const saveRecord = () => {
        recordForm.patch(route("records.update", {patient: props.patient?.id, record: props.record?.id}));
      };

      const changeValue = (newValue, key) => {
        recordForm[key] = newValue;
      };

      const changeIsEditable = () => {
        refEditAgainDialog.value.open();
      };

      const barMessage = (recordKey, valueKey) => {
        let message = "";

        each(props.record[recordKey], (value) => {
          if (message.length === 0) message += value[valueKey];
          else if (value[valueKey].length > 0) message += ", " + value[valueKey];
        });

        return message;
      };

      const medicationBarMessage = () => {
        let message = "";

        each(props.record["medications"], (value) => {
          if (message.length === 0) message += value["fam"];
          else if (value["fam"].length > 0) message += ", " + value["fam"];
        });

        return message;
      };

      const laborBarMessage = () => {
        let message = "";

        each(props.record.laborvalues, (value) => {
          if (!value.created_by_user || value.deviation === "normal") {
            return;
          }

          const type = value.type.replace(/\((.*?)\)/, "");

          if (message.length === 0) {
            message += type + buildLaborDetailMessage(value);
          } else if (value.type.length > 0) {
            message += ", " + type + buildLaborDetailMessage(value);
          }
        });

        return message;
      };

      const buildLaborDetailMessage = (value) => {
        if (
          value.value !== null &&
          value.mc_laborvalue_key &&
          (value.mc_laborvalue_key === 182 || value.mc_laborvalue_key === 183)
        ) {
          return " (" + value.value.replace(".", ",") + " " + value.unit + ")";
        }

        const deviation = ["raised", "normal", "lowered"];

        if (value.deviation && deviation.includes(value.deviation)) {
          if (value.deviation === deviation[0]) {
            return " (↑)";
          } else if (value.deviation === deviation[1]) {
            return " (norm.)";
          } else if (value.deviation === deviation[2]) {
            return " (↓)";
          }
        }

        return "";
      };

      const setDoneAndAnalyze = () => {
        refRecordEditFinishedDialog.value.open();
      };

      const closeAndEmitAnalysisStart = () => {
        Window.open(route("execution.show", {patient: page.props.patient.id, record: page.props.record.id}), "_blank")
          .then(() => {
            broadcast.record.postMessage(props.record.id, {action: "analyze"});

            refRecordEditFinishedDialog.value.close();
          })
          .catch(() => {
            console.error("Execution open failed!");
          });
      };

      const openDeleteRowDialog = (data) => {
        refMedicationDeleteConfirmDialog.value.open(data);
      };

      const deleteRow = ({routeName, payload}) => {
        router.delete(
          route(`${routeName}.destroy`, {
            patient: page.props.patient.id,
            record: page.props.record.id,
            ...payload,
          }),
          {
            preserveScroll: true,
            onError: (error) => {
              console.error(error);
            },
          },
        );
      };

      const handleValidDosage = (dosageId) => {
        invalidDosageFields.value = invalidDosageFields.value.filter((id) => id !== dosageId);
      };

      const handleInvalidDosage = (dosageId, callback) => {
        if (invalidDosageFields.value.indexOf(dosageId) === -1) {
          callback();

          invalidDosageFields.value.push(dosageId);
        }
      };

      provide("recordEditDeleteRowDialog", openDeleteRowDialog);
      provide("recordEditHandleValidDosage", handleValidDosage);
      provide("recordEditHandleInvalidDosage", handleInvalidDosage);

      return {
        /** enum */
        recordOriginKeys,

        /** ref */
        refEditAgainDialog,
        refMedicationDeleteConfirmDialog,
        refRecordEditFinishedDialog,

        /** const */
        currentUser,
        recordData,
        currentIsEditable,
        unloadableMedicationsSolved,
        unloadableMedicationsUnsolved,
        unloadableSymptomsSolved,
        unloadableSymptomsUnsolved,
        unloadableDiseasesSolved,
        unloadableDiseasesUnsolved,
        isMedicationLoading,
        isSymptomLoading,
        isLaborvalueLoading,
        isDiseaseLoading,
        recordForm,
        rememberForm,

        /** computed */
        unloadableLaborvaluesSolved,
        unloadableLaborvaluesUnsolved,
        canExportJson,
        analysisButtonDisabled,

        /** function */
        setActive,
        saveRecord,
        changeValue,
        barMessage,
        medicationBarMessage,
        laborBarMessage,
        changeIsEditable,
        setDoneAndAnalyze,
        closeAndEmitAnalysisStart,
        deleteRow,
      };
    },
  };
</script>
