<template>
  <component-dialog ref="refDialog" :dialog-title="title" dialog-width="large">
    <template #content>
      <div v-if="status === 'form'" class="mt-5 flex flex-col gap-3">
        <component-alert v-if="error.type && error.text" :type="error.type" class="!mb-0">
          {{ error.text }}
        </component-alert>

        <label
          class="flex flex-col gap-3 items-center justify-center rounded border border-dashed border-gray-700/25 px-6 py-10 cursor-pointer hover:border-mcred"
          :class="{'!border-mcred': fileDragging}"
          @drag.prevent="fileDragging = true"
          @dragend.prevent="fileDragging = false"
          @dragenter.prevent="fileDragging = true"
          @dragover.prevent="fileDragging = true"
          @dragleave.prevent="fileDragging = false"
          @drop.prevent="processFileDrop"
        >
          <component-icon class="text-5xl">upload_file</component-icon>

          <span class="text-sm">JSON-Datei per Drag-and-Drop oder Klicken hochladen</span>

          <input type="file" class="sr-only" accept="application/json" @change.prevent="processFileUpload" />
        </label>

        <p>oder JSON-Code manuell einfügen</p>

        <textarea
          class="rounded border border-gray-700/25 resize-y"
          placeholder="Hier JSON-Code einfügen"
          rows="3"
          @input="processTextInput"
        />
      </div>

      <component-existing-patient-select
        v-else-if="status === 'existingPatients'"
        v-slot="{hasExact}"
        :current-patient="parsedJson.patient"
        :existing-patients="existingPatients"
        @existing-patient-selected="existingPatientSelected"
      >
        <component-alert type="warning">
          <span v-if="hasExact">Es wurden Patienten mit den gleichen Daten gefunden.</span>
          <span v-else>Es wurden Patienten mit ähnlichen Daten gefunden.</span>

          <span>
            Bitte wählen Sie einen Patienten aus, dem Sie mit den Daten aus der JSON-Datei eine neue Analyse hinzufügen
            möchten. Alternativ können Sie den BMP als einen neuen Patienten importieren.
          </span>
        </component-alert>
      </component-existing-patient-select>

      <component-existing-patient-select
        v-else-if="status === 'patientsNotMatch'"
        v-slot="{has}"
        :current-patient="parsedJson.patient"
        :existing-patients="existingPatients"
        :show-table="showExistingPatients"
        @existing-patient-selected="existingPatientSelected"
      >
        <component-alert type="error">
          <p>
            Die Daten aus der JSON-Datei stimmen nicht mit dem ausgewählten Patienten überein.
            <span v-if="has && showExistingPatients">
              Wählen Sie einen anderen Patienten aus oder importieren Sie die JSON-Datei zu diesem Patienten.
            </span>
            <span v-else-if="has">
              Möchten Sie die JSON-Datei weiterhin zu diesem Patienten importieren oder nach anderen Patienten suchen?
            </span>
            <span v-else>Möchten Sie die JSON-Datei weiterhin zu diesem Patienten importieren?</span>
          </p>

          <table v-if="!showExistingPatients" class="mt-3 table-auto">
            <thead>
              <tr>
                <th />
                <th class="pr-14">Ausgewählter Patient</th>
                <th>Patient aus JSON-Datei</th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(label, key, index) in {
                  firstname: 'Vorname',
                  lastname: 'Nachname',
                  gender: 'Geschlecht',
                  birthdate: 'Geburtsdatum',
                  insurancenumber: 'Versichertennummer',
                }"
                :key="index"
                class="hover:bg-mcred-200"
              >
                <th class="pl-0.5 pr-14 py-2">{{ label }}</th>

                <template v-if="key === 'gender'">
                  <td class="pl-0.5 pr-14 py-2">{{ genderToHuman(props.patient[key]) }}</td>
                  <td class="px-0.5 py-2">{{ genderToHuman(parsedJson.patient[key]) }}</td>
                </template>

                <template v-else-if="key === 'birthdate'">
                  <td class="pl-0.5 pr-14 py-2">{{ dateToHuman(props.patient[key]) }}</td>
                  <td class="px-0.5 py-2">{{ dateToHuman(parsedJson.patient[key]) }}</td>
                </template>

                <template v-else>
                  <td class="pl-0.5 pr-14 py-2">{{ props.patient[key] }}</td>
                  <td class="px-0.5 py-2">{{ parsedJson.patient[key] }}</td>
                </template>
              </tr>
            </tbody>
          </table>
        </component-alert>
      </component-existing-patient-select>

      <div v-else class="h-40 flex justify-center items-center">
        <component-spinner class="w-10 h-10" />
      </div>
    </template>

    <template #actions>
      <component-button v-if="status === 'existingPatients'" class="p4umc-primary" @click="saveAndOpenRecord">
        Als neuen Patienten importieren
      </component-button>

      <template v-else-if="status === 'patientsNotMatch'">
        <component-button class="p4umc-primary" @click="performImportToCurrentPatient">
          Zu bestehendem Patienten importieren
        </component-button>

        <component-button
          v-if="existingPatients.length > 0 && !showExistingPatients"
          class="p4umc-primary"
          @click="showExistingPatients = true"
        >
          Nach anderen Patienten suchen
        </component-button>
      </template>

      <component-button @click="close">Abbrechen</component-button>
    </template>
  </component-dialog>
</template>

<script>
  import {inject, onBeforeUnmount, reactive, ref, toRefs} from "vue";
  import {debounce} from "lodash";
  import {router} from "@inertiajs/vue3";

  import {patientGender} from "@pages/Patients/Enums/Enums.js";
  import filterExistingPatients from "@utils/Helpers/FilterExistingPatients.js";
  import resetState from "@utils/Helpers/ResetState.js";

  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentAlert from "@components/Alerts/Alert.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentExistingPatientSelect from "@components/ExistingPatientSelect/ExistingPatientSelect.vue";
  import ComponentSpinner from "@components/Spinner.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";

  export default {
    name: "ComponentJsonImportDialog",

    components: {
      ComponentIcon,
      ComponentSpinner,
      ComponentExistingPatientSelect,
      ComponentButton,
      ComponentAlert,
      ComponentDialog,
    },

    props: {
      title: {
        type: String,
        default: "Neue Variante aus JSON-Datei erstellen",
      },
    },

    setup() {
      const privacy = inject("$privacy");
      const axios = inject("$axios");

      /*
      |--------------------------------------------------------------------------
      | Component references
      |--------------------------------------------------------------------------
      */

      const /** @type {import("vue").Ref<{open: VoidFunction, close: VoidFunction}>} */ refDialog = ref(null);

      /*
      |--------------------------------------------------------------------------
      | Variables
      |--------------------------------------------------------------------------
      */

      /** @typedef {{id, firstname, lastname, gender, birthdate, insurancenumber}} Patient */

      const initialState = {
        /** @type {Boolean} */
        showExistingPatients: false,

        /** @type {Patient[]} */
        existingPatients: [],

        /** @type {("form"|"existingPatients"|"patientsNotMatch"|"loading")} */
        status: "form",

        /** @type {{type: ?("error"|"warning"), text: ?String}} */
        error: {type: null, text: null},

        /** @type {{patient: ?Patient}} */
        props: {patient: null},

        /** @type {?{patient: Patient, record: Object}} */
        parsedJson: null,

        /** @type {Boolean} */
        fileDragging: false,
      };

      const state = reactive({...initialState});

      /*
      |--------------------------------------------------------------------------
      | File Handlers
      |--------------------------------------------------------------------------
      */

      /**
       * Process data dropped as file(s)/folder in drop area
       *
       * @param {InputEvent} event
       */
      const processFileUpload = (event) => {
        processFileList(event.target.files);
      };

      /**
       * Process data dropped in drop area
       *
       * @param {DragEvent} event
       */
      const processFileDrop = (event) => {
        state.fileDragging = false;
        processFileList(event.dataTransfer.files);
      };

      /**
       * Process a FileListe from file upload or file drop
       *
       * @param {FileList} fileList
       * @return {Promise<void>}
       */
      const processFileList = async (fileList) => {
        if (fileList.length !== 1) {
          return setError("error", "Bitte laden Sie jeweils nur eine JSON-Datei hoch.");
        }

        const item = fileList.item(0);

        if (item.type !== "application/json") {
          return setError("error", "Bitte laden Sie nur JSON-Dateien hoch.");
        }

        try {
          let text = await item.text();

          if (text.includes("�")) {
            text = new TextDecoder("iso-8859-1").decode(await item.arrayBuffer());
          }

          processJson(text);
        } catch (e) {
          return setError(
            "error",
            "Beim Verarbeiten der Datei ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
          );
        }
      };

      const processTextInput = debounce(
        /** @param {InputEvent} event **/ (event) => {
          processJson(event.target.value);
        },
        1000,
      );

      /*
      |--------------------------------------------------------------------------
      | Methods
      |--------------------------------------------------------------------------
      */

      /** @param {["props"|String]} not */
      const reset = (not = []) => {
        Object.entries(initialState).forEach(([key, value]) => {
          if (!not.includes(key)) {
            state[key] = resetState(value);
          }
        });
      };

      const open = (patient = null) => {
        reset();
        state.props.patient = patient;
        refDialog.value.open();
      };

      const close = () => {
        refDialog.value?.close();
        reset();
      };

      const existingPatientSelected = (selectedPatient) => {
        saveAndOpenRecord({patient: selectedPatient.id});
      };

      const performImportToCurrentPatient = () => {
        saveAndOpenRecord({patient: state.props.patient.id});
      };

      const setError = (type, text) => {
        state.error.type = type;
        state.error.text = text;
      };

      const clearError = () => {
        state.error.type = null;
        state.error.text = null;
      };

      /*
      |--------------------------------------------------------------------------
      | JSON Processors
      |--------------------------------------------------------------------------
      */

      /**
       *
       * @param {String} json
       */
      const processJson = async (json) => {
        if (json.trim().length === 0) {
          return;
        }

        clearError();

        try {
          state.parsedJson = JSON.parse(json.trim());
        } catch (e) {
          return setError("error", "Bitte laden Sie nur gültige JSON-Dateien hoch.");
        }

        if (
          state.props.patient &&
          (state.props.patient.firstname !== state.parsedJson.patient.firstname ||
            state.props.patient.lastname !== state.parsedJson.patient.lastname ||
            state.props.patient.gender !== state.parsedJson.patient.gender ||
            state.props.patient.birthdate !== state.parsedJson.patient.birthdate ||
            state.props.patient.insurancenumber !== state.parsedJson.patient.insurancenumber)
        ) {
          state.status = "loading";
          await searchExistingPatients();
          state.status = "patientsNotMatch";
        } else {
          state.status = "loading";

          await searchExistingPatients();

          if (state.existingPatients.length > 0) {
            state.status = "existingPatients";
          } else if (state.props.patient) {
            saveAndOpenRecord({patient: state.props.patient.id});
          } else {
            saveAndOpenRecord();
          }
        }
      };

      const searchExistingPatients = async () => {
        const search = async () => {
          state.existingPatients = await filterExistingPatients(
            state.parsedJson.patient,
            await Promise.all(
              (
                await axios.post(route("api.patients.search"), await privacy.encryptPatient(state.parsedJson.patient))
              ).data.data.map(async (patient) => await privacy.decryptPatient(patient)),
            ),
            ["birthdate"],
          );
        };

        try {
          await search();
        } catch (e) {
          // If privacy password is expired
          await new Promise((resolve) => {
            const interval = setInterval(async () => {
              if (privacy.hasValidLocalStorage()) {
                await search();
                clearInterval(interval);
                resolve();
              }
            }, 1000);
          });
        }
      };

      const saveAndOpenRecord = async (routeParams = {}) => {
        state.status = "loading";

        state.parsedJson.patient = await privacy.encryptPatient(state.parsedJson.patient);

        router.post(route("init-from-json", {origin: "json", ...routeParams}), state.parsedJson, {
          preserveState: true,
          preserveScroll: true,
          onSuccess: () => close(),
          onError: () => {
            reset(["props"]);
            setError("error", "Beim Importieren ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.");
            state.status = "form";
          },
        });
      };

      const dateToHuman = (value) => {
        let d = new Date(value);
        return isNaN(d.getTime()) ? "" : d.toLocaleDateString("de-DE", {dateStyle: "medium"});
      };

      const genderToHuman = (value) => {
        switch (value) {
          case "male":
            return "männlich";
          case "female":
            return "weiblich";
          case "diverse":
            return "divers";
          default:
            return null;
        }
      };

      /*
      |--------------------------------------------------------------------------
      | Lifecycle Hooks
      |--------------------------------------------------------------------------
      */

      onBeforeUnmount(close);

      /*
      |--------------------------------------------------------------------------
      | Return for template and external components
      |--------------------------------------------------------------------------
      */

      return {
        refDialog,
        ...toRefs(state),
        open,
        close,
        processFileUpload,
        processFileDrop,
        processTextInput,
        existingPatientSelected,
        performImportToCurrentPatient,
        saveAndOpenRecord,
        dateToHuman,
        genderToHuman,
        patientGender,
      };
    },
  };
</script>

<style scoped></style>
