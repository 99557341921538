<template>
  <div class="w-full h-full relative z-0">
    <div ref="scannerContainer" class="w-full h-full" />

    <div class="w-full h-full absolute top-0 left-0">
      <slot />
    </div>
  </div>
</template>

<script>
  import {onBeforeUnmount, onMounted, ref, watch} from "vue";
  import * as SDCCore from "scandit-web-datacapture-core";
  import * as SDCBarcode from "scandit-web-datacapture-barcode";

  /**
   * @typedef {import("vue").PropType} PropType
   * @typedef {import("vue").Ref} Ref
   */

  export default {
    name: "ComponentBarcodeScanner",

    props: {
      // we have only a license for QR, DataMatrix, Code39 and Code128
      /** @type {PropType<import("scandit-web-datacapture-barcode").Symbology[]>} */
      barcodeFormats: {
        type: Array,
        default() {
          return [SDCBarcode.Symbology.DataMatrix, SDCBarcode.Symbology.Code39];
        },
      },
      /** @type {PropType<Boolean>} */
      paused: {
        type: Boolean,
        default: false,
      },
    },

    emits: ["camera-on", "error", "detect"],

    setup(props, {emit}) {
      const /** @type {Ref<HTMLDivElement>} */ scannerContainer = ref(null);

      let /** @type {SDCBarcode.BarcodeCapture} */ barcodeCapture = null;
      let /** @type {import("scandit-web-datacapture-core").Camera} */ camera = null;

      onMounted(async () => {
        try {
          await SDCCore.configure({
            licenseKey: import.meta.env.VITE_SCANDIT_LICENSE_KEY,
            logLevel: SDCCore.Logger.Level.Quiet,
            libraryLocation: import.meta.env.DEV
              ? import.meta.url.split("/re")[0] + "/node_modules/scandit-web-datacapture-barcode/build/engine/"
              : "/build/scandit-sdk/",
            moduleLoaders: [SDCBarcode.barcodeCaptureLoader()],
          });

          await startBarcodeScanner(props.barcodeFormats);
          emit("camera-on");
        } catch (e) {
          onError(e);
        }
      });

      onBeforeUnmount(() => {
        if (barcodeCapture) {
          barcodeCapture.setEnabled(false);
          barcodeCapture.context.frameSource.switchToDesiredState(SDCCore.FrameSourceState.Off);
        }
      });

      const startBarcodeScanner = async (
        /** @type {import("scandit-web-datacapture-barcode").Symbology[]} */ barcodeFormats,
      ) => {
        try {
          const container = scannerContainer.value;

          const view = new SDCCore.DataCaptureView();

          const context = await SDCCore.DataCaptureContext.create();

          context.addListener({
            didChangeStatus: (context, contextStatus) => {
              if (!contextStatus.isValid) {
                onError({name: "InvalidLicenseKey"});
              }
            },
          });

          await view.setContext(context);

          const settings = new SDCBarcode.BarcodeCaptureSettings();
          settings.enableSymbologies(barcodeFormats);
          settings.codeDuplicateFilter = 1000;
          settings.getProperty();

          barcodeCapture = await SDCBarcode.BarcodeCapture.forContext(context, settings);

          const feedback = new SDCBarcode.BarcodeCaptureFeedback();
          feedback.success = new SDCCore.Feedback(null, null);

          await barcodeCapture.setFeedback(feedback);

          barcodeCapture.addListener({
            didScan: (barcodeCapture, session) => {
              const recognizedBarcodes = session.newlyRecognizedBarcodes;
              emit("detect", recognizedBarcodes);
            },
          });

          view.logoStyle = SDCCore.LogoStyle.Minimal;

          const cameraSettings = SDCBarcode.BarcodeCapture.recommendedCameraSettings;

          camera = SDCCore.Camera.default;

          if (camera) {
            await camera.applySettings(cameraSettings);
            await camera.setMirrorImageEnabled(false);
          }

          await context.setFrameSource(camera);

          view.connectToElement(container);
          await camera.switchToDesiredState(SDCCore.FrameSourceState.On);
        } catch (e) {
          onError(e);
        }
      };

      const onError = (e = null) => {
        const error = {
          name: e?.name ?? "UnknownError",
          message: null,
        };

        if (["NotAllowedError"].includes(e?.name)) {
          error.message =
            "Bitte verwenden Sie ein Gerät mit einer Kamera oder erlauben den Zugriff auf diese in den Browser- bzw. Systemeinstellungen.";
        } else if (e?.name === "InvalidLicenseKey") {
          error.message = "Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie den Kundensupport.";
        } else {
          error.message = "Es ist ein Fehler aufgetreten. Bitte laden Sie die Seite neu und versuchen es erneut.";
        }

        emit("error", error);
      };

      watch(
        () => props.paused,
        (shouldPause, isPaused) => {
          if (shouldPause === isPaused) return;

          if (shouldPause && barcodeCapture) {
            camera.switchToDesiredState(SDCCore.FrameSourceState.Off);
            barcodeCapture.setEnabled(false);
          } else if (!shouldPause && barcodeCapture) {
            camera.switchToDesiredState(SDCCore.FrameSourceState.On);
            barcodeCapture.setEnabled(true);
          }
        },
      );

      watch(
        () => props.barcodeFormats,
        (newBarcodeFormats, oldBarcodeFormats) => {
          if (JSON.stringify(newBarcodeFormats) !== JSON.stringify(oldBarcodeFormats)) {
            startBarcodeScanner(newBarcodeFormats);
          }
        },
        {deep: true},
      );

      return {
        scannerContainer,
      };
    },
  };
</script>

<style scoped></style>
