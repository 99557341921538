<template>
  <div class="min-h-screen flex flex-col">
    <InertiaHead :title="title" class="print:hidden" />
    <layout-guest-header />

    <main id="container" class="flex-grow py-4 px-2 md:px-4 print:hidden">
      <div class="h-full flex flex-col justify-center items-center">
        <div class="text-4xl text-center">
          {{ code }}
        </div>

        <div class="text-xl text-center">
          {{ title }}
        </div>

        <div class="text-md text-center">
          {{ description }}

          <div v-if="isDev" class="bg-mcred-100 p-1 rounded">Message: {{ message }}</div>
        </div>

        <div class="mt-3">
          <inertia-link
            v-if="isLoggedIn"
            as="button"
            :href="$route('dashboard.index')"
            class="p4umc-btn overflow-hidden uppercase p-1 rounded border-2 focus-visible:ring ring-red-500 outline-0 p4umc-primary"
          >
            zum Dashboard
          </inertia-link>

          <inertia-link
            v-else
            as="button"
            :href="$route('login')"
            class="p4umc-btn overflow-hidden uppercase p-1 rounded border-2 focus-visible:ring ring-red-500 outline-0 p4umc-primary"
          >
            zum Login
          </inertia-link>

          <button
            type="button"
            class="ml-3 p4umc-btn overflow-hidden uppercase p-1 rounded border-2 focus-visible:ring ring-red-500 outline-0"
            @click="getBack"
          >
            Zurück
          </button>
        </div>
      </div>
    </main>

    <footer>
      <div class="text-center">
        <a href="https://www.pharma4u.de/allgemein/datenschutz/" class="mr-5">Datenschutz</a>
        <a href="https://www.pharma4u.de/allgemein/impressum/">Impressum</a>
      </div>
      <div class="text-center text-xs">© {{ currentYear }} pharma4u GmbH</div>
    </footer>
  </div>
</template>

<script>
  import {computed} from "vue";
  import {Link as InertiaLink, Head as InertiaHead} from "@inertiajs/vue3";

  import LayoutGuestHeader from "@components/Layout/Components/GuestHeader.vue";

  export default {
    name: "PagesErrorShow",

    components: {LayoutGuestHeader, InertiaLink, InertiaHead},

    props: {
      code: {
        type: Number,
        default: 0,
      },
      message: {
        type: String,
        default: "",
      },
      isLoggedIn: {
        type: Boolean,
        default: false,
      },
    },

    setup(props) {
      const title = computed(() => {
        return {
          503: "Dienst nicht verfügbar",
          500: "Serverfehler",
          429: "Zu viele Anfragen",
          419: "Sitzung abgelaufen",
          404: "Seite nicht gefunden",
          403: "Verboten",
          400: "Bad Request",
          401: "Unautorisiert",
        }[props.code];
      });

      const description = computed(() => {
        return {
          503: "Entschuldigung, wir führen gerade Wartungsarbeiten durch. Bitte versuchen Sie es später erneut.",
          500: "Hoppla, auf unseren Servern ist etwas schiefgelaufen.",
          429: "Entschuldigung, Sie haben zu viele Anfragen gesendet. Bitte versuchen Sie es später erneut.",
          419: "Entschuldigung, die Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.",
          404: "Entschuldigung, die von Ihnen gesuchte Seite konnte nicht gefunden werden.",
          403: "Entschuldigung, Sie sind nicht berechtigt, auf diese Seite zuzugreifen.",
          400: "Entschuldigung, die Anfrage konnte nicht verarbeitet werden.",
          401: "Entschuldigung, Sie sind nicht autorisiert, diese Seite zu sehen.",
        }[props.code];
      });

      const currentYear = computed(() => new Date().getFullYear());

      const isDev = computed(() => import.meta.env.DEV);

      const getBack = () => {
        window.history.back();
      };

      return {title, description, currentYear, isDev, getBack};
    },
  };
</script>
