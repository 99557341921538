<template>
  <div id="p4umc-documents" class="mb-4">
    <component-card title="Dokumente / Arbeitshilfen">
      <ol class="pb-8 space-y-8">
        <li>
          <h2 class="text-lg">I. MediCheck-Dokumente</h2>
          <ul class="mt-2 list-disc ml-6 space-y-4">
            <li v-if="hasThirdParty('p4u') && hasUrlRedirect('medicheck-overview')">
              <document-component-url-redirect :url-redirect="getUrlRedirect('medicheck-overview')" />
            </li>

            <!-- Gebrauchsanweisung (2 Versionen) -->
            <li v-if="hasThirdParty('p4u') && hasUrlRedirect('manual-medicheck')">
              <document-component-url-redirect :url-redirect="getUrlRedirect('manual-medicheck')" />
            </li>
            <li v-if="hasThirdParty('nident') && hasUrlRedirect('manual-medicheck-wawi')">
              <document-component-url-redirect :url-redirect="getUrlRedirect('manual-medicheck-wawi')" />
            </li>

            <li v-if="hasThirdParty('p4u') && hasUrlRedirect('short-manual-medicheck')">
              <document-component-url-redirect :url-redirect="getUrlRedirect('short-manual-medicheck')" />
            </li>
            <li v-if="hasUrlRedirect('declaration-of-conformity')">
              <document-component-url-redirect :url-redirect="getUrlRedirect('declaration-of-conformity')" />
            </li>
            <li v-if="hasUrlRedirect('privacy-consent')">
              <document-component-url-redirect :url-redirect="getUrlRedirect('privacy-consent')" />
            </li>
            <li v-if="hasUrlRedirect('pdl-agreement-short')">
              <document-component-url-redirect :url-redirect="getUrlRedirect('pdl-agreement-short')" />
              <document-component-url-redirect
                v-if="hasUrlRedirect('pdl-agreement-source')"
                :url-redirect="getUrlRedirect('pdl-agreement-source')"
              />
            </li>
            <li v-if="hasUrlRedirect('pdl-agreement-nursing-home-short')">
              <document-component-url-redirect :url-redirect="getUrlRedirect('pdl-agreement-nursing-home-short')" />
            </li>
            <li v-if="hasThirdParty('nident') && hasUrlRedirect('training-video-medicheck-wawi')">
              <document-component-url-redirect :url-redirect="getUrlRedirect('training-video-medicheck-wawi')" />
            </li>
            <li v-if="hasThirdParty('p4u') && hasUrlRedirect('updates-medicheck-p4u')">
              <document-component-url-redirect :url-redirect="getUrlRedirect('updates-medicheck-p4u')" />
            </li>
            <li v-else-if="hasThirdParty('nident') && hasUrlRedirect('updates-medicheck-nident')">
              <document-component-url-redirect :url-redirect="getUrlRedirect('updates-medicheck-nident')" />
            </li>
          </ul>
        </li>

        <li>
          <h2 class="text-lg">II. Honorierte pharmazeutische Dienstleistungen gemäß ABDA</h2>
          <ul class="mt-2 list-disc ml-6 space-y-4">
            <li v-if="hasUrlRedirect('abda-pdl-overview')">
              <document-component-url-redirect :url-redirect="getUrlRedirect('abda-pdl-overview')" />
            </li>
            <li v-if="hasUrlRedirect('abda-pdl-polymedication')">
              <document-component-url-redirect :url-redirect="getUrlRedirect('abda-pdl-polymedication')" />
            </li>
            <li v-if="hasUrlRedirect('abda-care-for-organ-transplant-patients')">
              <document-component-url-redirect
                :url-redirect="getUrlRedirect('abda-care-for-organ-transplant-patients')"
              />
            </li>
            <li v-if="hasUrlRedirect('abda-care-for-oral-antitumour-therapy')">
              <document-component-url-redirect
                :url-redirect="getUrlRedirect('abda-care-for-oral-antitumour-therapy')"
              />
            </li>
          </ul>
        </li>

        <li>
          <h2 class="text-lg">III. Weitere honorierte pharmazeutische Dienstleistungen gemäß ABDA</h2>
          <ul class="mt-2 list-disc ml-6 space-y-4">
            <li v-if="hasUrlRedirect('abda-pdl-inhalants')">
              <document-component-url-redirect :url-redirect="getUrlRedirect('abda-pdl-inhalants')" />
            </li>
            <li v-if="hasUrlRedirect('abda-pdl-blood-pressure')">
              <document-component-url-redirect :url-redirect="getUrlRedirect('abda-pdl-blood-pressure')" />
            </li>
          </ul>
        </li>

        <li>
          <h2 class="text-lg">IV. Leitlinien und Arbeitshilfen zur Medikationsanalyse (allgemein)</h2>
          (ACHTUNG: für pharmazeutische Dienstleistung siehe oben unter I. und/oder II.)
          <ul class="mt-2 list-disc ml-6 space-y-4">
            <li v-if="hasUrlRedirect('abda-guideline-amts')">
              <document-component-url-redirect :url-redirect="getUrlRedirect('abda-guideline-amts')" />
            </li>
            <li v-if="hasUrlRedirect('abda-guideline-amts-comment')">
              <document-component-url-redirect :url-redirect="getUrlRedirect('abda-guideline-amts-comment')" />
            </li>
            <li v-if="hasUrlRedirect('abda-amts-flow-chart')">
              <document-component-url-redirect :url-redirect="getUrlRedirect('abda-amts-flow-chart')" />
            </li>
            <li v-if="hasUrlRedirect('abda-process-description-amts-in-nursing-homes')">
              <document-component-url-redirect
                :url-redirect="getUrlRedirect('abda-process-description-amts-in-nursing-homes')"
              />
            </li>
            <li v-if="hasUrlRedirect('abda-sop-amts-in-nursing-homes')">
              <document-component-url-redirect :url-redirect="getUrlRedirect('abda-sop-amts-in-nursing-homes')" />
            </li>
            <li v-if="hasUrlRedirect('abda-checklist-amts-in-nursing-homes')">
              <document-component-url-redirect :url-redirect="getUrlRedirect('abda-checklist-amts-in-nursing-homes')" />
            </li>
          </ul>
        </li>
      </ol>
    </component-card>
  </div>
</template>

<script>
  import {inject} from "vue";

  import ComponentCard from "@components/Cards/Card.vue";
  import DocumentComponentUrlRedirect from "@pages/Document/Components/UrlRedirect.vue";

  export default {
    name: "PagesDocumentIndex",

    components: {
      DocumentComponentUrlRedirect,
      ComponentCard,
    },

    props: {
      urlRedirects: {
        type: Array,
        default: () => {
          return [];
        },
      },
    },

    setup(props) {
      const hasThirdParty = inject("$hasThirdParty");

      function hasUrlRedirect(identifier) {
        let found = props.urlRedirects.some((obj) => {
          return obj.identifier === identifier;
        });

        if (!found) {
          console.error('Missing identifier in url redirects: "' + identifier + '".');
        }

        return found;
      }

      function getUrlRedirect(identifier) {
        return props.urlRedirects.find((obj) => {
          return obj.identifier === identifier;
        });
      }

      return {hasThirdParty, hasUrlRedirect, getUrlRedirect};
    },
  };
</script>
