<template>
  <div class="relative bg-gray-100 p-1 rounded-md flex items-start hover:bg-gray-200/60">
    <component-spinner v-if="form.processing" class="ml-0.5 mr-3 w-5 h-5" />
    <component-tooltip v-else-if="completed" tooltip="auf nicht erledigt zurücksetzen">
      <button class="mr-2 rounded-full" @click="setPending()">
        <component-icon class="text-2xl text-mcgreen">check_circle</component-icon>
      </button>
    </component-tooltip>
    <component-tooltip v-else tooltip="als erledigt markieren">
      <button class="mr-2 group/todo rounded-full" @click="setComplete()">
        <component-icon class="group-hover/todo:hidden text-2xl text-gray-600">radio_button_unchecked</component-icon>
        <component-icon class="hidden group-hover/todo:block text-2xl text-gray-600">
          check_circle_outline
        </component-icon>
      </button>
    </component-tooltip>

    <div class="w-full">
      <div class="flex items-center justify-between space-x-2">
        <div class="w-full">
          <div class="flex justify-between items-start space-x-1">
            <button v-if="!expanded" class="w-full text-left text-sm" @click="openDetails()">
              <component-tooltip tooltip="bearbeiten">
                <span class="hover:underline">{{ todo.label }}</span>
              </component-tooltip>
            </button>
            <component-tooltip v-if="!expanded && todo.description" tooltip="enthält Details">
              <component-icon
                class="mt-0.5 text-gray-400 hover:text-gray-900 font-semibold"
                :clickable="true"
                @click="showDescription = !showDescription"
              >
                notes
              </component-icon>
            </component-tooltip>
            <component-tooltip v-if="!expanded && !todo.url.endsWith($page.url)" tooltip="zum Element springen">
              <inertia-link :href="todo.url" class="hover:no-underline text-gray-400 hover:text-gray-900">
                <component-icon class="font-semibold">launch</component-icon>
              </inertia-link>
            </component-tooltip>
          </div>
          <div v-if="!expanded && showDescription" class="text-xs text-gray-600">{{ todo.description }}</div>
          <span v-if="!expanded && completed" class="block text-xs">erledigt {{ todo.completed_at }}</span>
        </div>
      </div>

      <div v-if="expanded" class="px-2 pb-2 bg-white space-y-2">
        <component-input ref="refLabel" v-model="form.label" :validation="form.errors.label" :hide-clear="true" />
        <component-textarea
          v-model="form.description"
          :validation="form.errors.description"
          label="Details"
          maxlength="255"
          rows="3"
        />

        <div
          :class="{
            'flex-row-reverse space-x-4 space-x-reverse xl:flex-col xl:space-x-0 xl:space-y-4 xl:items-stretch': !layer,
            'flex-col space-x-0 space-y-4 items-stretch': layer,
          }"
          class="flex"
        >
          <component-button class="p4umc-primary" label="Speichern" :disabled="form.processing" @click="update()" />
          <component-button type="button" label="Abbrechen" :disabled="form.processing" @click="closeDetails()" />
          <component-button
            type="button"
            class="p4umc-flat p4umc-primary"
            label="Löschen"
            :disabled="form.processing"
            @click="remove()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ComponentIcon from "@components/Icons/Icon.vue";
  import {nextTick, ref} from "vue";
  import ComponentInput from "@components/Inputs/Input.vue";
  import ComponentTextarea from "@components/Textarea/Textarea.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import {useForm, Link as InertiaLink} from "@inertiajs/vue3";
  import ComponentSpinner from "@components/Spinner.vue";
  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";

  export default {
    name: "ComponentTodoListTodoItem",
    components: {
      InertiaLink,
      ComponentTooltip,
      ComponentSpinner,
      ComponentButton,
      ComponentTextarea,
      ComponentInput,
      ComponentIcon,
    },
    props: {
      todo: Object,
      patientId: String,
      /**
       * layer: embedded in layer
       */
      layer: {
        type: Boolean,
        default: false,
      },
    },
    setup(props) {
      const refLabel = ref(null);
      const expanded = ref(false);
      const showDescription = ref(false);

      const form = useForm({
        completed_at: props.todo.completed_at !== null,
        label: props.todo.label,
        description: props.todo.description,
      });

      const completed = ref(props.todo.completed_at !== null);

      function openDetails() {
        expanded.value = true;
        nextTick(() => {
          refLabel.value.focus();
        });
      }

      function closeDetails() {
        expanded.value = false;
      }
      function remove() {
        form.delete(route("todos.destroy", {patient: props.patientId, todo: props.todo.id}), {
          preserveScroll: true,
        });
      }

      function update() {
        form.patch(route("todos.update", {patient: props.patientId, todo: props.todo.id}), {
          preserveScroll: true,
          onSuccess: () => {
            closeDetails();
          },
        });
      }

      function setPending() {
        form.completed_at = false;

        form.patch(route("todos.update", {patient: props.patientId, todo: props.todo.id}), {
          preserveScroll: true,
        });
      }

      function setComplete() {
        form.completed_at = true;

        form.patch(route("todos.update", {patient: props.patientId, todo: props.todo.id}), {
          preserveScroll: true,
        });
      }

      return {
        expanded,
        showDescription,
        update,
        remove,
        form,
        completed,
        setPending,
        setComplete,
        openDetails,
        closeDetails,
        refLabel,
      };
    },
  };
</script>
