<template>
  <li class="grid grid-cols-12 gap-x-2 border-b-1 border-gray-300 py-2" :class="[{'opacity-50': isDiscontinued}]">
    <!-- row 1 - name and fi -->
    <div class="flex col-span-12">
      <!-- name -->
      <template v-if="isEditable">
        <div class="flex">
          <div v-if="medicationForm.value.fam" class="more icon-wrap m-auto">
            <component-tooltip
              placement="right-end"
              tooltip="Zeile verschieben"
              custom-class-label="flex items-center"
              :force-hidden="isDragging"
            >
              <component-icon class="text-gray-900 text-xl mt-0.5" drag-handle>drag_handle</component-icon>
            </component-tooltip>
          </div>

          <div v-if="!medicationForm.value.fam" class="more icon-wrap">
            <component-icon class="text-gray-400 text-xl mt-0.5" drag-handle>drag_handle</component-icon>
          </div>

          <a
            class="font-medium ml-3 !text-gray-900 text-md cursor-pointer flex min-w-full pr-2 m-auto"
            :class="{'line-through': isDiscontinued}"
            @click="openDetails"
          >
            <component-tooltip tooltip="nicht analysierbar">
              <span
                v-if="medicationForm.value.abdata_key_fam === null || medicationForm.value.abdata_key_fam === 0"
                class="text-mcred pr-1 cursor-pointer"
              >
                **
              </span>
            </component-tooltip>

            <component-tooltip
              placement="top"
              :tooltip="medicationForm.value.fam"
              custom-class-label="flex items-center"
            >
              {{ medicationForm.value.fam }}
            </component-tooltip>
          </a>
        </div>
      </template>

      <template v-else>
        <div class="flex items-center mx-2">
          <component-tooltip tooltip="nicht analysierbar">
            <span
              v-if="medicationForm.value.abdata_key_fam === null || medicationForm.value.abdata_key_fam === 0"
              class="text-mcred pr-1 cursor-pointer"
            >
              **
            </span>
          </component-tooltip>

          <component-tooltip placement="top" :tooltip="medicationForm.value.fam" custom-class-label="flex items-center">
            <span class="line-clamp-1" :class="{'line-through': isDiscontinued}" @click.prevent="openDetails">
              {{ medication.fam }}
            </span>
          </component-tooltip>
        </div>
      </template>

      <!-- fi button -->
      <div v-if="medicationForm.value.redhand" class="relative align-middle">
        <component-tooltip tooltip="Rote-Hand-Brief (ggf. weitere Dokumente)">
          <button
            class="rounded-full bg-gray-200 w-5 h-5 text-xs"
            @click="openDialog('refCoreInfoDialog', medicationForm.value)"
          >
            FI
          </button>
          <component-icon class="absolute p4umc-icon right-1/3 select-none text-mcred text-sm top-0">
            pan_tool
          </component-icon>
        </component-tooltip>
      </div>

      <div v-else class="flex items-center">
        <component-tooltip tooltip="Fachinformation (ggf. weitere Dokumente)">
          <button
            class="rounded-full bg-gray-200 w-5 h-5 text-xs"
            @click="openDialog('refCoreInfoDialog', medicationForm.value)"
          >
            FI
          </button>
        </component-tooltip>
      </div>
    </div>

    <!-- row 2 - gray text -->
    <div class="w-full flex col-span-12 mt-1" :class="{'ml-2': !isEditable}">
      <div
        class="data-cell-span text-gray-500 text-xs"
        :class="{'line-through': medicationForm.value.additionalinformation?.discontinued, 'ml-8': isEditable}"
        @click="openDetails"
      >
        <template
          v-if="medicationForm.value.core.substancesText && medicationForm.value.core.substancesText.length > 0"
        >
          {{ medicationForm.value.core.substancesText + " / " ?? "-" + " / " }}
        </template>

        <template v-if="medicationForm.value.form && medicationForm.value.form.length > 0">
          {{ medicationForm.value.form + " / " }}
        </template>

        <template v-if="medicationForm.value.selected_package_pzn && medicationForm.value.core.manufactureText">
          {{ medicationForm.value.core.manufactureText + " / " }}
        </template>

        <template v-if="medicationForm.value.core.selectedPackageLabel === 'außer Vertrieb'">
          {{ medicationForm.value.core.selectedPackageLabel }}
        </template>

        <template v-else>PZN: {{ medicationForm.value.core.selectedPackageLabel ?? "-" }}</template>

        <template v-if="medicationForm.value.core.package">{{ " / " + medicationForm.value.core.package }}</template>
      </div>
    </div>

    <!-- row 3 - col 1 / Art -->
    <div
      class="col-span-2"
      :class="[{'line-through pointer-events-none': isDiscontinued, 'ml-2': !isEditable, 'ml-8': isEditable}]"
    >
      <component-select
        :id="'usage' + medicationForm.value.id"
        ref="usage"
        :name="'usage' + medicationForm.value.id"
        :model-value="medicationForm.value.usage"
        :options="medicationUsage"
        :class="{'line-through': isDiscontinued}"
        :nullable="true"
        :disabled="!isEditable"
        placeholder-text=""
        @change="updateUsage"
      />
    </div>

    <!-- row 3 - col 2 / Dosierung -->
    <div class="col-span-2 xl:col-span-1" :class="[{'line-through pointer-events-none': isDiscontinued}]">
      <template v-if="medicationForm.value.dosage.dosagealternative === null">
        <div class="flex gap-1.5">
          <component-dosage-input
            :id="'dosageMorning' + medicationForm.value.id"
            :ref="'dosageMorning' + medicationForm.value.id"
            :name="'dosageMorning' + medicationForm.value.id"
            :value="medicationForm.value.dosage.morning"
            :as-fraction="medicationForm.value.dosage.morning_as_fraction"
            :disabled="!isEditable"
            @input="handleDosageChange($event, 'morning')"
            @is-invalid="handleInvalidDosage"
          />

          <component-dosage-input
            :id="'dosageNoon' + medicationForm.value.id"
            :ref="'dosageNoon' + medicationForm.value.id"
            :name="'dosageNoon' + medicationForm.value.id"
            :value="medicationForm.value.dosage.noon"
            :as-fraction="medicationForm.value.dosage.noon_as_fraction"
            :disabled="!isEditable"
            @input="handleDosageChange($event, 'noon')"
            @is-invalid="handleInvalidDosage"
          />

          <component-dosage-input
            :id="'dosageEvening' + medicationForm.value.id"
            :ref="'dosageEvening' + medicationForm.value.id"
            :name="'dosageEvening' + medicationForm.value.id"
            :value="medicationForm.value.dosage.evening"
            :as-fraction="medicationForm.value.dosage.evening_as_fraction"
            :disabled="!isEditable"
            @input="handleDosageChange($event, 'evening')"
            @is-invalid="handleInvalidDosage"
          />

          <component-dosage-input
            :id="'dosageNight' + medicationForm.value.id"
            :ref="'dosageNight' + medicationForm.value.id"
            :name="'dosageNight' + medicationForm.value.id"
            :value="medicationForm.value.dosage.night"
            :as-fraction="medicationForm.value.dosage.night_as_fraction"
            :disabled="!isEditable"
            @input="handleDosageChange($event, 'night')"
            @is-invalid="handleInvalidDosage"
          />
        </div>
      </template>

      <template v-else>
        <div class="relative">
          <div
            class="items-center h-9 p-2 pr-2 border-b-1 border-gray-300"
            :class="{
              'cursor-pointer': isEditable,
              'border-dashed cursor-default': !isEditable,
            }"
            @click="isEditable ? openDetails : () => {}"
          >
            <component-tooltip ref="refDosageAlternativeTooltip">
              <div class="text-sm truncate" :class="{'!text-gray-500': !isEditable}">Sonderdosierung</div>

              <template #tooltip>
                <p>
                  {{
                    medicationForm.value.dosage.dosagealternative.text ??
                    medicationForm.value.dosage.dosagealternative.additional
                  }}
                </p>
              </template>
            </component-tooltip>
          </div>

          <template v-if="!isDiscontinued">
            <div
              class="cursor-help absolute top-0.5 -right-0.5 inline-flex justify-center items-center rounded-full font-medium p-0 w-4 h-4 text-mcgreen text-lg"
              @mouseenter="dosageAlternativeBadgeMouseEnter"
              @mouseleave="dosageAlternativeBadgeMouseLeave"
            >
              <component-icon class="fill-current">info</component-icon>
            </div>
          </template>
        </div>
      </template>
    </div>

    <!-- row 3 - col 3 / Einheit -->
    <div class="col-span-2" :class="[{'line-through pointer-events-none': isDiscontinued}]">
      <component-tooltip
        placement="top"
        :tooltip="currentDosageUnit.unit"
        :force-hidden="currentDosageUnit.unit === null"
        custom-class-label="w-full"
      >
        <component-select
          :id="'dosageUnit' + medicationForm.value.id"
          ref="unitfield"
          :data-hash-id="medicationForm.value.id"
          :model-value="currentDosageUnit"
          :disabled="!isEditable"
          :options="currentDosageUnitList"
          :class="{'line-through': isDiscontinued}"
          key-value="keyDoe"
          key-name="unit"
          return-type="object"
          :placeholder-text="placeholderTextLabel"
          @change="updateDosageUnit"
        />
      </component-tooltip>
    </div>

    <!-- row 3 - col 4 / Mahlzeit -->
    <div class="col-span-2" :class="[{'line-through pointer-events-none': isDiscontinued}]">
      <component-tooltip
        placement="top"
        :tooltip="getMeal(medicationForm.value.dosage.meal)"
        :force-hidden="medicationForm.value.dosage.meal === null"
        custom-class-label="w-full"
      >
        <component-select
          :id="'meal' + medicationForm.value.id"
          ref="meal"
          :name="'meal' + medicationForm.value.id"
          :disabled="!isEditable"
          :model-value="medicationForm.value.dosage.meal"
          :options="medicationMeal"
          :class="{'line-through': isDiscontinued}"
          :nullable="true"
          placeholder-text=""
          @change="updateMeal"
        />
      </component-tooltip>
    </div>

    <!-- row 3 - col 5 / Grund/Ind. -->
    <div class="col-span-3 xl:col-span-4" :class="[{'line-through pointer-events-none': isDiscontinued}]">
      <medication-component-indication-display
        :indications="medicationForm.value.indications"
        :indications-unknown="medicationForm.value.indication_unknown"
        :is-active="isEditable"
        :is-discontinued="isDiscontinued"
        :disabled="!isEditable"
        @open-dialog="detailDialogIndications"
      />
    </div>

    <!-- row 3 - col 6 / Details -->
    <div class="flex col-span-1 h-9 items-center">
      <template v-if="isEditable">
        <medication-component-row-control
          :data-hash-id="medicationForm.value.id"
          :show-badge="hasAdditionalInformation"
          @click="openDetails"
        />

        <component-tooltip placement="top" tooltip="Zeile löschen">
          <component-icon-button
            class="text-white bg-gray-500 hover:bg-gray-400 border-gray-500 hover:border-gray-400"
            icon="delete"
            @click="showDeleteConfirmDialog"
          />
        </component-tooltip>
      </template>

      <template v-else>
        <div @click="openDetails">
          <component-tooltip
            :tooltip="hasAdditionalInformation ? 'Weitere Angaben sind hinterlegt' : 'Weitere / abweichende Angaben'"
          >
            <component-badge v-if="hasAdditionalInformation" icon="info" class="text-mcgreen">
              <component-icon-button class="p4umc-primary" icon="more_horiz" />
            </component-badge>

            <component-icon-button v-else class="p4umc-primary" icon="more_horiz" />
          </component-tooltip>
        </div>
      </template>
    </div>
  </li>
</template>

<script>
  import {computed, inject, onBeforeMount, ref, watch} from "vue";
  import {useForm, usePage} from "@inertiajs/vue3";
  import {cloneDeep, debounce} from "lodash";

  import ComponentBadge from "@components/Badges/Badge.vue";
  import ComponentDosageInput from "@components/Inputs/DosageInput.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentIconButton from "@components/Buttons/IconButton.vue";
  import ComponentSelect from "@components/Selects/Select.vue";
  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";

  import {checkAllInfos} from "@pages/Records/Components/Sections/MedicationBar/Utils/medicationConsistencyCheck.js";
  import {updateCompleteForm, updateForm} from "@pages/Records/Components/Sections/MedicationBar/Utils/formHandler.js";
  import {
    dosageUnits,
    medicationMeal,
    medicationUsage,
  } from "@pages/Records/Components/Sections/MedicationBar/enums.js";

  import MedicationComponentRowControl from "@pages/Records/Components/Sections/MedicationBar/Components/RowControl.vue";
  import MedicationComponentIndicationDisplay from "@pages/Records/Components/Sections/MedicationBar/Components/Indications/IndicationDisplay.vue";

  export default {
    name: "MedicationTableRow",

    components: {
      ComponentBadge,
      ComponentDosageInput,
      ComponentIcon,
      ComponentIconButton,
      ComponentSelect,
      ComponentTooltip,
      MedicationComponentIndicationDisplay,
      MedicationComponentRowControl,
    },

    props: {
      isEditable: {
        type: Boolean,
        required: true,
        default: false,
      },
      medication: {
        type: Object,
        required: true,
        default: () => {},
      },
      isDragging: {
        type: Boolean,
        required: true,
      },
    },

    setup(props) {
      const page = usePage();

      const openDialog = inject("medicationOpenDialog");
      const deleteDialog = inject("recordEditDeleteRowDialog");
      const recordEditHandleValidDosage = inject("recordEditHandleValidDosage");
      const recordEditHandleInvalidDosage = inject("recordEditHandleInvalidDosage");

      const refMedicationDeleteConfirmDialog = ref(null);

      const isLoading = ref(false);
      const showIndicationTooltip = ref(false);
      const showTooltip = ref(false);

      const refDosageAlternativeTooltip = ref(null);

      const medicationForm = useForm({
        value: null,
      });

      const hasAdditionalInformation = computed(() => {
        if (medicationForm.value) {
          return checkAllInfos(medicationForm.value);
        }

        return false;
      });

      const currentDosageUnit = computed(() => {
        return {
          unit: medicationForm.value.dosage.selected_unit,
          keyDoe: medicationForm.value.dosage.selected_unit_key_doe,
        };
      });

      const currentDosageUnitList = computed(() => {
        return typeof medicationForm.value.core.unitList !== "undefined" &&
          medicationForm.value.core.unitList.length > 0
          ? medicationForm.value.core.unitList
          : dosageUnits;
      });

      const placeholderTextLabel = computed(() => (props.isEditable ? "Bitte auswählen" : ""));

      const isDiscontinued = computed(() => !!medicationForm.value.additionalinformation?.discontinued);

      watch(
        () => props.medication,
        () => {
          medicationForm.value = cloneDeep(props.medication);
        },
        {deep: true},
      );

      onBeforeMount(() => {
        medicationForm.value = cloneDeep(props.medication);
      });

      const patchMedication = debounce(
        () => {
          medicationForm
            .transform((data) => {
              return data.value;
            })
            .put(
              route("medications.update", {
                patient: page.props.patient.id,
                record: page.props.record.id,
                medication: medicationForm.value.id,
              }),
              {
                preserveScroll: true,
                only: ["record", "flash"],
                onStart: () => {
                  isLoading.value = true;
                },
                onFinish: () => {
                  isLoading.value = false;
                },
              },
            );
        },
        3000,
        {trailing: true},
      );

      const updatePackageChange = (newPackage) => {
        medicationForm.value.selected_package = newPackage.value;
        medicationForm.value.selected_package_pzn = newPackage.pzn;
        patchMedication();
      };

      const updateUsage = (newUsage) => {
        medicationForm.value.usage = newUsage;
        patchMedication();
      };

      const updateDosageUnit = (newDosageKeyDoe) => {
        medicationForm.value.dosage.selected_unit_key_doe = newDosageKeyDoe.keyDoe;
        medicationForm.value.dosage.selected_unit = newDosageKeyDoe.unit;
        patchMedication();
      };

      const updateMeal = (newMeal) => {
        medicationForm.value.dosage.meal = newMeal;
        patchMedication();
      };

      const openDetails = () => {
        openDialog("refEditDetailDialog", {medication: medicationForm.value});
      };

      const getMeal = (meal) => {
        return medicationMeal[meal];
      };

      const handleDosageChange = (newValues, type) => {
        const asFraction = type + "_as_fraction";

        medicationForm.value.dosage[type] = newValues.value;
        medicationForm.value.dosage[asFraction] = newValues.as_fraction;

        recordEditHandleValidDosage(newValues.id);

        patchMedication();
      };

      const handleInvalidDosage = (dosageId) => {
        recordEditHandleInvalidDosage(dosageId, () => patchMedication.cancel());
      };

      const handleFormChange = ({key, newValue}) => {
        if (key) {
          updateForm(medicationForm.value, key, newValue);
        } else {
          updateCompleteForm(medicationForm, newValue);
        }
      };

      const showDeleteConfirmDialog = () => {
        deleteDialog({routeName: "medications", payload: {medication: medicationForm.value.id}});
      };

      const detailDialogIndications = () => {
        if (props.isEditable) {
          openDialog("refIndicationDialog", {
            medication: medicationForm.value,
            medicationKeyFam: medicationForm.value.abdata_key_fam,
            medicationHashId: medicationForm.value.hashId,
          });
        }
      };

      /**
       * The badge next to dosagealternative is not nested within the tooltip
       * but should trigger the tooltip of refDosageAlternativeTooltip too.
       * So these two methods pass through the mouse events.
       */
      const dosageAlternativeBadgeMouseEnter = () => {
        refDosageAlternativeTooltip.value.mouseEnter();
      };
      const dosageAlternativeBadgeMouseLeave = () => {
        refDosageAlternativeTooltip.value.mouseLeave();
      };

      return {
        /** inject */
        openDialog,

        /** enum */
        medicationMeal,
        medicationUsage,

        /** ref */
        refMedicationDeleteConfirmDialog,
        refDosageAlternativeTooltip,

        /** const */
        isLoading,
        showIndicationTooltip,
        medicationForm,
        hasAdditionalInformation,
        currentDosageUnit,
        currentDosageUnitList,
        placeholderTextLabel,
        isDiscontinued,
        patchMedication,
        showTooltip,

        /** function */
        updatePackageChange,
        updateUsage,
        updateDosageUnit,
        updateMeal,
        openDetails,
        getMeal,
        handleDosageChange,
        handleInvalidDosage,
        handleFormChange,
        showDeleteConfirmDialog,
        detailDialogIndications,
        dosageAlternativeBadgeMouseEnter,
        dosageAlternativeBadgeMouseLeave,
      };
    },
  };
</script>
