<template>
  <component-toast-message ref="componentToastMessage" class="print:hidden" />
  <layout-dialog-crypt v-if="hasClient" ref="crypt" />

  <InertiaHead :title="title" class="print:hidden" />
  <layout-auth-header v-if="isAuthenticated" class="print:hidden" />
  <layout-guest-header v-else class="print:hidden" />

  <main id="container" class="flex-grow flex flex-col pt-4 pb-8 px-2 md:px-4 print:p-0">
    <template v-if="isAuthenticated">
      <component-message display-mode="all_pages" />
      <slot />
    </template>

    <template v-else-if="!hasClient">
      <slot />
    </template>
  </main>

  <layout-footer class="print:hidden" />
</template>

<script>
  import {computed, inject, nextTick, onMounted, onUpdated, provide, ref} from "vue";
  import {Head as InertiaHead, router, usePage} from "@inertiajs/vue3";

  import ComponentMessage from "@components/Messages/Message.vue";
  import ComponentToastMessage from "@components/Messages/ToastMessage.vue";

  import LayoutFooter from "@components/Layout/Components/Footer.vue";
  import LayoutDialogCrypt from "@components/Layout/Components/CryptDialog.vue";
  import LayoutGuestHeader from "@components/Layout/Components/GuestHeader.vue";
  import LayoutAuthHeader from "@components/Layout/Components/AuthHeader/AuthHeader.vue";

  export default {
    name: "ComponentLayout",

    components: {
      ComponentToastMessage,
      ComponentMessage,
      LayoutAuthHeader,
      LayoutGuestHeader,
      InertiaHead,
      LayoutDialogCrypt,
      LayoutFooter,
    },

    props: {
      title: {
        type: String,
        default: null,
      },
    },

    setup() {
      const page = usePage();

      const privacy = inject("$privacy");
      const axios = inject("$axios");

      const componentToastMessage = ref(null);

      const hasClient = computed(() => !!page.props.client);
      const isAuthenticated = computed(() => {
        return hasClient.value && privacy.getCurrentUser() !== null && privacy.hasCryptStatus("VERIFICATION_VALID");
      });

      onMounted(() => {
        layoutMessages();

        setInterval(() => {
          authRefresh();
        }, 300000);
      });

      onUpdated(() => {
        layoutMessages();
      });

      const authRefresh = () => {
        if (page.props.client) {
          axios
            .post(route("api.auth.refresh"))
            .then((response) => {
              if (response.data.error && response.data.error.length > 0) {
                addToastMessage(response.data.error, "error");
                router.visit(route("login"));
              }
            })
            .catch(() => {
              addToastMessage("Etwas ist schiefgelaufen, bitte loggen Sie sich erneut ein.", "error");
              router.visit(route("login"));
            });
        }
      };

      const layoutMessages = () => {
        const flashKeys = ["success", "info", "warning", "error"];
        const timer = {
          success: 10000,
          info: 15000,
          warning: 20000,
          error: 60000,
        };

        for (const key of flashKeys) {
          if (page.props.flash[key]) {
            addToastMessage(page.props.flash[key], key, timer[key]);
            nextTick(() => {
              page.props.flash[key] = null;
            });
          }
        }
      };

      const addToastMessage = (message, type = "info", duration = 60000) => {
        if (componentToastMessage.value) {
          componentToastMessage.value.addToastMessage(message, type, duration);
        } else {
          console.log("ToastMessage ref not available to show this: " + message);
        }
      };

      provide("addToastMessage", addToastMessage);

      return {
        componentToastMessage,
        hasClient,
        isAuthenticated,
      };
    },
  };
</script>
