<template>
  <li
    class="px-6 py-4 cursor-pointer flex flex-row select-none gap-6 items-center"
    :class="{'bg-gray-50': isOpen}"
    @click="toggle"
  >
    <slot name="icon" />
    <button class="text-left" :class="{'font-bold': isOpen}">
      {{ title }}
      <slot name="title" />
    </button>
    <component-icon class="text-2xl ml-auto" :class="{'font-bold': isOpen}">
      {{ isOpen ? "expand_less" : "expand_more" }}
    </component-icon>
  </li>
  <li v-if="isOpen" class="px-6 py-4">
    <slot />
  </li>
</template>

<script>
  import ComponentIcon from "@components/Icons/Icon.vue";
  import {computed, inject, onMounted, ref, watch} from "vue";
  import ComponentBadge from "@components/Badges/Badge.vue";
  import ComponentCategoryBadge from "@components/Badges/CategoryBadge.vue";

  export default {
    name: "ComponentAccordionItem",

    components: {ComponentIcon},

    props: {
      title: {
        type: String,
        required: true,
      },
      initOpened: {
        type: Boolean,
        default: false,
      },
    },

    setup(props) {
      const accordionProvider = inject("accordionProvider");

      const index = computed(() => accordionProvider.items.findIndex((i) => i.props.title === props.title));
      const isOpen = computed(() => accordionProvider.selectedIndex === index.value);

      const open = () => (accordionProvider.selectedIndex = index.value);
      const close = () => (accordionProvider.selectedIndex = null);

      const toggle = () => {
        if (isOpen.value) {
          close();
        } else {
          open();
        }
      };

      return {isOpen, toggle};
    },
  };
</script>

<style scoped></style>
