/** todo atm no need? */

export default {
  id: null,
  additional: "",
  interval: null,
  text: null,
  type: null,
  dosagealternativedatas: null,
};
