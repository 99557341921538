<template>
  <aside
    ref="refPatientSidebar"
    class="px-4 relative bg-white shadow-md transition-all"
    :class="{'w-12 -ml-4 pt-12 pb-10': hidden, 'w-64 pt-4': !hidden}"
  >
    <component-icon-button
      class="p4umc-primary absolute top-2"
      :class="{'right-1.5': hidden, '-right-4': !hidden}"
      :icon="hidden ? 'arrow_forward' : 'arrow_back'"
      @click="hidden = !hidden"
    />

    <div v-if="hidden" class="absolute bottom-2 right-1.5">
      <component-tooltip tooltip="Patientennotiz öffnen" placement="left">
        <component-icon-button class="p4umc-primary p4umc-flat" icon="assignment" @click="openPatientNote()" />
      </component-tooltip>
    </div>

    <h1
      class="text-xl font-semibold"
      :class="{'w-full [writing-mode:vertical-lr] translate-x-2 rotate-180 whitespace-nowrap': hidden}"
    >
      <component
        :is="hidden ? 'button' : 'span'"
        class="hover:no-underline text-gray-900"
        @click="
          () => {
            if (hidden) hidden = false;
          }
        "
      >
        <span
          v-if="patient.salutation"
          class="font-normal text-base"
          v-text="patientSalutation[patient.salutation] || ''"
        />
        <span v-if="patient.title" class="font-normal text-base" v-text="patient.title" />
        <br v-if="!hidden && (patient.salutation || patient.title)" />
        <span :data-encrypted="patient.firstname" />
        <span :data-encrypted="patient.suffix" />
        <span :data-encrypted="patient.prefix" />
        <span :data-encrypted="patient.lastname" />
      </component>

      <br v-if="patient.is_fake && !hidden" />
      <component-tooltip tooltip="Alias" :force-hidden="hidden" placement="right">
        <span v-if="patient.is_fake && patient.aliasname" class="font-normal text-base">„{{ patient.aliasname }}“</span>
      </component-tooltip>
    </h1>

    <div v-if="!hidden">
      <div class="text-sm">
        <span v-if="patient.birthdate" class="after:content-[',_'] last:after:content-['']">
          <template v-if="patient.is_fake">
            <span data-format="age" :data-encrypted="patient.birthdate" />
          </template>
          <template v-else>
            <span data-format="date" :data-encrypted="patient.birthdate" />
            <span data-format="age-brackets" :data-encrypted="patient.birthdate" />
          </template>
        </span>

        <span
          v-if="patient.gender"
          class="after:content-[',_'] last:after:content-['']"
          v-text="patientGender[patient.gender] || ''"
        />

        <span
          v-if="patient.insurancenumber"
          class="after:content-[',_'] last:after:content-['']"
          :data-encrypted="patient.insurancenumber"
        />
      </div>

      <div v-if="patient.is_fake && patient?.shared_owners" class="mt-2 leading-tight mb-4 text-sm">
        <h2 class="text-gray-600">Patient wurde geteilt von</h2>
        <span class="font-semibold">{{ patient?.shared_owners[0]?.company }}</span>
        <span v-if="patient?.shared_owners[0]?.name" class="text-sm">({{ patient?.shared_owners[0]?.name }})</span>
      </div>

      <a
        class="text-sm mt-2 group/link hover:no-underline flex items-center ml-0.5 space-x-1.5"
        href="#"
        @click.prevent="openPatientNote()"
      >
        <component-icon
          class="text-gray-500 group-hover/link:text-gray-900 group-hover/link:no-underline"
          :clickable="true"
        >
          assignment
        </component-icon>
        <span class="leading-tight group-hover/link:underline">Patientennotiz öffnen</span>
      </a>

      <component
        :is="open ? 'inertia-link' : 'a'"
        class="text-sm mt-2 group/link hover:no-underline flex items-center ml-0.5 space-x-1.5"
        :href="$route('patients.edit', {id: patient.id})"
        :target="open ? '_self' : '_blank'"
      >
        <component-icon
          class="text-gray-500 group-hover/link:text-gray-900 group-hover/link:no-underline"
          :clickable="true"
        >
          edit
        </component-icon>
        <span class="group-hover/link:underline">Patienten bearbeiten</span>
        <component-icon
          v-if="!open"
          class="text-sm leading-tight text-gray-500 group-hover/link:text-gray-900 group-hover/link:no-underline"
          :clickable="true"
          title="Öffnet in neuem Tab"
        >
          launch
        </component-icon>
      </component>

      <component-foldable
        class="mt-4"
        title="Individuelle Besonderheiten"
        :badge-label="patient?.patient_attributes?.length || 0"
        :badge-color="patient?.patient_attributes?.length > 0 ? 'blue' : 'gray'"
      >
        <div v-if="patient?.patient_attributes?.length">
          <ul class="space-y-4">
            <li v-for="attribute in patient.patient_attributes" :key="attribute.id">
              <component :is="getComponentName(attribute.type)" :attribute="attribute" :patient="patient" />
            </li>
          </ul>
        </div>
        <div v-else class="text-sm text-gray-500">
          Erfassen Sie zusätzliche Angaben und medizinische Besonderheiten zu diesem Patienten.
        </div>
        <component
          :is="open ? 'inertia-link' : 'a'"
          class="text-sm mt-1 group/link hover:no-underline flex items-center ml-0.5 space-x-1.5"
          :href="$route('patients.edit', {id: patient.id}) + '#patient-attributes-data'"
          :target="open ? '_self' : '_blank'"
        >
          <component-icon
            class="text-gray-500 group-hover/link:text-gray-900 group-hover/link:no-underline"
            :clickable="true"
          >
            add
          </component-icon>
          <span class="group-hover/link:underline">Besonderheit hinzufügen</span>
          <component-icon
            v-if="!open"
            class="text-sm leading-tight text-gray-500 group-hover/link:text-gray-900 group-hover/link:no-underline"
            :clickable="true"
            title="Öffnet in neuem Tab"
          >
            launch
          </component-icon>
        </component>
      </component-foldable>

      <component-foldable
        v-if="!patient.is_fake"
        title="Kontakte"
        :badge-label="patient?.contacts?.length + (doctorContactViaTransfer ? 1 : 0) || 0"
        :badge-color="patient?.contacts?.length + (doctorContactViaTransfer ? 1 : 0) > 0 ? 'blue' : 'gray'"
      >
        <div v-if="patient?.contacts?.length || doctorContactViaTransfer">
          <ol class="space-y-4">
            <li v-if="doctorContactViaTransfer">
              <div class="px-2">
                <div class="flex items-center space-x-2">
                  <component-icon-button
                    class="p4umc-primary text-lg !p-0 font-normal"
                    icon="add"
                    @click="openPatientContactDialog(doctorContactViaTransfer)"
                  />
                  <h6 class="text-xs text-gray-600 uppercase">Kontakt aus WaWi</h6>
                  <component-info-icon icon-class="text-blue-600 text-base">
                    <template #content>
                      Der folgende Kontakt wurde aus der Warenwirtschaft übertragen und ist nur in dieser Analyse
                      verfügbar. Sie können den Kontakt übergreifend zu Ihrem Patienten speichern.
                    </template>
                  </component-info-icon>
                </div>
              </div>
              <component-contact-card :contact="doctorContactViaTransfer" :small="true" />
            </li>
            <li v-for="contact in patient.contacts" :key="contact.id">
              <component-contact-card :contact="contact" :small="true" />
            </li>
          </ol>
        </div>
        <div v-else class="text-sm text-gray-500">
          Verknüpfen Sie Kontakte (Ärzte / Pflegedienste / Heime) mit diesem Patienten.
        </div>
        <button
          class="text-sm mt-1 group/link hover:no-underline flex items-center ml-0.5 space-x-1.5"
          @click="openPatientContactDialog()"
        >
          <component-icon
            class="text-gray-500 group-hover/link:text-gray-900 group-hover/link:no-underline"
            :clickable="true"
          >
            add
          </component-icon>
          <span class="text-mcred group-hover/link:underline">Kontakt hinzufügen</span>
        </button>
      </component-foldable>

      <component-patient-sidebar-patientinput-foldable v-if="record" :patient="patient" :record="record" />
    </div>
  </aside>
  <component-patient-note-dialog ref="refPatientNote" />
  <component-patient-contact-dialog ref="refPatientContactDialog" :patient="patient" />
</template>
<script>
  import {ref, onUpdated, inject, onMounted, onBeforeUnmount, computed} from "vue";
  import {Link as InertiaLink, router} from "@inertiajs/vue3";

  import {patientGender, patientSalutation} from "@pages/Patients/Enums/Enums.js";
  import {doctorProfession} from "@pages/Contacts/Enums/Enums.js";

  import ComponentFoldable from "@components/Foldable/Foldable.vue";
  import ComponentIconButton from "@components/Buttons/IconButton.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentPatientNoteDialog from "@components/Dialogs/PatientNoteDialog.vue";
  import ComponentContactCard from "@components/Cards/ContactCard.vue";
  import ComponentConfirmationDialog from "@components/Dialogs/ConfirmationDialog.vue";
  import ComponentPatientSidebarPatientinputFoldable from "@components/Sidebars/PatientSidebar/PatientinputFoldable.vue";
  import ComponentPatientSidebarDegreeOfCareAttribute from "@components/Sidebars/PatientSidebar/DegreeOfCareAttribute.vue";
  import ComponentPatientSidebarMobilityAttribute from "@components/Sidebars/PatientSidebar/MobilityAttribute.vue";
  import ComponentPatientSidebarNursingHomeAttribute from "@components/Sidebars/PatientSidebar/NursingHomeAttribute.vue";
  import ComponentPatientSidebarPatientAddressAttribute from "@components/Sidebars/PatientSidebar/PatientAddressAttribute.vue";
  import ComponentPatientSidebarTubePatientAttribute from "@components/Sidebars/PatientSidebar/TubePatientAttribute.vue";
  import ComponentPatientSidebarSimpleAttribute from "@components/Sidebars/PatientSidebar/SimpleAttribute.vue";
  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";
  import ComponentInfoIcon from "@components/Icons/InfoIcon.vue";
  import ComponentPatientContactDialog from "@components/Dialogs/PatientContactDialog.vue";

  export default {
    name: "ComponentPatientSidebar",

    components: {
      ComponentPatientContactDialog,
      ComponentInfoIcon,
      ComponentTooltip,
      ComponentPatientSidebarPatientinputFoldable,
      ComponentConfirmationDialog,
      ComponentContactCard,
      ComponentPatientNoteDialog,
      ComponentIcon,
      ComponentFoldable,
      ComponentIconButton,
      InertiaLink,
      ComponentPatientSidebarDegreeOfCareAttribute,
      ComponentPatientSidebarMobilityAttribute,
      ComponentPatientSidebarNursingHomeAttribute,
      ComponentPatientSidebarPatientAddressAttribute,
      ComponentPatientSidebarTubePatientAttribute,
      ComponentPatientSidebarSimpleAttribute,
    },

    props: {
      patient: {
        type: Object,
        required: true,
      },
      record: {
        type: Object,
        default: null,
      },
      open: {
        type: Boolean,
        default: false,
      },
    },

    setup(props) {
      const privacy = inject("$privacy");
      const broadcast = inject("$broadcast");

      const hidden = ref(!props.open);
      const refPatientSidebar = ref(null);
      const refPatientNote = ref(null);

      const refPatientContactDialog = ref(null);

      onUpdated(() => {
        privacy.decryptStaticContent(refPatientSidebar.value);
      });

      function openPatientNote() {
        refPatientNote.value.open(props.patient);
      }

      function getComponentName(type) {
        switch (type) {
          case "degreeOfCare":
            return "component-patient-sidebar-degree-of-care-attribute";
          case "mobility":
            return "component-patient-sidebar-mobility-attribute";
          case "nursingHome":
            return "component-patient-sidebar-nursing-home-attribute";
          case "patientAddress":
            return props.patient.is_fake ? false : "component-patient-sidebar-patient-address-attribute";
          case "tubePatient":
            return "component-patient-sidebar-tube-patient-attribute";
          case "insulinPump":
          case "palliativePatient":
          case "port":
          case "stoma":
            return "component-patient-sidebar-simple-attribute";
        }
      }

      onMounted(() => {
        broadcast.patient.onMessage(props.patient.id, handleBroadcastMessage);
        addInteractionListener();
      });

      onBeforeUnmount(() => {
        broadcast.patient.offMessage(props.patient.id, handleBroadcastMessage);
      });

      function addInteractionListener() {
        refPatientSidebar.value.addEventListener("mouseenter", handleInteractWithSidebar);
      }

      function removeInteractionListener() {
        refPatientSidebar.value.removeEventListener("mouseenter", handleInteractWithSidebar);
      }

      const handleBroadcastMessage = (data) => {
        switch (data?.action) {
          case "updated":
            router.reload({
              only: ["patient"],
              onSuccess: () => {
                removeInteractionListener();
              },
            });

            break;
        }
      };

      const handleInteractWithSidebar = () => {
        router.reload({
          only: ["patient"],
          onSuccess: () => {
            removeInteractionListener();
          },
        });
      };

      const doctorContactViaTransfer = computed(() => {
        if (
          props.record &&
          props.record.transfer &&
          props.record.transfer.data &&
          props.record.transfer.data.doctor &&
          (props.record.transfer.data.doctor.first_name ||
            props.record.transfer.data.doctor.last_name ||
            props.record.transfer.data.doctor.company_name)
        ) {
          let contact = {
            // replace "Kardiologe" with "cardiologist" from enum
            profession:
              Object.keys(doctorProfession).find(
                (key) => doctorProfession[key] === props.record.transfer.data.doctor.job_title,
              ) || null,
            title: props.record.transfer.data.doctor.title ?? null,
            firstname: props.record.transfer.data.doctor.first_name ?? null,
            lastname: props.record.transfer.data.doctor.last_name ?? null,
            company: props.record.transfer.data.doctor.company_name ?? null,
            street: props.record.transfer.data.doctor.street_address ?? null,
            zip: props.record.transfer.data.doctor.postal_code ?? null,
            city: props.record.transfer.data.doctor.city ?? null,
            country: props.record.transfer.data.doctor.country ?? null,
            telephone: props.record.transfer.data.doctor.telephone ?? null,
            email: props.record.transfer.data.doctor.email ?? null,
          };

          if (props.patient?.contacts?.length === 0) {
            // patient does not have any contacts yet, so
            // immediately return contact from transfer
            return contact;
          }

          if (
            props.patient.contacts.some(
              (obj) =>
                obj.firstname === contact.firstname &&
                obj.lastname === contact.lastname &&
                obj.company === contact.company,
            )
          ) {
            // hide contact from transfer, if patient already contains #
            // a similar (likely the same) contact
            return null;
          }

          return contact;
        } else {
          return null;
        }
      });

      const openPatientContactDialog = (searchContact = null) => {
        refPatientContactDialog.value.open(searchContact);
      };

      return {
        hidden,
        refPatientSidebar,
        refPatientNote,
        openPatientNote,
        patientGender,
        patientSalutation,
        getComponentName,
        doctorContactViaTransfer,
        refPatientContactDialog,
        openPatientContactDialog,
      };
    },
  };
</script>
