<template>
  <div>
    <ul id="p4umc-medicheck-table" class="w-full text-sm text-left text-gray-700">
      <template v-if="isLoading">
        <labor-value-skeleton-row-main-values v-for="i in Array.from(new Array(5).keys())" :key="i" />
        <div class="w-2/12 h-[32px] skeleton-input-loader !mt-4" />
      </template>

      <template v-else>
        <!-- BMI row -->
        <main-labor-values-bmi-row :size="size" :weight="weight" :bmi="bmi" :is-editable="isEditable" />

        <!-- Bloodpressure row -->
        <labor-table-row :laborvalue="bloodpressure" :is-editable="isEditable" :dragable="false" :deletable="false" />

        <!-- Pulse row -->
        <labor-table-row :laborvalue="pulse" :is-editable="isEditable" :dragable="false" :deletable="false" />

        <!-- Creatinin row -->
        <labor-table-row :laborvalue="creatinine" :is-editable="isEditable" :dragable="false" :deletable="false" />

        <!-- GFR row -->
        <labor-table-row :laborvalue="gfr" :is-editable="isEditable" :dragable="false" :deletable="false" />
      </template>
    </ul>

    <!-- GFR Calculator -->
    <div class="inline-block">
      <component-tooltip
        placement="top"
        tooltip="GFR auf Kreatininbasis (mg/dl) nach Cockcroft-Gault, MDRD oder CKD-EPI berechnen"
      >
        <component-button
          icon-button
          label="(e)GFR BERECHNEN"
          :uppercase="false"
          class="p4umc-primary p4umc-flat"
          @click="openGfrDialog"
        >
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" class="fill-current" viewBox="0 0 100 100">
              <path
                d="M52.836 32.389c-.324-2.666-1.313-6.062-3.205-11.01a2.45 2.45 0 1 0-4.578 1.75c1.696 4.436 4.254 12.027 2.35 13.024-.078.042-.446.093-1.18-.166-3.558-1.252-9.075-7.11-11.135-11.829a2.451 2.451 0 0 0-4.492 1.963c2.336 5.346 8.66 12.612 14 14.49.977.343 1.833.474 2.574.474 1.083 0 1.918-.28 2.508-.59 2.587-1.354 3.65-4.081 3.16-8.106Z"
              />
              <path
                d="M86.588 51.506c-3.737-4.833-7.997-6.112-11.755-7.24-2.678-.804-5.233-1.574-7.72-3.581 4.305-5.786 7.268-11.286 8.812-16.371 1.736-5.725 1.62-10.676-.347-14.716-2.887-5.933-9.628-9.2-18.98-9.2-10.73 0-22.656 5.154-31.905 13.787-4.747 4.431-8.515 9.47-11.2 14.98-2.918 5.987-4.397 12.23-4.397 18.558 0 17.682 5.485 32.928 14.673 40.786 4.538 3.88 9.906 5.876 15.713 5.876 1.359 0 2.745-.109 4.149-.33 17.446-2.744 25.812-14.145 24.257-33.007.545-.009 1.098.014 1.656.071 1.38.141 2.282.667 2.924 1.709 2.207 3.583.627 11.818-.511 16.407-1.749 7.052-4.246 13.499-5.7 17.3l-.082.215a2.45 2.45 0 0 0 2.29 3.327c.986 0 1.916-.6 2.288-1.576l.082-.215c5.321-13.911 10.602-30.245 5.807-38.03-1.439-2.335-3.722-3.724-6.604-4.016-6.253-.633-12.024 1.748-17.156 7.084-4.326 4.498-7.635 10.41-10.293 15.162a2.453 2.453 0 0 0 4.28 2.394c2.52-4.505 5.657-10.112 9.548-14.156 2.144-2.228 4.342-3.791 6.631-4.704 1.162 15.975-5.451 24.88-20.177 27.194-6.017.945-11.37-.545-15.912-4.43-3.988-3.41-7.29-8.67-9.55-15.215-2.229-6.453-3.407-14.007-3.407-21.844 0-5.578 1.313-11.099 3.9-16.41 2.389-4.897 5.894-9.58 10.138-13.543C36.403 9.965 47.08 5.304 56.6 5.304s13.141 3.503 14.572 6.443c1.389 2.853 1.41 6.707.063 11.149-1.298 4.283-3.779 8.965-7.376 13.945-3.03-5.748-1.827-14.118-1.812-14.215a2.451 2.451 0 0 0-4.848-.736c-.084.553-1.99 13.63 4.846 20.78 3.86 4.039 7.856 5.238 11.378 6.296 3.495 1.05 6.512 1.957 9.287 5.544 5.915 7.648.22 21.221.162 21.354a2.45 2.45 0 1 0 4.505 1.936c.286-.663 6.899-16.353-.787-26.292Z"
              />
              <path
                d="m24.423 45.667-.082.007a2.45 2.45 0 0 0 .434 4.88l.082-.005c1.822-.162 4.088-.362 6.074-1.231 2.777-1.214 4.284-3.426 4.361-6.392.06-2.279-.85-4.25-2.63-5.696-1.283-1.043-2.972-1.797-5.163-2.3a2.451 2.451 0 0 0-1.096 4.779c4.046.93 4.006 2.5 3.99 3.09-.047 1.816-1.387 2.46-5.968 2.866Zm18.705 12.995c-3.104 2.527-7.62 5.695-12.85 9.362-.862.606-1.75 1.23-2.659 1.867a2.452 2.452 0 0 0 2.82 4.01c.906-.638 1.792-1.258 2.655-1.864 6.306-4.425 11.286-7.918 14.578-10.794 1.955-1.706 6.531-5.704 4.095-9.55-.543-.856-1.643-1.971-3.808-2.428-3.257-.686-7.524.377-10.53 1.39-4.028 1.358-8.234 3.439-11.844 5.86a2.452 2.452 0 0 0 2.73 4.072c3.853-2.584 8.583-4.785 12.654-5.887 4.05-1.096 5.959-.732 6.504-.48-.215.49-1.057 1.765-4.345 4.442Z"
              />
            </svg>
          </span>
        </component-button>
      </component-tooltip>
    </div>

    <div v-if="isEditable" class="w-full text-sm text-left text-gray-700 mt-4">
      <template v-if="!isLoading" />
    </div>
  </div>
</template>

<script>
  import {inject, onBeforeMount, ref, watch} from "vue";
  import {cloneDeep, find} from "lodash";

  import {
    bloodpressureKey,
    bmiKey,
    bodySizeKey,
    bodyWeightKey,
    creatinineKey,
    gfrKey,
    pulseKey,
  } from "@pages/Records/Components/Sections/LaborBar/enums.js";

  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";
  import ComponentButton from "@components/Buttons/Button.vue";

  import LaborTableRow from "@pages/Records/Components/Sections/LaborBar/Components/Row.vue";
  import MainLaborValuesBmiRow from "@pages/Records/Components/Sections/LaborBar/Components/BmiRow.vue";
  import LaborValueSkeletonRowMainValues from "@pages/Records/Components/Sections/LaborBar/Components/SkeletonRowMainValues.vue";

  export default {
    name: "MainLaborValues",

    components: {
      ComponentTooltip,
      ComponentButton,
      LaborValueSkeletonRowMainValues,
      LaborTableRow,
      MainLaborValuesBmiRow,
    },

    props: {
      isEditable: {
        type: Boolean,
        required: true,
      },
      recordData: {
        type: Object,
        required: true,
      },
      isLoading: {
        type: Boolean,
        required: true,
      },
    },

    setup(props) {
      const openDialog = inject("laborValueOpenDialog");

      const weight = ref(null);
      const size = ref(null);
      const bmi = ref(null);
      const bloodpressure = ref(null);
      const pulse = ref(null);
      const creatinine = ref(null);
      const gfr = ref(null);

      watch(
        () => [props.isLoading, props.recordData],
        () => {
          if (!props.isLoading) {
            setupValues();
          }
        },
        {deep: true},
      );

      onBeforeMount(() => {
        setupValues();
      });

      const openGfrDialog = () => {
        openDialog("refLaborValueGfrDialog", {
          gfr: gfr.value,
          size: size.value,
          weight: weight.value,
          bmi: bmi.value,
          creatinine: creatinine.value,
        });
      };

      const setupValues = () => {
        weight.value = _findLaborvalueByKey(bodyWeightKey);
        size.value = _findLaborvalueByKey(bodySizeKey);
        bmi.value = _findLaborvalueByKey(bmiKey);
        bloodpressure.value = _findLaborvalueByKey(bloodpressureKey);
        pulse.value = _findLaborvalueByKey(pulseKey);
        creatinine.value = _findLaborvalueByKey(creatinineKey);
        gfr.value = _findLaborvalueByKey(gfrKey);
      };

      const _findLaborvalueByKey = (key) => {
        return cloneDeep(find(props.recordData.laborvalues, (laborvalue) => laborvalue.mc_laborvalue_key === key));
      };

      return {
        /** const */
        weight,
        size,
        bmi,
        bloodpressure,
        pulse,
        creatinine,
        gfr,

        /** function */
        openGfrDialog,
      };
    },
  };
</script>
