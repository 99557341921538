<template>
  <li class="flex flex-row gap-5 justify-between items-center">
    <div>
      <div>{{ medication.Name }} / {{ medication.Packung }}</div>
      <p class="text-sm text-gray-500 leading-tight">{{ medication.Anbieter }} / PZN: {{ medication.PZN }}</p>
    </div>

    <component-icon-button
      icon="delete"
      class="text-white bg-gray-500 hover:bg-gray-400 border-gray-500 hover:border-gray-400"
      @click="$emit('delete', medication)"
    />
  </li>
</template>

<script>
  import ComponentIconButton from "@components/Buttons/IconButton.vue";

  export default {
    name: "PatientinputMedicationRow",

    components: {ComponentIconButton},

    props: {
      medication: {
        type: Object,
        required: true,
      },
      form: {
        type: Object,
        required: true,
      },
    },

    emits: ["delete"],
  };
</script>
