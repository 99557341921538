<template>
  <patientinput-component-step :forward-button="false" :back-button="false" :navigation-horizontal="false">
    <template #title>
      <img src="/resources/assets/images/mcp_logo_RGB_pos.svg" width="250" alt="MediCheck" class="mt-3" />
    </template>

    <p v-if="step === 'askBmp'">
      <span v-if="form.meta.is_patient">
        Haben Sie einen bundeseinheitlichen Medikationsplan (BMP, siehe Beispielfoto)?
      </span>
      <span v-else>
        Liegt ein aktueller bundeseinheitlicher Medikationsplan (BMP, siehe Beispielfoto) des Patienten vor?
      </span>
    </p>

    <p v-else>
      Scannen Sie nun den Code auf
      <span v-if="form.meta.is_patient">Ihrem</span>
      <span v-else>dem</span>
      BMP in der rechten, oberen Ecke mit Hilfe Ihrer Gerätekamera ein, indem Sie auf „Code einscannen“ klicken.
    </p>

    <img v-show="step === 'askBmp'" src="@images/example-bmp.jpg" alt="Beispiel-Medikationsplan" />
    <img v-show="step === 'scanBmp'" src="@images/example-bmp-marked-code.jpg" alt="Beispiel-Medikationsplan" />

    <template #navigation>
      <component-button v-if="step === 'askBmp'" class="p4umc-primary" @click="step = 'scanBmp'">Ja</component-button>
      <component-button v-else class="p4umc-primary" @click="$refs.bmpscanner.open()">Code einscannen</component-button>

      <component-button @click="$emit('navigate', '+')">
        <span v-if="step === 'askBmp'">Nein</span>
        <span v-else>Ohne Scan weiter</span>
      </component-button>
    </template>
  </patientinput-component-step>

  <patientinput-component-bmp-scanner-dialog ref="bmpscanner" :form="form" @ready="$emit('navigate', '+')" />
</template>

<script>
  import {usePage} from "@inertiajs/vue3";
  import {computed, ref} from "vue";

  import ComponentButton from "@components/Buttons/Button.vue";
  import PatientinputComponentStep from "@pages/Patientinput/Components/Step.vue";
  import PatientinputComponentBmpScannerDialog from "@pages/Patientinput/Components/Scanners/BmpScannerDialog.vue";

  export default {
    name: "PatientinputComponentStepWelcome",

    components: {
      PatientinputComponentBmpScannerDialog,
      ComponentButton,
      PatientinputComponentStep,
    },

    props: {
      form: {
        type: Object,
        required: true,
      },
    },

    emits: ["navigate"],

    setup() {
      const page = usePage();

      /** @type {import("vue").Ref<"askBmp"|"scanBmp">} */
      const step = ref("askBmp");

      const company = computed(() => page.props.company);

      return {company, step};
    },
  };
</script>
