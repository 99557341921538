<template>
  <component-dialog ref="refDialog" dialog-width="small" dialog-title="Interne Bezeichnung bearbeiten">
    <template #content>
      <form @submit.prevent="save">
        <component-input
          v-model="form.label"
          label="Interne Bezeichnung"
          maxlength="40"
          :hide-clear="true"
          helper-text="Die interne Bezeichnung hilft Ihnen dabei, sich bei mehreren Varianten zu orientieren."
          :validation="form.errors.label"
          autocomplete="off"
        />
      </form>
    </template>
    <template #actions>
      <component-button class="p4umc-primary" label="Speichern" :disabled="form.processing" @click="save()" />
      <component-button label="Abbrechen" @click="close()" />
    </template>
  </component-dialog>
</template>

<script>
  import {inject, ref} from "vue";
  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import {useForm} from "@inertiajs/vue3";
  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentInput from "@components/Inputs/Input.vue";

  export default {
    name: "ComponentRecordEditLabelDialog",
    components: {ComponentInput, ComponentButton, ComponentDialog},
    expose: ["open", "close"],

    setup(props) {
      const broadcast = inject("$broadcast");

      const refDialog = ref(null);

      const form = useForm({
        label: null,
      });

      const currentRecord = ref(props.record);

      function open(record) {
        if (typeof record !== "undefined") {
          currentRecord.value = record;
        }

        form.label = currentRecord.value.label;
        refDialog.value.open();
      }

      function close() {
        form.label = null;
        refDialog.value.close();
      }

      function save() {
        form.patch(route("records.update", {patient: currentRecord.value.patient_id, record: currentRecord.value.id}), {
          preserveScroll: true,
          headers: {
            // avoid updating the edited_at values when just changing the status
            "Mc-Silent-Update": true,
          },
          onSuccess: () => {
            // reload list of records
            broadcast.postMessage({action: "reload.records"});
            // reload specific record
            broadcast.record.postMessage(currentRecord.value.id, {action: "reload"});
            close();
          },
        });
      }

      return {refDialog, currentRecord, open, close, save, form};
    },
  };
</script>
