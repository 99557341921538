<template>
  <component
    :is="href && !isExternal ? 'inertia-link' : 'a'"
    v-bind="$attrs"
    :href="path"
    :target="isExternal ? '_blank' : ''"
    :class="{'text-gray-900 bg-gray-100': isActive, 'text-gray-500 hover:text-gray-900 hover:bg-gray-100': !isActive}"
    class="block group items-start p-2 rounded-lg focus:ring-4 focus:ring-opacity-50 focus:ring-red-300 !no-underline w-full"
  >
    <div class="flex space-x-2 items-top">
      <component-icon v-if="icon" class="text-xl p-1" :class="{'text-mcred': isActive}">{{ icon }}</component-icon>
      <div>
        <div class="flex space-x-1.5 items-center">
          <span class="font-semibold text-left">{{ label }}</span>
          <component-icon
            v-if="isExternal"
            class="text-sm leading-tight text-gray-500 group-hover:text-gray-900 grouplink:no-underline"
            :clickable="true"
            title="Öffnet in neuem Tab"
          >
            launch
          </component-icon>
        </div>

        <p v-if="description" class="mt-0.5 text-sm" v-text="description" />
      </div>
    </div>
  </component>
</template>

<script>
  import {computed} from "vue";
  import {Link as InertiaLink} from "@inertiajs/vue3";

  import ComponentIcon from "@components/Icons/Icon.vue";

  export default {
    name: "AuthHeaderDropdownItem",

    components: {ComponentIcon, InertiaLink},

    props: {
      label: {
        type: String,
        default: null,
      },
      description: {
        type: String,
        default: null,
      },
      icon: {
        type: String,
        default: null,
      },
      href: {
        type: String,
        default: null,
      },
      data: {
        type: Object,
        default: null,
      },
      canBeActive: {
        type: Boolean,
        default: true,
      },
      isExternal: {
        type: Boolean,
        default: false,
      },
    },

    setup(props) {
      const path = computed(() =>
        props.href && !props.isExternal && !props.href.startsWith("http") ? route(props.href, props.data) : props.href,
      );

      const isActive = computed(() => {
        if (props.href && props.canBeActive && !props.isExternal) {
          const modifiedHref = props.href.replace(/\..*$/, "");
          return !!route().current(props.href || modifiedHref || modifiedHref + ".*");
        } else {
          return false;
        }
      });

      return {isActive, path};
    },
  };
</script>
