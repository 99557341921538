<template>
  <div
    class="w-full max-w-96 mx-auto flex items-start space-x-2"
    :class="{'mt-4': !$attrs.class || $attrs.class.indexOf('mt-') === -1}"
  >
    <component-icon class="text-2xl text-blue-600">info</component-icon>
    <div class="text-blue-900">
      <slot />
    </div>
  </div>
</template>

<script>
  import ComponentIcon from "@components/Icons/Icon.vue";

  export default {
    name: "ComponentEmptyState",
    components: {ComponentIcon},
  };
</script>
