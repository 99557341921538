<template>
  <div class="p-2 bg-white shadow-md" :class="{'min-h-40': !small}">
    <div v-if="!small || contact.profession" class="text-sm text-gray-500">
      {{ doctorProfession[contact.profession] ?? "&nbsp;" }}
    </div>
    <h6 class="flex items-start justify-between">
      <span
        v-if="contact.firstname || contact.lastname"
        class="font-semibold text-gray-500"
        :class="{'text-xl': !small, 'text-lg': small}"
      >
        {{ contact.title }} {{ contact.firstname }} {{ contact.lastname }}
      </span>
      <span v-else class="font-semibold text-gray-500" :class="{'text-xl': !small, 'text-lg': small}">
        {{ contact.company }}
      </span>

      <component-category-badge v-if="contact.role" class="mt-1" color="red">
        {{ assignmentRole[contact.role] }}
      </component-category-badge>
    </h6>

    <address
      v-if="contact.company || contact.street || contact.zip || contact.city"
      class="mt-2 not-italic"
      :class="{'text-sm': small}"
    >
      <!-- without firstname and lastname the company is already shown above! -->
      <div v-if="(contact.firstname || contact.lastname) && contact.company">{{ contact.company }}</div>
      <div v-if="contact.street">{{ contact.street }}</div>
      <div v-if="contact.zip || contact.city">{{ contact.zip }} {{ contact.city }}</div>
    </address>

    <div v-if="contact.email || contact.telephone || contact.fax" class="mt-2" :class="{'text-sm': small}">
      <a
        v-if="contact.email"
        :href="`mailto:${contact.email}`"
        class="group/contact-card hover:no-underline flex items-center space-x-2"
      >
        <component-icon class="text-gray-500 group-hover/contact-card:text-gray-900 group-hover:no-underline">
          email
        </component-icon>
        <span class="group-hover/contact-card:underline">{{ contact.email }}</span>
      </a>

      <a
        v-if="contact.telephone"
        :href="`tel:${contact.telephone}`"
        class="group/contact-card hover:no-underline flex items-center space-x-2"
      >
        <component-icon class="text-gray-500 group-hover/contact-card:text-gray-900 group-hover:no-underline">
          phone
        </component-icon>
        <span class="group-hover/contact-card:underline">{{ contact.telephone }}</span>
      </a>

      <div v-if="contact.fax" class="flex items-center space-x-2">
        <component-icon class="text-gray-500">print</component-icon>
        <span class="">{{ contact.fax }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import {Link as InertiaLink} from "@inertiajs/vue3";

  import {doctorProfession, assignmentRole} from "@pages/Contacts/Enums/Enums.js";

  import ComponentCategoryBadge from "@components/Badges/CategoryBadge.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";

  export default {
    name: "ComponentContactCard",

    components: {ComponentIcon, ComponentCategoryBadge, InertiaLink},

    props: {
      contact: {
        type: Object,
        required: true,
      },
      small: {
        type: Boolean,
        default: false,
      },
    },

    setup() {
      return {doctorProfession, assignmentRole};
    },
  };
</script>
