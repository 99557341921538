<template>
  <tr v-if="transfer.data.masterdata" class="hover:bg-gray-100 border-t-2 border-gray-200 leading-tight">
    <td class="px-0.5 py-2">
      <div class="flex flex-row items-center gap-3">
        <component-tooltip tooltip="Importieren" force-inline>
          <span class="text-gray-700 font-semibold">
            {{ transfer.data.masterdata.patient.lastname }},
            {{ transfer.data.masterdata.patient.firstname }}
          </span>
        </component-tooltip>
      </div>

      <div>
        <component-tooltip tooltip="Geburtsdatum" force-inline>
          <!-- prettier-ignore -->
          <span class="text-xs">
            {{
              new Date(transfer.data.masterdata.patient.birthdate).toLocaleDateString("de-DE", {dateStyle: "medium"})
            }}<span v-if="transfer.data.masterdata.patient.insurancenumber">,</span>
          </span>
        </component-tooltip>
        <component-tooltip
          v-if="transfer.data.masterdata.patient.insurancenumber"
          tooltip="Versichertennummer"
          force-inline
        >
          <span class="text-xs">
            {{ transfer.data.masterdata.patient.insurancenumber }}
          </span>
        </component-tooltip>
      </div>
    </td>

    <td class="px-0.5 py-2">
      <component-status-badge color="gray" :show-dot="false">
        <template v-if="transfer.meta.is_carer">Pflegecheckliste</template>
        <template v-else>Patientenfragebogen</template>
      </component-status-badge>
    </td>

    <td class="px-0.5 py-2">
      <component-status-badge v-if="transfer.status === 'pending'" color="info">
        Noch nicht importiert
      </component-status-badge>

      <component-status-badge v-else-if="transfer.status === 'processed'" color="yellow">
        Medikationsanalyse in Arbeit
      </component-status-badge>

      <component-status-badge v-else-if="transfer.status === 'completed'" color="green">
        Medikationsanalyse abgeschlossen
      </component-status-badge>

      <component-status-badge v-else-if="transfer.status === 'rejected'" color="red">
        Medikationsanalyse gelöscht
      </component-status-badge>
    </td>

    <td class="px-0.5 py-2">
      <component-tooltip tooltip="Eingesendet" force-inline>
        {{ transfer.created_at }}
      </component-tooltip>
    </td>

    <td class="px-0.5 py-2">
      <div class="flex justify-end gap-3">
        <component-button
          v-if="transfer.status === 'pending'"
          class="p4umc-primary"
          @click="$emit('startImport', transfer)"
        >
          Importieren
        </component-button>

        <inertia-link
          v-else-if="['processed', 'completed'].includes(transfer.status)"
          :href="
            $route('records.edit', {
              patient: transfer.meta.patient_id,
              record: transfer.meta.record_id,
            })
          "
        >
          <component-button class="p4umc-primary">Öffnen</component-button>
        </inertia-link>

        <component-button
          v-else-if="transfer.status === 'rejected'"
          class="p4umc-primary"
          @click="$emit('startImport', transfer)"
        >
          Erneut importieren
        </component-button>

        <component-icon-button icon="delete" title="Löschen" @click="$emit('delete', transfer)" />
      </div>
    </td>
  </tr>
</template>

<script>
  import {Link as InertiaLink} from "@inertiajs/vue3";

  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentStatusBadge from "@components/Badges/StatusBadge.vue";
  import ComponentIconButton from "@components/Buttons/IconButton.vue";

  export default {
    name: "PatientinputClientComponentDataSectionTableRow",

    components: {
      InertiaLink,
      ComponentIconButton,
      ComponentStatusBadge,
      ComponentButton,
      ComponentTooltip,
    },

    props: {
      transfer: {
        type: Object,
        required: true,
      },
    },

    emits: ["startImport", "delete"],
  };
</script>
