<template>
  <component-dialog ref="bmpImportDialog" :dialog-title="title" dialog-width="large">
    <template #content>
      <div v-if="status === 'tabs'">
        <component-tabs :active-tab="activeTab" @click="tabChanged">
          <component-tab v-slot="{isActive}" title="Barcodescanner">
            <component-bmp-import-dialog-barcode-scanner-tab v-if="isActive" :error="error" @input="processXml" />
          </component-tab>

          <component-tab v-slot="{isActive}" title="Gerätekamera-Scan">
            <component-bmp-import-dialog-device-camera-scan-tab
              v-if="isActive"
              :error="error"
              @error="setError"
              @input="processXml"
            />
          </component-tab>

          <component-tab v-slot="{isActive}" title="Hochladen">
            <component-bmp-import-dialog-upload-tab
              v-if="isActive"
              :error="error"
              @error="setError"
              @input="processXml"
            />
          </component-tab>
        </component-tabs>
      </div>

      <div v-else-if="status === 'selectGender'">
        <component-alert type="warning">
          Im BMP ist kein Geschlecht hinterlegt. Dieses ist für den MediCheck allerdings notwendig.
        </component-alert>

        <component-select v-model="genderModel" label="Bitte wählen Sie ein Geschlecht aus:" :options="patientGender" />
      </div>

      <component-existing-patient-select
        v-else-if="status === 'existingPatients'"
        v-slot="{hasExact}"
        :current-patient="patient"
        :existing-patients="existingPatients"
        @existing-patient-selected="existingPatientSelected"
      >
        <component-alert type="warning">
          <span v-if="hasExact">Es wurden Patienten mit den gleichen Daten gefunden.</span>
          <span v-else>Es wurden Patienten mit ähnlichen Daten gefunden.</span>

          <span>
            Bitte wählen Sie einen Patienten aus, bei dem Sie mit den Daten aus dem BMP eine neue Analyse hinzufügen
            möchten. Alternativ können Sie den BMP als einen neuen Patienten importieren.
          </span>
        </component-alert>
      </component-existing-patient-select>

      <component-existing-patient-select
        v-else-if="status === 'patientsNotMatch'"
        v-slot="{has}"
        :current-patient="patient"
        :existing-patients="existingPatients"
        :show-table="showExistingPatients"
        @existing-patient-selected="existingPatientSelected"
      >
        <component-alert type="error">
          <p>
            Die Daten aus dem BMP stimmen nicht mit dem ausgewählten Patienten überein.
            <span v-if="has && showExistingPatients">
              Wählen Sie einen anderen Patienten aus oder importieren Sie den BMP zu diesem Patienten.
            </span>
            <span v-else-if="has">
              Möchten Sie den BMP weiterhin zu diesem Patienten importieren oder nach anderen Patienten suchen?
            </span>
            <span v-else>Möchten Sie den BMP weiterhin zu diesem Patienten importieren?</span>
          </p>

          <table v-if="!showExistingPatients" class="mt-3 table-auto">
            <thead>
              <tr>
                <th />
                <th class="pr-14">Ausgewählter Patient</th>
                <th>Patient von BMP</th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(label, key, index) in {
                  firstname: 'Vorname',
                  lastname: 'Nachname',
                  gender: 'Geschlecht',
                  birthdate: 'Geburtsdatum',
                  insurancenumber: 'Versichertennummer',
                }"
                :key="index"
                class="hover:bg-mcred-200"
              >
                <th class="pl-0.5 pr-14 py-2">{{ label }}</th>

                <template v-if="key === 'gender'">
                  <td class="pl-0.5 pr-14 py-2">{{ genderToHuman(props.patient[key]) }}</td>
                  <td class="px-0.5 py-2">{{ genderToHuman(patient[key]) }}</td>
                </template>

                <template v-else-if="key === 'birthdate'">
                  <td class="pl-0.5 pr-14 py-2">{{ dateToHuman(props.patient[key]) }}</td>
                  <td class="px-0.5 py-2">{{ dateToHuman(patient[key]) }}</td>
                </template>

                <template v-else>
                  <td class="pl-0.5 pr-14 py-2">{{ props.patient[key] }}</td>
                  <td class="px-0.5 py-2">{{ patient[key] }}</td>
                </template>
              </tr>
            </tbody>
          </table>
        </component-alert>
      </component-existing-patient-select>

      <div v-else class="h-40 flex justify-center items-center">
        <component-spinner class="w-10 h-10" />
      </div>
    </template>

    <template #actions>
      <component-button v-if="status === 'selectGender'" class="p4umc-primary" @click="genderSelected">
        Weiter
      </component-button>

      <component-button v-else-if="status === 'existingPatients'" class="p4umc-primary" @click="saveAndOpenRecord">
        Als neuen Patienten importieren
      </component-button>

      <template v-else-if="status === 'patientsNotMatch'">
        <component-button class="p4umc-primary" @click="performImportToCurrentPatient">
          Zu bestehendem Patienten importieren
        </component-button>

        <component-button
          v-if="existingPatients.length > 0 && !showExistingPatients"
          class="p4umc-primary"
          @click="showExistingPatients = true"
        >
          Nach anderen Patienten suchen
        </component-button>
      </template>

      <component-button @click="close">Abbrechen</component-button>
    </template>
  </component-dialog>
</template>

<script>
  import {inject, onBeforeUnmount, reactive, ref, toRefs} from "vue";
  import {cloneDeep} from "lodash";
  import {router} from "@inertiajs/vue3";

  import Bmp from "@utils/Bmp.js";
  import {pdfRouter} from "@utils/pdfRouter/pdfRouter.js";
  import {patientGender} from "@pages/Patients/Enums/Enums.js";
  import filterExistingPatients from "@utils/Helpers/FilterExistingPatients.js";
  import resetState from "@utils/Helpers/ResetState.js";

  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentTabs from "@components/Tab/Tabs.vue";
  import ComponentTab from "@components/Tab/Tab.vue";
  import ComponentAlert from "@components/Alerts/Alert.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentExistingPatientSelect from "@components/ExistingPatientSelect/ExistingPatientSelect.vue";
  import ComponentSpinner from "@components/Spinner.vue";
  import ComponentSelect from "@components/Selects/Select.vue";
  import ComponentBmpImportDialogDeviceCameraScanTab from "@components/Dialogs/BmpImportDialog/Tabs/DeviceCameraScanTab.vue";
  import ComponentBmpImportDialogBarcodeScannerTab from "@components/Dialogs/BmpImportDialog/Tabs/BarcodeScannerTab.vue";
  import ComponentBmpImportDialogUploadTab from "@components/Dialogs/BmpImportDialog/Tabs/UploadTab.vue";

  export default {
    name: "ComponentBmpImportDialog",

    components: {
      ComponentBmpImportDialogUploadTab,
      ComponentBmpImportDialogBarcodeScannerTab,
      ComponentBmpImportDialogDeviceCameraScanTab,
      ComponentSelect,
      ComponentSpinner,
      ComponentExistingPatientSelect,
      ComponentButton,
      ComponentAlert,
      ComponentTab,
      ComponentTabs,
      ComponentDialog,
    },

    props: {
      title: {
        type: String,
        default: "Neue Variante mit Bundeseinheitlichen Medikationsplan (BMP) erstellen",
      },
    },

    setup() {
      const privacy = inject("$privacy");
      const axios = inject("$axios");

      /*
      |--------------------------------------------------------------------------
      | Component references
      |--------------------------------------------------------------------------
      */

      const /** @type {import("vue").Ref<{open: VoidFunction, close: VoidFunction}>} */ bmpImportDialog = ref(null);

      /*
      |--------------------------------------------------------------------------
      | Variables
      |--------------------------------------------------------------------------
      */

      /** @typedef {{id, firstname, lastname, gender, birthdate, insurancenumber}} Patient */

      const initialState = {
        /** @type {Boolean} */
        showExistingPatients: false,

        /** @type {Patient[]} */
        existingPatients: [],

        /** @type {("tabs"|"selectGender"|"loadingDecode"|"loadingExistingPatient"|"existingPatients"|"patientsNotMatch"|"loadingForm")} */
        status: "tabs",

        /** @type {?String} */
        activeTab: "",

        /** @type {{type: ?("error"|"warning"), text: ?String}} */
        error: {type: null, text: null},

        /** @type {?String} */
        genderModel: null,

        /** @type {Patient} */
        patient: {},

        /** @type {{patient: ?Patient}} */
        props: {patient: null},

        /** @type {?String} */
        uniqueInstanceId: null,

        /** @type {{totalPages: Number, numbers: Number[], xml: String[]}} */
        pagesScanned: {totalPages: 0, numbers: [], xml: []},

        /** @type {?{patient: Object, record: Object, failed: Number[]}} */
        parsedXml: null,

        /** @type {Boolean} */
        importToCurrentPatient: false,
      };

      const state = reactive({...initialState});

      /*
      |--------------------------------------------------------------------------
      | Methods
      |--------------------------------------------------------------------------
      */

      /** @param {["activeTab"|"props"|String]} not */
      const reset = (not = []) => {
        Object.entries(initialState).forEach(([key, value]) => {
          if (!not.includes(key)) {
            state[key] = resetState(value);
          }
        });
      };

      const open = (patient = null) => {
        reset();

        state.props.patient = patient;

        bmpImportDialog.value.open();
      };

      const close = () => {
        bmpImportDialog.value?.close();
        reset();
      };

      const tabChanged = (newActiveTab) => {
        if (state.activeTab !== newActiveTab) {
          reset(["activeTab", "props"]);
          state.activeTab = newActiveTab;
        }
      };

      const existingPatientSelected = (selectedPatient) => {
        if (state.props.patient) {
          state.props.patient = selectedPatient;

          if (state.pagesScanned.totalPages < state.pagesScanned.numbers.length) {
            state.status = "tabs";
          } else {
            const xml = cloneDeep(state.pagesScanned.xml[0]);
            reset(["activeTab", "props"]);
            state.importToCurrentPatient = true;
            processXml(xml);
          }

          return;
        }

        saveAndOpenRecord({patient: selectedPatient.id});
      };

      const performImportToCurrentPatient = () => {
        const xml = cloneDeep(state.pagesScanned.xml[0]);
        reset(["activeTab", "props"]);
        state.importToCurrentPatient = true;
        processXml(xml);
      };

      const setError = (type, text) => {
        state.error.type = type;
        state.error.text = text;
      };

      const clearError = () => {
        state.error.type = null;
        state.error.text = null;
      };

      const genderSelected = () => {
        const xml = cloneDeep(state.pagesScanned.xml[0]);
        state.pagesScanned.numbers = [];
        state.pagesScanned.xml = [];
        processXml(xml);
      };

      /*
      |--------------------------------------------------------------------------
      | XML Processors
      |--------------------------------------------------------------------------
      */

      /**
       *
       * @param {String} xml
       */
      const processXml = async (xml) => {
        xml = xml.trim();

        // some scanners prepend a '#', which we have to remove
        // (yes, this is the "copy & paste" case, but it
        // should behave in the way as a scan would)
        if (xml.charAt(0) === "#") {
          xml = xml.slice(1);
        }

        if (xml.length === 0) {
          return;
        }

        // From old Frontend
        if (xml.match(/\/MP>/gm)?.length > 1) {
          const xmlSplit = xml.split("</MP>");

          for (let i = 0; i < xmlSplit.length; i++) {
            const xmlPart = xmlSplit[i].trim();
            if (xmlPart.length > 0) {
              await processXml(xmlPart + "</MP>");
              if (state.error.type === "error") break;
            }
          }

          return;
        }

        let currentPageNumber, scannedUniqueInstanceId;

        try {
          if (!Bmp.validateXml(xml)) throw Error();

          currentPageNumber = Bmp.getCurrentPageNumber(xml);
          state.pagesScanned.totalPages = Bmp.getTotalPageNumber(xml);
          scannedUniqueInstanceId = Bmp.getUniqueInstanceId(xml);

          state.patient = {
            firstname: Bmp.getPatientFirstname(xml),
            lastname: Bmp.getPatientLastname(xml),
            gender: Bmp.getPatientGender(xml) ?? state.genderModel,
            birthdate: Bmp.getPatientBirthdate(xml),
            insurancenumber: Bmp.getPatientInsurancenumber(xml),
          };
        } catch (e) {
          return setError("error", "Der erfasste BMP ist ungültig.");
        }

        clearError();

        if (state.uniqueInstanceId && state.uniqueInstanceId !== scannedUniqueInstanceId) {
          setError("error", "Bitte erfassen Sie nur Seiten des gleichen Medikationsplans.");
        } else if (state.pagesScanned.numbers.find((pN) => pN === currentPageNumber)) {
          setError("error", "Sie haben diese Seite bereits erfasst.");
        } else if (state.pagesScanned.numbers.length <= state.pagesScanned.totalPages) {
          state.pagesScanned.numbers.push(currentPageNumber);
          state.pagesScanned.xml.push(xml);
          state.uniqueInstanceId = scannedUniqueInstanceId;

          if (!state.patient.gender) {
            state.status = "selectGender";
          } else if (
            !state.importToCurrentPatient &&
            state.props.patient &&
            (state.props.patient.firstname !== state.patient.firstname ||
              state.props.patient.lastname !== state.patient.lastname ||
              state.props.patient.gender !== state.patient.gender ||
              state.props.patient.birthdate !== state.patient.birthdate ||
              state.props.patient.insurancenumber !== state.patient.insurancenumber)
          ) {
            state.status = "loadingExistingPatient";
            await searchExistingPatients();
            state.status = "patientsNotMatch";
          } else if (state.pagesScanned.numbers.length < state.pagesScanned.totalPages) {
            let nextPage = 0;

            if (!state.pagesScanned.numbers.find((pN) => pN === 1)) {
              nextPage = 1;
            } else if (!state.pagesScanned.numbers.find((pN) => pN === 2)) {
              nextPage = 2;
            } else if (!state.pagesScanned.numbers.find((pN) => pN === 3)) {
              nextPage = 3;
            }

            setError("warning", `Bitte erfassen Sie nun Seite ${nextPage} von ${state.pagesScanned.totalPages}.`);
          } else if (state.pagesScanned.numbers.length === state.pagesScanned.totalPages) {
            state.status = "loadingDecode";
            state.parsedXml = await parseXml(state.pagesScanned.xml);
            state.status = "loadingExistingPatient";

            if (!state.importToCurrentPatient) {
              await searchExistingPatients();
            } else {
              state.existingPatients = [];
            }

            if (state.existingPatients.length > 0) {
              state.status = "existingPatients";
            } else if (state.props.patient) {
              saveAndOpenRecord({patient: state.props.patient.id});
            } else {
              saveAndOpenRecord();
            }
          }
        } else {
          setError(
            "error",
            "Es ist ein unbekannter Fehler aufgetreten. Bitte schließen Sie den Dialog und versuchen es erneut.",
          );
        }
      };

      const searchExistingPatients = async () => {
        const search = async () => {
          state.existingPatients = filterExistingPatients(
            state.patient,
            await Promise.all(
              (
                await axios.post(route("api.patients.search"), await privacy.encryptPatient(state.patient))
              ).data.data.map(async (patient) => await privacy.decryptPatient(patient)),
            ),
            ["birthdate"],
          );
        };

        try {
          await search();
        } catch (e) {
          // If privacy password is expired
          await new Promise((resolve) => {
            const interval = setInterval(async () => {
              if (privacy.hasValidLocalStorage()) {
                await search();
                clearInterval(interval);
                resolve();
              }
            }, 1000);
          });
        }
      };

      const parseXml = (pages) => {
        return new Promise((resolve, reject) => {
          pdfRouter.decodeXml(
            {pages: pages},
            {
              onSuccess: (response) => resolve(response.data),
              onError: (error) => reject(error),
            },
          );
        });
      };

      const saveAndOpenRecord = async (routeParams = {}) => {
        state.status = "loadingForm";

        if (state.genderModel && (!state.parsedXml.patient.gender || state.parsedXml.patient.gender.length === 0)) {
          state.parsedXml.patient.gender = state.genderModel;
        }

        state.parsedXml.patient = await privacy.encryptPatient(state.parsedXml.patient);

        router.post(route("init-from-json", {origin: "bmp", ...routeParams}), state.parsedXml, {
          preserveState: true,
          preserveScroll: true,
          onSuccess: () => close(),
          onError: () => {
            reset(["activeTab", "props"]);
            setError("error", "Beim Importieren ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.");
            state.status = "tabs";
          },
        });
      };

      const dateToHuman = (value) => {
        let d = new Date(value);
        return isNaN(d.getTime()) ? "" : d.toLocaleDateString("de-DE", {dateStyle: "medium"});
      };

      const genderToHuman = (value) => {
        switch (value) {
          case "male":
            return "männlich";
          case "female":
            return "weiblich";
          case "diverse":
            return "divers";
          default:
            return null;
        }
      };

      /*
      |--------------------------------------------------------------------------
      | Lifecycle Hooks
      |--------------------------------------------------------------------------
      */

      onBeforeUnmount(close);

      /*
      |--------------------------------------------------------------------------
      | Return for template and external components
      |--------------------------------------------------------------------------
      */

      return {
        bmpImportDialog,
        ...toRefs(state),
        open,
        close,
        tabChanged,
        processXml,
        setError,
        genderSelected,
        existingPatientSelected,
        performImportToCurrentPatient,
        saveAndOpenRecord,
        dateToHuman,
        genderToHuman,
        patientGender,
      };
    },
  };
</script>

<style scoped></style>
