<template>
  <dashboard-widget>
    <template #default>
      <inertia-link :href="$route('patients.index')" class="group hover:no-underline flex items-baseline space-x-3">
        <component-icon class="p-2 text-2xl bg-mcred group-hover:bg-mcred-450 rounded-full text-white font-normal">
          view_list
        </component-icon>
        <span class="text-gray-700 group-hover:text-gray-900 text-4xl font-semibold">{{ patientCount }}</span>
        <span class="text-gray-700 group-hover:text-gray-900 text-2xl font-semibold leading-tight">Patienten</span>
      </inertia-link>
    </template>
    <template #actions>
      <component-link-button class="p4umc-flat" label="alle Patienten" :href="$route('patients.index')" />
    </template>
  </dashboard-widget>
</template>

<script>
  import ComponentIcon from "../../../Components/Icons/Icon.vue";
  import DashboardWidget from "./Widget.vue";
  import ComponentLinkButton from "../../../Components/Buttons/LinkButton.vue";
  import {Link as InertiaLink} from "@inertiajs/vue3";

  export default {
    name: "DashboardPatientCountWidget",
    components: {ComponentLinkButton, DashboardWidget, ComponentIcon, InertiaLink},
    props: {
      patientCount: {
        type: Number,
        required: true,
      },
    },
  };
</script>
