<template>
  <component
    :is="href ? 'inertia-link' : 'button'"
    :href="href"
    class="h-16 border-l-2 md:border-l-0 md:border-b-2 hover:border-gray-300 hover:!text-gray-900 inline-flex items-center px-1 pt-1 font-semibold focus:border-red focus:text-red !no-underline !text-gray-500"
    :class="{'border-mcred': isActive, 'border-transparent': !isActive}"
  >
    {{ label }}
    <component-icon v-if="icon" class="p-1">{{ icon }}</component-icon>
  </component>
</template>

<script>
  import {computed} from "vue";
  import {Link as InertiaLink, usePage} from "@inertiajs/vue3";

  import ComponentIcon from "@components/Icons/Icon.vue";

  export default {
    name: "AuthHeaderNavItem",

    components: {ComponentIcon, InertiaLink},

    props: {
      label: {
        type: String,
        default: null,
      },
      icon: {
        type: String,
        default: null,
      },
      href: {
        type: String,
        default: null,
      },
    },
    setup(props) {
      const page = usePage();

      const isActive = computed(() => {
        if (props.href) {
          return props.href.includes(page.url.split("?")[0]);
        } else {
          return false;
        }
      });

      return {isActive};
    },
  };
</script>
