<template>
  <li class="grid grid-cols-12 gap-x-6 py-2">
    <!-- col 1 -  Erkrankung / ICD-10 / Allergie / Lebensumstand -->
    <div class="col-span-8 ml-2 items-center w-full h-9">
      <template v-if="isEditable">
        <div class="flex">
          <template v-if="diseaseForm.value.disease">
            <div class="more icon-wrap flex items-center justify-center">
              <component-tooltip
                placement="right-end"
                tooltip="Zeile verschieben"
                custom-class-label="flex items-center"
                :force-hidden="isDragging"
              >
                <component-icon class="text-gray-900 text-xl mt-0.5" drag-handle>drag_handle</component-icon>
              </component-tooltip>
            </div>
          </template>

          <template v-if="!diseaseForm.value.disease">
            <div class="flex items-center">
              <component-icon class="text-gray-400 text-xl mt-0.5" drag-handle>drag_handle</component-icon>
            </div>
          </template>

          <div class="ml-1 text-sm p-2 w-full border-b-1 border-gray-300">
            {{ diseaseForm.value.disease }}
          </div>
        </div>
      </template>

      <template v-else>
        <div
          class="text-sm text-gray-700 min-w-full h-9 flex items-center p-2 w-full border-b-1 border-gray-300 border-dashed"
        >
          {{ diseaseForm.value.disease }}
        </div>
      </template>
    </div>

    <!-- col 2 - Seit(Datum) -->
    <div class="col-span-3">
      <component-full-date-input v-model="diseaseForm.value.since" :disabled="!isEditable" @change="changeSince" />
    </div>

    <div class="col-span-1 flex m-auto">
      <template v-if="isEditable">
        <component-tooltip placement="left-start" tooltip="Zeile löschen">
          <component-icon-button
            class="text-white bg-gray-500 hover:bg-gray-400 border-gray-500 hover:border-gray-400"
            icon="delete"
            @click="showDeleteConfirmDialog"
          />
        </component-tooltip>
      </template>
    </div>
  </li>
</template>

<script>
  import {inject, onBeforeMount, watch} from "vue";
  import {useForm, usePage} from "@inertiajs/vue3";
  import {cloneDeep, debounce} from "lodash";

  import ComponentFullDateInput from "@components/Inputs/FullDateInput.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentIconButton from "@components/Buttons/IconButton.vue";
  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";

  export default {
    name: "DiseasesTableRow",

    components: {
      ComponentFullDateInput,
      ComponentIcon,
      ComponentIconButton,
      ComponentTooltip,
    },

    props: {
      isEditable: {
        type: Boolean,
        required: true,
      },
      disease: {
        type: Object,
        required: true,
      },
      isDragging: {
        type: Boolean,
        required: true,
      },
    },

    setup(props) {
      const page = usePage();

      const deleteDialog = inject("recordEditDeleteRowDialog");

      const diseaseForm = useForm({
        value: null,
      });

      watch(
        () => props.disease,
        () => {
          diseaseForm.value = cloneDeep(props.disease);
        },
        {deep: true},
      );

      onBeforeMount(() => {
        diseaseForm.value = cloneDeep(props.disease);
      });

      const patchDisease = debounce(
        () => {
          diseaseForm
            .transform((data) => {
              return data.value;
            })
            .put(
              route("diseases.update", {
                patient: page.props.patient.id,
                record: page.props.record.id,
                disease: diseaseForm.value.id,
              }),
              {
                preserveScroll: true,
                only: ["record", "flash"],
              },
            );
        },
        1000,
        {leading: false, trailing: true},
      );

      const changeSince = (newValue) => {
        diseaseForm.value.since = newValue;
        patchDisease();
      };

      const showDeleteConfirmDialog = () => {
        deleteDialog({routeName: "diseases", payload: {disease: diseaseForm.value.id}});
      };

      return {
        /** const */
        diseaseForm,

        /** function */
        changeSince,
        showDeleteConfirmDialog,
      };
    },
  };
</script>
