<template>
  <component-input
    v-model="form.masterdata.doc_name"
    type="text"
    autocomplete="off"
    label="Behandelnder Arzt / behandelnde Ärztin"
    large
  />

  <component-input
    v-model="form.masterdata.nursing_home_carer"
    type="text"
    autocomplete="off"
    label="Zuständige Pflegefachkraft"
    large
  />

  <component-checkbox v-model="showAdditionalFields" color="primary" large>
    Weitere Angaben zur Einrichtung (optional)
  </component-checkbox>

  <template v-if="showAdditionalFields">
    <component-input
      v-model="form.masterdata.nursing_home_name"
      type="text"
      autocomplete="off"
      label="Name der Einrichtung"
      large
    />

    <component-input
      v-model="form.masterdata.nursing_home_street"
      type="text"
      autocomplete="off"
      label="Straße, Hausnr."
      large
    />

    <component-input
      v-model="form.masterdata.nursing_home_zip"
      type="text"
      autocomplete="off"
      label="Postleitzahl"
      large
    />

    <component-input v-model="form.masterdata.nursing_home_city" type="text" autocomplete="off" label="Ort" large />

    <component-input v-model="form.masterdata.nursing_home_ward" type="text" autocomplete="off" label="Station" large />

    <component-input v-model="form.masterdata.nursing_home_room" type="text" autocomplete="off" label="Raum" large />
  </template>
</template>

<script>
  import {onBeforeMount, ref, watch} from "vue";

  import ComponentInput from "@components/Inputs/Input.vue";
  import ComponentCheckbox from "@components/Checkboxes/Checkbox.vue";

  export default {
    name: "PatientinputComponentMasterdataCarerFields",

    components: {ComponentCheckbox, ComponentInput},

    props: {
      form: {
        type: Object,
        required: true,
      },
    },

    setup(props) {
      const showAdditionalFields = ref(false);

      const nursingHomeKeys = [
        "nursing_home_name",
        "nursing_home_street",
        "nursing_home_zip",
        "nursing_home_city",
        "nursing_home_ward",
        "nursing_home_room",
      ];

      onBeforeMount(() => {
        showAdditionalFields.value =
          Object.entries(props.form.masterdata).filter((item) => {
            const key = item[0];
            const value = item[1];

            if (!nursingHomeKeys.includes(key)) return false;
            return !!value;
          }).length > 0;
      });

      watch(
        () => showAdditionalFields.value,
        (newValue, oldValue) => {
          if (newValue && !oldValue) {
            nursingHomeKeys.forEach((key) => {
              props.form.masterdata[key] = null;
            });
          }
        },
      );

      return {showAdditionalFields};
    },
  };
</script>
