<template>
  <div v-show="loaded && password?.length > 0">
    <div class="flex flex-row">
      <div class="inline-flex flex-row border rounded">
        <div class="w-12 h-3 rounded-l" :class="{'bg-mcred-600': password?.length > 0}" />
        <div class="w-12 h-3" :class="{'bg-mcred-500': score >= 2}" />
        <div class="w-12 h-3" :class="{'bg-orange-500': score >= 3}" />
        <div class="w-12 h-3 rounded-r" :class="{'bg-mcgreen-500': score === 4}" />
      </div>
    </div>

    <div v-if="password?.length > 0" class="text-xs">
      <span v-if="score >= 0 && score <= 2" class="text-mcred-700">
        <span v-if="result?.feedback?.warning">{{ result.feedback.warning }}</span>
        <span v-else>Das Datenschutzpasswort ist sehr leicht zu erraten.</span>
      </span>
      <span v-else-if="score === 3" class="text-orange-400">Das Datenschutzpasswort ist leicht zu erraten.</span>
      <span v-else-if="score === 4" class="text-mcgreen-700">Ihr Datenschutzpasswort ist schwer zu knacken.</span>
    </div>
  </div>
</template>

<script>
  import {inject, onBeforeMount, ref, watch} from "vue";
  import {zxcvbn, debounce, zxcvbnOptions} from "@zxcvbn-ts/core";

  export default {
    name: "ComponentPasswordStrength",

    props: {
      password: {
        type: String,
        required: true,
        default: null,
      },
    },

    emits: ["loaded", "scoreChanged"],

    setup(props, {emit}) {
      const privacy = inject("$privacy");

      const result = ref(null);
      const score = ref(0);
      const user = ref([]);
      const loaded = ref(false);

      onBeforeMount(() => {
        Promise.all([
          import("@zxcvbn-ts/language-common"),
          import("@zxcvbn-ts/language-de"),
          // Prevent spinner flushing
          new Promise((res) => setTimeout(res, 1000)),
        ]).then(([zxcvbnCommonPackage, zxcvbnDePackage]) => {
          const options = {
            dictionary: {
              ...zxcvbnCommonPackage.dictionary,
              ...zxcvbnDePackage.dictionary,
            },
            graphs: zxcvbnCommonPackage.adjacencyGraphs,
            useLevenshteinDistance: true,
            translations: zxcvbnDePackage.translations,
          };

          zxcvbnOptions.setOptions(options);

          loaded.value = true;
          emit("loaded");
        });

        // Prevent user from using user-related password
        const clientKeys = ["first_name", "last_name", "city", "company", "zip"];
        const client = privacy.getClient();

        if (client.id) {
          clientKeys.forEach((key) => {
            if (client && client[key] !== null) {
              user.value.push(client[key]);
              client[key].split(" ").forEach((part) => user.value.push(part));
            }
          });
        }

        const currentUser = privacy.getCurrentUser();

        if (typeof currentUser?.name !== "undefined" && currentUser?.name !== null) {
          user.value.push(currentUser.name);
          currentUser.name.split(" ").forEach((part) => user.value.push(part));
        }

        ["MediCheck", "pharma4u"].forEach((siteData) => {
          user.value.push(siteData);
        });
      });

      watch(
        () => props.password,
        debounce(async () => {
          if (import.meta.env.VITE_ALLOW_UNSECURE_PRIVACY_PASSWORD === "true") {
            result.value = null;
            score.value = 4;
          } else if (props.password) {
            result.value = await zxcvbn(props.password, user.value);
            score.value = result.value?.score;

            // min password length = 8
            if (props.password.length < 7 && score.value > 2) {
              result.value = null;
              score.value = 2;
            }
          } else {
            result.value = null;
            score.value = 0;
          }

          emit("scoreChanged", score.value);
        }, 200),
      );

      return {
        loaded,
        score,
        result,
      };
    },
  };
</script>

<style scoped></style>
