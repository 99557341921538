<template>
  <component-dialog ref="refCryptDialog" dialog-width="medium">
    <template #title>Nutzen Sie MediCheck bereits über Ihre Warenwirtschaft?</template>

    <template #content>
      <div class="print:hidden">
        <template v-if="isLoading">
          <component-spinner class="h-12 w-12 mt-8" />
        </template>

        <template v-else>
          <div class="mt-4 flex justify-around space-x-4">
            <div class="w-2/5 flex flex-col space-y-2">
              <component-button
                class="p4umc-primary p4umc-flat"
                label="Nein,"
                @click="posIntegration.alreadyUsingPos = false"
              />
              <p>ich starte ganz neu mit MediCheck</p>
            </div>
            <div class="w-2/5 flex flex-col space-y-2">
              <component-button
                class="p4umc-primary p4umc-flat"
                label="Ja,"
                @click="posIntegration.alreadyUsingPos = true"
              />
              <p>ich nutze MediCheck bereits in meiner Warenwirtschaft</p>
            </div>
          </div>
        </template>
      </div>
    </template>

    <template #actions />
  </component-dialog>
</template>

<script>
  import {ref} from "vue";

  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentSpinner from "@components/Spinner.vue";

  export default {
    name: "CryptDialogsIsPosUser",

    components: {ComponentSpinner, ComponentButton, ComponentDialog},

    props: {
      isLoading: {
        type: Boolean,
        required: true,
        default: false,
      },
      posIntegration: {
        type: Object,
        required: true,
        default: () => {},
      },
    },

    setup() {
      const refCryptDialog = ref(null);

      const open = () => {
        refCryptDialog.value.open();
      };

      const close = () => {
        refCryptDialog.value.close();
      };

      return {refCryptDialog, open, close};
    },
  };
</script>
