<template>
  <div>
    <div class="grid grid-cols-6 md:grid-cols-12 gap-8">
      <div class="min-h-44 col-span-6 lg:col-span-8 md:h-96 md:order-last shadow-md">
        <img
          id="dataselect-header"
          class="object-cover object-left-top w-full h-full"
          src="@images/hero-image-large.jpg"
          alt="Pharmazeutische Dienstleistung Amts"
        />
      </div>

      <div class="grid place-content-center col-span-6 lg:col-span-4 text-left">
        <div v-if="$page.props.can.is_education">
          <h1 class="text-4xl font-bold">MediCheck Education</h1>
          <p class="text-lg">
            Für AMTS-Vorreiter, Chancen-Denker
            <br />
            und Apotheker mit Profil
          </p>
        </div>

        <div v-else>
          <h2 class="text-4xl text-mcred font-bold">AMTS</h2>
          <h1 class="text-4xl font-bold">Dienstleistungen</h1>
        </div>

        <ul class="mt-8">
          <li class="flex items-center space-x-2">
            <component-icon class="text-3xl text-mcgreen">check</component-icon>
            <span class="text-xl font-semibold">Medikationsanalyse</span>
          </li>
          <li class="flex items-center space-x-2">
            <component-icon class="text-3xl text-mcgreen">check</component-icon>
            <span class="text-xl font-semibold">Adhärenz</span>
          </li>
          <li class="flex items-center space-x-2">
            <component-icon class="text-3xl text-mcgreen">check</component-icon>
            <span class="text-xl font-semibold">Prävention</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import ComponentIcon from "@components/Icons/Icon.vue";

  export default {
    name: "DashboardComponentsHeader",

    components: {
      ComponentIcon,
    },
  };
</script>
