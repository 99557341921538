<template>
  <div class="claim">
    <template v-if="readOnly || !showProblemEditor">
      <div
        class="px-2 -mx-1 py-1"
        :class="{'hover:bg-yellow-50': !readOnly}"
        @click="(event) => checkForEmbeddedLink(event, activateProblemEditor)"
        v-html="internalProblem"
      />
    </template>

    <template v-else>
      <component-editor
        ref="refProblemEditor"
        v-model="internalProblem"
        class="claim-text min-h-full"
        @blur="deactivateProblemEditor()"
        @click="(event) => checkForEmbeddedLink(event)"
      />
    </template>

    <template v-if="!readOnly">
      <div class="flex justify-end print:hidden">
        <component-tooltip tooltip="Problemtext bearbeiten" placement="left" :force-inline="true">
          <component-icon-button
            class="text-[1rem] p4umc-primary p4umc-flat"
            icon="edit"
            @click="activateProblemEditor()"
          />
        </component-tooltip>
      </div>
    </template>

    <div class="px-1 font-bold text-green-700 print:mt-6 print:break-after-avoid-page" :class="{'mt-6': readOnly}">
      {{ solutionTitle }}:
    </div>

    <div>
      <template v-if="readOnly || !showSolutionEditor">
        <div
          ref="refSolution"
          class="px-2 -mx-1 py-1 bg-green-50/50 print:line-clamp-none print:break-inside-auto"
          :class="{'hover:bg-yellow-50': !readOnly, 'line-clamp-4': !solutionLengthHasBeenMaximised}"
          @click="(event) => checkForEmbeddedLink(event, activateSolutionEditor)"
          v-html="internalSolution"
        />
      </template>

      <template v-else>
        <component-editor
          ref="refSolutionEditor"
          v-model="internalSolution"
          class="claim-text min-h-full"
          editor-class="bg-green-50/50"
          @blur="deactivateSolutionEditor()"
          @click="(event) => checkForEmbeddedLink(event, activateSolutionEditor)"
        />
      </template>

      <div class="px-1 flex justify-between print:hidden">
        <div v-if="solutionLengthIsShortened || solutionLengthHasBeenMaximised">
          <a
            v-if="solutionLengthIsShortened && !solutionLengthHasBeenMaximised"
            class="no-underline hover:underline"
            @click="toggleSolutionLength()"
          >
            mehr lesen
          </a>
          <a v-else class="no-underline hover:underline" @click="toggleSolutionLength()">weniger lesen</a>
        </div>

        <div v-else><!-- nix --></div>

        <component-tooltip v-if="!readOnly" tooltip="Lösungsvorschlag bearbeiten" placement="left" :force-inline="true">
          <component-icon-button
            class="text-[1rem] p4umc-primary p4umc-flat"
            icon="edit"
            @click="activateSolutionEditor()"
          />
        </component-tooltip>
      </div>
    </div>

    <div v-if="comment && comment.length > 0" class="px-1 mt-2 print:hidden">
      <a class="no-underline hover:underline" @click="showComment = !showComment">Zusatzinformationen / Quellen</a>

      <div v-if="showComment" class="text-gray-600 italic" v-html="comment" />
    </div>
  </div>
</template>

<script>
  import {nextTick, ref, watch} from "vue";
  import Window from "@utils/Helpers/Window.js";
  import ComponentEditor from "@components/Editor/Editor.vue";
  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";
  import ComponentIconButton from "@components/Buttons/IconButton.vue";

  export default {
    name: "PagesRecordResultListClaimTextBox",

    components: {ComponentIconButton, ComponentTooltip, ComponentEditor},

    props: {
      mcCheckId: {
        type: Number,
        required: true,
      },
      problem: {
        type: String,
        required: true,
      },
      solution: {
        type: String,
        required: true,
      },
      comment: {
        type: String,
        default: () => {
          return "";
        },
      },
      claimNumber: {
        type: Number,
        default: null,
      },
      readOnly: {
        type: Boolean,
        default: false,
      },
      visible: {
        type: Boolean,
        default: false,
      },
    },

    emits: ["update:problem", "update:solution", "open:coreInfoDialog"],

    setup(props, {emit}) {
      const showProblemEditor = ref(false);
      const showSolutionEditor = ref(false);
      const showComment = ref(false);

      const solutionTitle = ref("Lösungsvorschlag");

      const refProblemEditor = ref(null);
      const refSolutionEditor = ref(null);
      const refSolution = ref(null);

      const internalProblem = ref(props.problem);
      const internalSolution = ref(props.solution);

      // create || init
      if (props.mcCheckId === 20) {
        solutionTitle.value = "Tipps";
      }

      const solutionLengthIsShortened = ref(false);
      watch(
        () => props.visible,
        (isVisible) => {
          if (isVisible) {
            // recalculate, if 'cline-clamp' class shortens the solution <div>
            nextTick(() => {
              solutionLengthIsShortened.value = refSolution.value?.scrollHeight > refSolution.value?.offsetHeight;
            });
          }
        },
      );

      const solutionLengthHasBeenMaximised = ref(false);

      function toggleSolutionLength() {
        if (solutionLengthIsShortened.value && !solutionLengthHasBeenMaximised.value) {
          solutionLengthHasBeenMaximised.value = true;
        } else {
          solutionLengthHasBeenMaximised.value = !solutionLengthHasBeenMaximised.value;
          // recalculate
          nextTick(() => {
            solutionLengthIsShortened.value = refSolution.value?.scrollHeight > refSolution.value?.offsetHeight;
          });
        }
      }

      function activateProblemEditor() {
        showProblemEditor.value = true;
        setTimeout(() => {
          if (refProblemEditor.value) {
            refProblemEditor.value.focus();
          }
        }, 50);
      }

      function deactivateProblemEditor() {
        showProblemEditor.value = false;
        emit("update:problem", internalProblem.value);
      }

      function activateSolutionEditor() {
        showSolutionEditor.value = true;
        setTimeout(() => {
          if (refSolutionEditor.value) {
            refSolutionEditor.value.focus();
          }
        }, 50);
      }

      function deactivateSolutionEditor() {
        showSolutionEditor.value = false;
        emit("update:solution", internalSolution.value);
      }

      function checkForEmbeddedLink(eventObject, callback = null) {
        if (eventObject.target.localName === "a") {
          eventObject.stopPropagation();
          eventObject.preventDefault();

          if (Object.keys(eventObject.target.dataset).length === 0) {
            // external links (without any data attribute)
            Window.open(eventObject.target.href, "_blank");
          } else {
            // handleMedicalTextLink
            const datasetNames = Object.keys(eventObject.target.dataset);

            // new: layer detail content is linked within href
            if (datasetNames.indexOf("layer") >= 0) {
              // href="https://www..." data-layer="interaction|dosage|..."
              emit("open:coreInfoDialog", {
                url: eventObject.target.href,
                type: eventObject.target.dataset.layer,
              });
            }
            // legacy: layer detail content url will be generated via data-attribute
            // (for claims which are already stored in our dataservice)
            else if (datasetNames.indexOf("keyfat") >= 0) {
              emit("open:coreInfoDialog", {
                referenceName: eventObject.target.dataset.famnames || null,
                key: eventObject.target.dataset.keyfat,
                type: "dosage",
              });
            } else if (datasetNames.indexOf("keyint") >= 0) {
              emit("open:coreInfoDialog", {
                referenceName: null,
                key: eventObject.target.dataset.keyint,
                type: "interaction",
              });
            } else if (datasetNames.indexOf("guidelineid") >= 0) {
              emit("open:coreInfoDialog", {
                referenceName: null,
                key: eventObject.target.dataset.guidelineid,
                medicationType: eventObject.target.dataset.guidelinemedication,
                type: "guideline",
              });
            }
          }
        } else if (callback) {
          callback();
        }
      }

      return {
        refProblemEditor,
        refSolutionEditor,
        internalProblem,
        internalSolution,
        showProblemEditor,
        showSolutionEditor,
        showComment,

        refSolution,
        solutionLengthIsShortened,
        solutionLengthHasBeenMaximised,
        toggleSolutionLength,

        activateProblemEditor,
        activateSolutionEditor,
        deactivateProblemEditor,
        deactivateSolutionEditor,

        solutionTitle,
        checkForEmbeddedLink,
      };
    },
  };
</script>

<style>
  /* unfortunately it's not possible to @apply our custom 'list-dash' tailwind plugin */
  .claim ul {
    @apply my-1 pl-4;
    list-style-type: "- ";
  }
  .claim ol {
    @apply my-1 pl-5;
    list-style: decimal;
  }
</style>
