<template>
  <form @submit.prevent="save">
    <component-card class="relative group hover:bg-gray-100">
      <div class="flex items-baseline justify-between">
        <h6 class="text-xl">Pflegestufe</h6>

        <component-spinner v-if="form.processing" class="size-4" />
        <component-unsaved-changes v-else-if="form.isDirty || form.recentlySuccessful" :form="form" />
        <span v-else class="text-xs text-gray-500">Aktualisiert {{ attribute.updated_at }}</span>
      </div>

      <template v-if="activeEdit">
        <div class="mt-4 grid grid-cols-4 gap-8">
          <component-select
            v-model="form.payload.level"
            class="col-span-2"
            label="Pflegegrad"
            :validation="form.errors['payload.level']"
            :helper-text="helperText"
          >
            <option value="1">Pflegegrad 1</option>
            <option value="2">Pflegegrad 2</option>
            <option value="3">Pflegegrad 3</option>
            <option value="4">Pflegegrad 4</option>
            <option value="5">Pflegegrad 5</option>
          </component-select>
        </div>
      </template>

      <template v-else>
        <div class="mt-0.5 flex items-baseline space-x-4">
          <span class="text-3xl">{{ attribute.payload?.level }}</span>
          <span v-text="helperText" />
        </div>

        <div class="absolute hidden group-hover:flex right-2 bottom-2 flex-row-reverse space-x-4 space-x-reverse">
          <component-icon-button type="button" class="p4umc-primary" icon="edit" @click="activeEdit = true" />
          <component-icon-button type="button" icon="delete" @click="confirmRemove()" />
        </div>
      </template>

      <template v-if="activeEdit" #actions>
        <component-button class="p4umc-primary" label="Speichern" :disabled="form.processing" />
        <component-button type="button" label="Abbrechen" :disabled="form.processing" @click="cancelEdit()" />
      </template>
    </component-card>
  </form>

  <component-confirmation-dialog
    ref="confirmationDialog"
    title="Sind Sie sicher?"
    content="Die Angabe „Pflegestufe“ wird gelöscht."
    @confirmed="remove()"
  />
</template>

<script>
  import ComponentCard from "@components/Cards/Card.vue";
  import ComponentSelect from "@components/Selects/Select.vue";
  import {useForm} from "@inertiajs/vue3";
  import ComponentUnsavedChanges from "@components/Alerts/UnsavedChanges.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import {computed, inject, ref, watch} from "vue";
  import ComponentConfirmationDialog from "@components/Dialogs/ConfirmationDialog.vue";
  import {degreeOfCareLevel} from "@pages/Patients/Components/PatientAttributes/Enums.js";
  import ComponentIconButton from "@components/Buttons/IconButton.vue";
  import ComponentSpinner from "@components/Spinner.vue";

  export default {
    name: "PatientsPatientAttributesDegreeOfCareAttribute",
    components: {
      ComponentSpinner,
      ComponentIconButton,
      ComponentConfirmationDialog,
      ComponentButton,
      ComponentUnsavedChanges,
      ComponentSelect,
      ComponentCard,
    },
    props: {
      attribute: Object,
      patient: Object,
      recentlyAdded: {
        type: Boolean,
        default: false,
      },
    },

    setup(props) {
      const broadcast = inject("$broadcast");

      const confirmationDialog = ref(null);
      const activeEdit = ref(props.recentlyAdded);

      watch(
        () => props.recentlyAdded,
        (newValue) => {
          activeEdit.value = newValue;
        },
      );

      const form = useForm({
        type: props.attribute.type,
        payload: {
          level: props.attribute?.payload?.level,
        },
      });

      const helperText = computed(() => {
        return typeof degreeOfCareLevel[form.payload.level] !== "undefined"
          ? degreeOfCareLevel[form.payload.level]
          : null;
      });

      function cancelEdit() {
        // was recently added AND was NOT saved once
        if (props.recentlyAdded && !form.wasSuccessful) {
          remove();
        } else {
          form.reset();
          activeEdit.value = false;
        }
      }

      function save() {
        form.put(route("attributes.update", {patient: props.patient.id, attribute: props.attribute.id}), {
          preserveScroll: true,
          onSuccess: () => {
            activeEdit.value = false;
            broadcast.patient.postMessage(props.patient.id, {action: "updated"});
          },
        });
      }

      function confirmRemove() {
        confirmationDialog.value.open();
      }

      function remove() {
        form.delete(route("attributes.destroy", {patient: props.patient.id, attribute: props.attribute.id}), {
          preserveScroll: true,
          onSuccess: () => {
            broadcast.patient.postMessage(props.patient.id, {action: "updated"});
          },
        });
      }

      return {
        activeEdit,
        cancelEdit,
        form,
        save,
        confirmRemove,
        remove,
        helperText,
        confirmationDialog,
      };
    },
  };
</script>
