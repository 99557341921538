<template>
  <form @submit.prevent="save">
    <component-card class="rounded-lg border-2 border-gray-200" :class="$attrs['class']">
      <div class="grid grid-cols-4 gap-8">
        <component-input
          v-model="form.company"
          class="col-span-2"
          label="Praxis / Institution / Firma**"
          :hide-clear="true"
          :validation="form.errors.company"
        />

        <component-select
          v-model="form.profession"
          class="col-span-2"
          label="Fachrichtung"
          :validation="form.errors.profession"
          :options="doctorProfession"
          :nullable="true"
          placeholder-text=""
        />

        <!-- TODO: Autocomplete mit fest hinterlegten typischen Titeln -->
        <component-input
          v-model="form.title"
          class="col-start-1"
          label="Titel"
          :hide-clear="true"
          :validation="form.errors.title"
        />

        <component-input
          v-model="form.firstname"
          class="col-start-1 col-span-2"
          label="Vorname"
          :hide-clear="true"
          :validation="form.errors.firstname"
        />

        <component-input
          v-model="form.lastname"
          class="col-span-2"
          label="Nachname**"
          :hide-clear="true"
          :validation="form.errors.lastname"
        />

        <component-input
          v-model="form.street"
          class="col-start-1 col-span-4"
          label="Straße, Hausnr."
          :hide-clear="true"
          :validation="form.errors.street"
        />

        <component-input
          v-model="form.zip"
          class="col-start-1 col-span-1"
          label="PLZ"
          :hide-clear="true"
          :validation="form.errors.zip"
        />

        <component-input
          v-model="form.city"
          class="col-span-3"
          label="Ort"
          :hide-clear="true"
          :validation="form.errors.city"
        />

        <component-input
          v-model="form.telephone"
          class="col-start-1 col-span-2"
          label="Telefon"
          :hide-clear="true"
          :validation="form.errors.telephone"
        />

        <component-input v-if="completeForm" v-model="form.fax" class="col-span-2" label="Fax" :hide-clear="true" />

        <component-input
          v-if="completeForm"
          v-model="form.email"
          class="col-start-1 col-span-2"
          type="email"
          label="E-Mail"
          :hide-clear="true"
          :validation="form.errors.email"
        />

        <component-textarea
          v-if="completeForm"
          v-model="form.note"
          class="col-start-1 col-span-4"
          label="Zusätzliche Informationen"
          :validation="form.errors.note"
        />
      </div>

      <div class="text-sm text-right">
        <!-- * Pflichtangaben -->
        ** mind. eine Angabe erforderlich
      </div>

      <template #actions>
        <component-button class="p4umc-primary" label="Speichern" :disabled="form.processing" @click="submitClicked" />
        <component-button label="Abbrechen" :disabled="form.processing" @click="handleCancel" />
      </template>
    </component-card>
  </form>
</template>

<script>
  import ComponentInput from "@components/Inputs/Input.vue";
  import ComponentCard from "@components/Cards/Card.vue";
  import ComponentTextarea from "@components/Textarea/Textarea.vue";
  import ComponentSelect from "@components/Selects/Select.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import {router, useForm} from "@inertiajs/vue3";
  import {doctorProfession} from "@pages/Contacts/Enums/Enums.js";
  import {inject, watch} from "vue";

  export default {
    name: "ContactsContactForm",
    components: {
      ComponentButton,
      ComponentSelect,
      ComponentTextarea,
      ComponentCard,
      ComponentInput,
    },

    props: {
      contact: {
        type: Object,
        default: null,
      },
      completeForm: {
        type: Boolean,
        default: true,
      },
      patientId: {
        type: String,
        default: null,
      },
      redirectBack: {
        type: Boolean,
        default: false,
      },
      emitCancel: {
        type: Boolean,
        default: false,
      },
    },

    emits: ["submitButtonClicked", "cancelButtonClicked", "submitHasNoErrors"],

    setup(props, {emit}) {
      const broadcast = inject("$broadcast");

      const form = useForm({
        title: props?.contact?.title,
        lastname: props?.contact?.lastname,
        firstname: props?.contact?.firstname,
        profession: props?.contact?.profession,
        email: props?.contact?.email,
        telephone: props?.contact?.telephone,
        fax: props?.contact?.fax,
        company: props?.contact?.company,
        street: props?.contact?.street,
        zip: props?.contact?.zip,
        city: props?.contact?.city,
        note: props?.contact?.note,
        patient_id: props.patientId,
        redirect_back: props.redirectBack,
      });

      watch(
        () => form.processing,
        (formIsProcessing, formWasProcessing) => {
          if (!formIsProcessing && formWasProcessing && !form.hasErrors) {
            emit("submitHasNoErrors");
          }
        },
      );

      function save() {
        if (props.contact !== null && typeof props.contact.id !== "undefined" && props.contact.id !== null) {
          // update
          form.put(route("contacts.update", {contact: props.contact.id}), {});
        } else {
          // store
          form.post(route("contacts.store"), {
            preserveScroll: true,
            onSuccess: () => {
              if (form.patient_id !== null) {
                broadcast.patient.postMessage(form.patient_id, {action: "updated"});
              }
            },
          });
        }
      }

      function submitClicked() {
        emit("submitButtonClicked");
      }

      function handleCancel() {
        if (props.emitCancel) {
          emit("cancelButtonClicked");
        } else {
          window.history.back();
        }
      }

      return {form, save, submitClicked, handleCancel, doctorProfession};
    },
  };
</script>

<style scoped></style>
