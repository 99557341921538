<template>
  <div class="w-full text-ellipsis relative">
    <label
      v-if="label.length > 0"
      :class="{'text-mcred': isInvalid, 'text-gray-500': !isInvalid && !large, 'text-sm': !large}"
      class="duration-300 -z-1 origin-0 truncate cursor-default"
      :for="fieldId"
    >
      {{ label }}
    </label>
    <div class="flex relative" :class="background">
      <div v-if="hasIconSlot" class="absolute pt-2">
        <slot name="icon" />
      </div>

      <input
        :id="fieldId"
        ref="field"
        v-model="currentValue"
        :type="currentType"
        :disabled="disabled"
        :name="name"
        :placeholder="placeholder"
        :autocomplete="autocomplete"
        :tabindex="tabindex"
        :maxlength="maxlength"
        :autofocus="autofocus"
        :pattern="pattern"
        class="placeholder-shown:text-sm h-9 text-ellipsis p-2"
        :class="[
          {
            'cursor-text': !disabled,
            'w-8 px-0 text-center': dosageField,
            'px-2': hasBackground && !dosageField,
            'pl-6': hasIconSlot,
            'pr-6': (!hideClear && !disabled) || type === 'password',
            'text-mcred': dosageField && validation,
          },
          inputAlignment,
        ]"
        @input="handleInput"
        @focus="handleFocus"
        @keyup="$emit('keyup', $event)"
        @keydown="$emit('keydown', $event)"
        @blur="handleBlur"
      />

      <input v-model="internalValue" type="hidden" />

      <div
        v-if="hasSuffixSlot"
        class="absolute pt-2 inset-y-0 text-sm"
        :class="{
          'right-8': !hideClear && !dosageField && type !== 'password' && type !== 'date' && !disabled,
          'right-2': !(!hideClear && !dosageField && type !== 'password' && type !== 'date' && !disabled),
        }"
      >
        <slot name="suffix" />
      </div>

      <ul
        v-if="datalistIsVisible && filteredDatalist && filteredDatalist.length > 1"
        class="z-10 absolute bottom-0 w-full translate-y-full bg-white shadow-md max-h-52 space-y-0.5 overflow-y-auto"
      >
        <li
          v-for="(option, index) in filteredDatalist"
          :key="'datalist-' + index"
          class="cursor-pointer p-px hover:bg-gray-100"
          :class="{'bg-gray-100': index === selectedDatalistIndex}"
          @click="selectDatalist(index)"
        >
          {{ option }}
        </li>
      </ul>

      <component-icon
        v-if="type === 'password' && !passwordVisible"
        :clickable="true"
        class="text-gray-500 text-base -ml-6 mt-2.5"
        @click="passwordIconClicked"
      >
        visibility_off
      </component-icon>

      <component-icon
        v-if="type === 'password' && passwordVisible"
        :clickable="true"
        class="text-gray-500 text-base -ml-6 mt-2.5"
        @click="passwordIconClicked"
      >
        visibility
      </component-icon>

      <component-icon
        v-if="!hideClear && !dosageField && type !== 'password' && type !== 'date' && !disabled"
        :clickable="true"
        class="text-gray-500 text-base -ml-6 mt-2.5"
        @click="clearField"
      >
        clear
      </component-icon>
    </div>

    <div
      class="flex cursor-default mt-1 space-x-2"
      :class="{'justify-center': dosageField, 'justify-between': !dosageField}"
    >
      <div>
        <div class="text-xs text-mcred" data-test="errorMessage">
          <slot name="validation">{{ validation ?? internalValidation }}</slot>
        </div>
        <div v-if="helperText && !isInvalid" class="text-xs text-gray-500">{{ helperText }}</div>
      </div>
      <div
        v-if="maxlength && showMaxlengthCount && !disabled"
        class="text-xs text-gray-500 text-right"
        :class="{'text-mcred': currentLength >= maxlength}"
      >
        {{ currentLength }}/{{ maxlength }}
      </div>
      <div v-if="parseInt(currentLength) === parseInt(maxlength) && showMaxLengthMessage" class="text-xs text-mcred">
        {{ showMaxLengthMessage }}
      </div>
    </div>
  </div>
</template>

<script>
  import {ref, computed, watch, nextTick, inject, onMounted, onUnmounted} from "vue";

  import ComponentIcon from "@components/Icons/Icon.vue";

  export default {
    name: "ComponentInput",

    components: {ComponentIcon},

    props: {
      name: {
        type: String,
        default: "text",
      },
      placeholder: {
        type: String,
        default: "",
      },
      type: {
        type: String,
        default: "text",
      },
      label: {
        type: String,
        default: "",
      },
      autocomplete: {
        type: String,
        default: "on",
      },
      tabindex: {
        type: [String, Number],
        default: null,
      },
      maxlength: {
        type: [String, Number],
        default: null,
      },
      showMaxlengthCount: {
        type: Boolean,
        default: true,
      },
      showMaxLengthMessage: {
        type: String,
        default: "",
      },
      autofocus: {
        type: Boolean,
        default: false,
      },
      background: {
        type: String,
        default: "",
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      inputAlignment: {
        type: String,
        default: "text-left",
      },
      modelValue: {
        type: [String, Number, ref],
        default: "",
      },
      dosageField: {
        type: Boolean,
        default: false,
      },
      hideClear: {
        type: Boolean,
        default: false,
      },
      validation: {
        type: String,
        default: null,
      },
      helperText: {
        type: String,
        default: null,
      },
      filter: {
        type: String,
        default: null,
      },
      timeSelect: {
        type: String,
        default: "",
      },
      encrypted: {
        type: Boolean,
        default: false,
      },
      datalist: {
        type: Array,
        default: () => [],
      },
      pattern: {
        type: String,
        default: null,
      },
      large: {
        type: Boolean,
        default: false,
      },
    },

    emits: ["input", "changed", "blur", "clear", "keyup", "keydown", "update:modelValue", "decrypted"],

    setup(props, {emit, slots}) {
      const privacy = inject("$privacy");

      const field = ref(null);
      const fieldId = ref(crypto.randomUUID());
      const currentValue = ref(null);
      const internalValue = ref(props.modelValue);
      const passwordVisible = ref(false);
      const currentType = ref(props.type);

      const isInvalid = computed(() => !!props.validation || internalValidity.value === false);
      const internalValidity = ref(true);
      const internalValidation = ref(null);
      const hasIconSlot = computed(() => !!slots.icon);
      const hasSuffixSlot = computed(() => !!slots.suffix);
      const hasBackground = computed(() => props.background.length > 0);
      const currentLength = computed(() => {
        if (!currentValue.value) {
          return 0;
        }
        return currentValue.value.length;
      });

      const selectedDatalistIndex = ref(0);
      const datalistIsVisible = ref(false);
      const filteredDatalist = computed(() => {
        return typeof currentValue.value === "string"
          ? props.datalist.filter((item) => item.toLowerCase().includes(currentValue.value.toLowerCase().trim()))
          : props.datalist;
      });

      function selectDatalist(index) {
        index = index ?? selectedDatalistIndex.value;
        currentValue.value = filteredDatalist.value[index] ?? "";
        datalistIsVisible.value = false;
        handleInput();
      }

      function datalistKeyDownHandler(event) {
        switch (event.key) {
          case "ArrowDown":
            if (!datalistIsVisible.value) {
              datalistIsVisible.value = true;
            } else {
              selectedDatalistIndex.value = Math.min(
                selectedDatalistIndex.value + 1,
                filteredDatalist.value.length - 1,
              );
            }
            break;
          case "ArrowUp":
          case "Delete":
          case "Backspace":
            if (!datalistIsVisible.value) {
              datalistIsVisible.value = true;
            } else {
              selectedDatalistIndex.value = Math.max(selectedDatalistIndex.value - 1, 0);
            }
            break;
          case "Enter":
            event.preventDefault();
            selectDatalist();
            break;
        }
      }

      onMounted(() => {
        if (props.datalist.length > 0) {
          // we do not use @keydown.down, .up, .delete and .enter
          // on the input element, because we only want to handle
          // those events if datalist prop is set
          field.value.addEventListener("keydown", datalistKeyDownHandler);
        }
      });

      onUnmounted(() => {
        if (props.datalist.length > 0 && field.value) {
          field.value.removeEventListener("keydown", datalistKeyDownHandler);
        }
      });

      watch(
        () => props.modelValue,
        (newValue) => {
          internalValue.value = newValue;
        },
      );

      watch(
        internalValue,
        (newValue) => {
          if (props.encrypted) {
            privacy.decryptValue(internalValue.value).then((plaintext) => {
              currentValue.value = plaintext;
              emit("decrypted", plaintext);
            });
          } else {
            currentValue.value = newValue;
          }
        },
        {immediate: true},
      );

      watch(
        () => currentValue.value,
        (newValue) => {
          if (props.filter) {
            currentValue.value = newValue.replace(new RegExp(props.filter, "g"), "");
          }
          if (props.datalist.length > 0) {
            selectedDatalistIndex.value = 0;
          }

          if (props.type === "date") {
            if (field.value.checkValidity() === false) {
              internalValidity.value = false;
              internalValidation.value = "Das Feld ist nicht vollständig oder enthält ein ungültiges Datum";
            } else {
              internalValidity.value = true;
              internalValidation.value = null;
            }
          }
        },
      );

      function mcEscape(input) {
        if (input && typeof input === "string") {
          return input.replace(/<\S[^>]*>/g, "");
        }

        return input;
      }

      function handleInput() {
        if (
          (props.timeSelect === "hourField" && parseInt(currentValue.value) > 24) ||
          (props.timeSelect === "minuteField" && parseInt(currentValue.value) > 59)
        ) {
          currentValue.value = "00";
        }

        if (props.type === "number" && props.maxlength && currentValue.value.length > props.maxlength) {
          currentValue.value = currentValue.value.slice(0, props.maxlength);
        } else if (props.type === "number" && currentValue.value.length === 0) {
          currentValue.value = "00";
        }

        if (props.encrypted && mcEscape(currentValue.value).trim() !== "") {
          // encrypt non-empty values
          privacy.encryptValue(mcEscape(currentValue.value)).then((hash) => {
            internalValue.value = hash;

            emit("update:modelValue", internalValue.value);
            emit("input", internalValue.value);
            emit("changed", internalValue.value);
          });
        } else {
          // set empty values to null
          internalValue.value = props.type !== "password" ? mcEscape(currentValue.value) || null : currentValue.value;

          emit("update:modelValue", internalValue.value);
          emit("input", internalValue.value);
          emit("changed", internalValue.value);
        }
      }

      function handleBlur(e) {
        if (
          (props.timeSelect === "hourField" || props.timeSelect === "minuteField") &&
          parseInt(currentValue.value) < 10 &&
          currentValue.value !== "00"
        ) {
          currentValue.value = "0" + parseInt(currentValue.value);
        }

        if (props.datalist.length > 0) {
          setTimeout(() => {
            datalistIsVisible.value = false;
          }, 200);
        }

        emit("blur", e);
      }

      function passwordIconClicked() {
        passwordVisible.value = !passwordVisible.value;

        if (passwordVisible.value) {
          currentType.value = "text";
        } else {
          currentType.value = "password";
        }
      }

      function handleFocus(event) {
        event.target.select();

        if (props.datalist.length > 0) {
          setTimeout(() => {
            if (document.activeElement === event.target) {
              datalistIsVisible.value = true;
            }
          }, 1000);
        }
      }

      function focus() {
        nextTick(() => this.$refs.field.focus());
      }

      function clearField() {
        currentValue.value = "";
        handleInput();
        emit("blur");
        emit("clear");
      }

      return {
        field,
        fieldId,
        currentValue,
        internalValue,
        passwordVisible,
        currentType,
        isInvalid,
        internalValidation,
        hasIconSlot,
        hasSuffixSlot,
        hasBackground,
        currentLength,
        handleInput,
        handleBlur,
        passwordIconClicked,
        handleFocus,
        focus,
        clearField,

        filteredDatalist,
        selectedDatalistIndex,
        datalistIsVisible,
        selectDatalist,
      };
    },
  };
</script>

<!--input type number hide arrows for firefox-->
<style>
  input[type="number"] {
    -moz-appearance: textfield;
  }
</style>
