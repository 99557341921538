<template>
  <patientinput-component-step title="Wie geht es Ihnen im Alltag?">
    <component-range
      v-model="form.compliance.confident"
      min="1"
      max="5"
      label="Wie sicher fühlen Sie sich um Umgang mit Ihrer Medikation?"
      :datalist="confidentDatalist"
    />

    <component-range
      v-model="form.compliance.forgotten"
      min="1"
      max="5"
      label="Jeder von uns hat schon mal etwas vergessen. Haben Sie schonmal die Einnahme Ihrer Medikamente vergessen?"
      :datalist="forgottenDatalist"
    />

    <component-range
      v-model="form.compliance.affect"
      min="1"
      max="5"
      label="Wie sehr wird Ihr Alltag durch die Einnahme/Anwendung Ihrer Medikamente beeinträchtigt?"
      :datalist="affectDatalist"
    />

    <component-range
      v-model="form.compliance.pain"
      min="0"
      max="10"
      label="Falls Sie unter Schmerzen leiden, wie stark sind diese im Durchschnitt auf einer Skala von 0 (= kein Schmerz) bis 10 (= stärkster vorstellbarer Schmerz)?"
      :datalist="painDatalist"
      show-value
    />
  </patientinput-component-step>
</template>

<script>
  import PatientinputComponentStep from "@pages/Patientinput/Components/Step.vue";
  import ComponentRange from "@components/Inputs/Range.vue";

  export default {
    name: "PatientinputComponentStepCompliance",

    components: {
      ComponentRange,
      PatientinputComponentStep,
    },

    props: {
      form: {
        type: Object,
        required: true,
      },
    },

    setup() {
      const confidentDatalist = ["nicht sicher", "sehr sicher"];
      const forgottenDatalist = ["noch nie", "sehr oft"];
      const affectDatalist = ["überhaupt nicht", "völlig"];
      const painDatalist = ["kein Schmerz", "stärkster vorstellbarer Schmerz"];

      return {confidentDatalist, forgottenDatalist, affectDatalist, painDatalist};
    },
  };
</script>
