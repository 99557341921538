<template>
  <tr
    class="border-b-2 border-gray-200 leading-tight"
    :class="{'hover:bg-gray-100 cursor-pointer': !patient.revoked, 'text-gray-500 cursor-not-allowed': patient.revoked}"
    @click="(event) => clickRow(event, $route('patients.show', {patient: patient.id}))"
  >
    <!-- patient -->
    <td class="px-0.5 py-2">
      <component-tooltip v-if="patient.aliasname" tooltip="Alias" :force-inline="true">
        <span class="text-sm">„{{ patient.aliasname }}“</span>
      </component-tooltip>
      <div class="flex items-center space-x-2">
        <inertia-link
          :href="$route('patients.show', {patient: patient.id})"
          class="font-semibold text-lg"
          :class="{
            'text-gray-700 hover:text-gray-900 cursor-pointer hover:underline': !patient.revoked,
            'text-gray-500 hover:no-underline cursor-not-allowed': patient.revoked,
          }"
        >
          {{ patient?.shared_owners[0]?.company }}
        </inertia-link>
        <component-tooltip v-if="!patient.revoked" tooltip="Patientennotiz öffnen">
          <component-icon-button icon="assignment" class="p4umc-flat text-sm" @click="openPatientNote()" />
        </component-tooltip>
      </div>

      <div>
        <component-tooltip tooltip="Patient" :force-inline="true">
          <span class="text-xs">{{ patient.lastname }}, {{ patient.firstname }}</span>
        </component-tooltip>
        <component-tooltip tooltip="Alter" :force-inline="true">
          <span class="text-xs" data-format="age" :data-encrypted="patient.birthdate" />
        </component-tooltip>
      </div>
    </td>

    <!-- status -->
    <td class="px-0.5 py-2">
      <patients-patient-information :patient="patient" />
    </td>

    <!-- to dos -->
    <td class="px-0.5 py-2 text-sm">
      <template v-if="!patient.revoked">
        <component-tooltip v-if="patient?.todo_count === 0" tooltip="Neue Aufgabe anlegen" :force-inline="true">
          <component-icon-button icon="add" class="p4umc-flat" @click="openPatientTodos" />
        </component-tooltip>

        <a v-if="patient?.todo_count > 0" href="#" class="text-gray-900" @click.prevent="openPatientTodos()">
          {{ patient?.todo_last?.label }}
        </a>
        <div v-if="patient?.todo_count > 1" class="text-xs">
          und
          <a href="#" @click.prevent="openPatientTodos()">{{ patient?.todo_count - 1 }} weitere</a>
        </div>
      </template>
    </td>

    <!-- last updates -->
    <td class="px-0.5 py-2">
      {{ patient.updated_at }}
      <div v-if="patient.username" class="text-xs">
        {{ patient.username }}
      </div>
    </td>

    <!-- actions -->
    <td class="px-0.5 py-2">
      <div class="flex justify-end space-x-4">
        <component-tooltip tooltip="Löschen">
          <component-icon-button icon="delete" @click="confirmDelete()" />
        </component-tooltip>
      </div>
    </td>
  </tr>
</template>

<script>
  import {Link as InertiaLink, router} from "@inertiajs/vue3";

  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";
  import ComponentIconButton from "@components/Buttons/IconButton.vue";
  import PatientsPatientInformation from "@pages/Patients/Components/PatientInformation.vue";

  export default {
    name: "PatientsExternalPatientRow",

    components: {
      PatientsPatientInformation,
      ComponentIconButton,
      InertiaLink,
      ComponentTooltip,
    },

    props: {
      patient: {
        type: Object,
        required: true,
      },
    },

    setup(props, {emit}) {
      function openPatientNote() {
        emit("open:patientNote");
      }
      function openPatientTodos() {
        emit("open:patientTodos");
      }
      function confirmDelete() {
        emit("confirm:delete");
      }
      function clickRow(event, href) {
        if (props.patient.revoked) {
          // disable click when revoked
          return;
        }

        let node = event.target ?? event;

        if (!node.nodeName) {
          return;
        }

        if (node.nodeName.toLowerCase() === "td") {
          // click on <td> => open link
          router.visit(href);
          return;
        }

        if (
          node.nodeName.toLowerCase() === "a" ||
          node.nodeName.toLowerCase() === "button" ||
          node.nodeName.toLowerCase() === "select" ||
          node.nodeName.toLowerCase() === "input" ||
          node.nodeName.toLowerCase() === "textarea" ||
          node.nodeName.toLowerCase() === "dialog"
        ) {
          // click on a clickable element (a or button)
          return;
        }

        if (node.parentNode) {
          // click on any other element: check if parent is <td>
          return clickRow(node.parentNode, href);
        }
      }

      return {openPatientNote, openPatientTodos, confirmDelete, clickRow};
    },
  };
</script>
