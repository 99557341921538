<template>
  <patientinput-component-step :forward-button="false" :back-button="false" :navigation-horizontal="false">
    <template #title>
      <img src="/resources/assets/images/mcp_logo_RGB_pos.svg" width="250" alt="MediCheck" class="mt-3" />
    </template>

    <h1 class="text-2xl md:text-4xl text-center font-medium">
      Checkliste zur Datenerfassung für die Medikationsberatung
    </h1>

    <img src="@images/hero-image.jpg" alt="" class="rounded" />

    <p>Sehr geehrte Pflegefachkraft,</p>

    <p>
      diese digitale Checkliste soll Sie bei der Erfassung wichtiger Informationen für die Medikationsberatung von
      Patienten im Alten- und Pflegeheim unterstützen (Dauer ca. 5 Minuten).
    </p>

    <p>
      Verantwortlich für die Datenverarbeitung im Sinne der DSGVO ist
      <span v-if="company">{{ company }}.</span>
      <span v-else>Ihre Apotheke.</span>
    </p>

    <template #navigation>
      <component-button class="p4umc-primary" @click="$emit('navigate', '+')">Mit der Eingabe starten</component-button>
    </template>
  </patientinput-component-step>
</template>

<script>
  import {usePage} from "@inertiajs/vue3";
  import {computed} from "vue";

  import ComponentButton from "@components/Buttons/Button.vue";
  import PatientinputComponentStep from "@pages/Patientinput/Components/Step.vue";

  export default {
    name: "PatientinputComponentStepWelcome",

    components: {
      ComponentButton,
      PatientinputComponentStep,
    },

    props: {
      form: {
        type: Object,
        required: true,
      },
    },

    emits: ["navigate"],

    setup() {
      const page = usePage();

      const company = computed(() => page.props.company);

      return {company};
    },
  };
</script>
