<template>
  <component-dialog
    ref="refDialog"
    dialog-width="small"
    :dialog-title="form.wasSuccessful ? 'Zurücksenden erfolgreich' : 'Sind Sie sicher?'"
  >
    <template #content>
      <div v-if="form.wasSuccessful">Diese Variante wurde an den Absender zurückgesendet.</div>
      <div v-else>
        Sie senden diese Variante mit den ggf. von Ihnen vorgenommenen Änderungen an den Absender zurück.
      </div>
    </template>
    <template #actions>
      <component-button
        v-if="!form.wasSuccessful"
        :disabled="form.processing"
        class="p4umc-primary"
        label="Zurücksenden"
        @click="complete()"
      />
      <component-button :label="form.wasSuccessful ? 'Schließen' : 'Abbrechen'" @click="close()" />
    </template>
  </component-dialog>
</template>
<script>
  import {ref, watch} from "vue";
  import {useForm} from "@inertiajs/vue3";
  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentButton from "@components/Buttons/Button.vue";

  export default {
    name: "ComponentRecordShareCompleteDialog",
    components: {
      ComponentButton,
      ComponentDialog,
    },
    props: {
      record: {
        type: Object,
        default: () => {
          return null;
        },
      },
      sharedrecordId: {
        type: String,
        default: () => {
          return null;
        },
      },
    },
    expose: ["open", "close"],
    emits: ["completed"],

    setup(props, {emit}) {
      const refDialog = ref(null);
      const currentRecord = ref(props.record);
      const currentSharedrecordId = ref(props.sharedrecordId);

      let form = useForm({
        info: null,
      });

      watch(
        () => props.record,
        (record) => {
          currentRecord.value = record;
          // record.info is always unencrypted within this dialog
          form.info = record.info;
        },
      );

      function open(sharedrecordId) {
        if (typeof sharedrecordId !== "undefined") {
          form.reset();
          form.wasSuccessful = false;
          currentSharedrecordId.value = sharedrecordId;
        }
        refDialog.value.open();
      }

      function close() {
        refDialog.value.close();
      }

      function complete() {
        form.patch(
          route("sharedrecords.update", {
            patient: currentRecord.value.patient_id,
            record: currentRecord.value.id,
            sharedrecord: currentSharedrecordId.value,
          }),
          {
            preserveScroll: true,
            onSuccess: () => {
              emit("completed");
            },
          },
        );
      }

      return {
        refDialog,
        form,
        open,
        close,
        complete,
      };
    },
  };
</script>
