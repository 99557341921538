<template>
  <dialog
    v-if="active"
    ref="dialogRef"
    class="!m-auto w-full bg-white rounded-lg p-4 text-left shadow-xl print:shadow-none transform transition-all overflow-y-auto overscroll-contain print:absolute print:top-0 print:left-0 cursor-default"
    :class="[widthGreaterThanSmall, overflowClass, {'!overflow-hidden': isLoading}]"
    @cancel.prevent="handleCancel"
  >
    <div class="relative">
      <div v-if="isLoading" class="absolute z-[60] backdrop-blur -inset-4">
        <div class="text-center h-full flex justify-center items-center">
          <component-spinner class="h-12 w-12" />
        </div>
      </div>

      <div class="flex w-full">
        <h3 id="modal-title" class="text-lg leading-6 font-medium">
          <slot name="title">{{ dialogTitle }}</slot>
        </h3>
        <div class="ml-1">
          <slot name="info-icon" />
        </div>
      </div>

      <div v-if="hasSlotContent === false" class="mt-4">
        <p class="text-gray-700">{{ dialogContent }}</p>
      </div>
      <div v-else class="mt-4">
        <slot name="content" />
      </div>

      <div v-if="hasSlotActions" class="mt-4 flex flex-row-reverse space-x-reverse space-x-3 print:hidden">
        <slot name="actions" />
      </div>

      <div v-else-if="dialogType === 'abort'" class="mt-4 text-right">
        <component-button label="Abbrechen" @click="close" />
      </div>

      <div v-else-if="dialogType === 'information'" class="mt-4 flex flex-row-reverse space-x-reverse space-x-3">
        <component-button
          class="p4umc-primary"
          :label="primaryLabel"
          :disabled="primaryButtonDisabled"
          @click="okButtonClicked"
        />
      </div>

      <div v-else-if="dialogType === 'multiActions'" class="mt-4 flex flex-row-reverse space-x-reverse space-x-3">
        <component-button
          class="p4umc-primary"
          :label="primaryLabel"
          :disabled="primaryButtonDisabled"
          @click="primaryButtonClicked"
        />
        <component-button :label="secondaryLabel" @click="secondaryButtonClicked" />
        <component-button v-if="cancelButton" class="p4umc-primary p4umc-flat" label="Abbrechen" @click="close" />
      </div>

      <div v-else-if="dialogType === 'emptyActions'" />

      <div v-else class="mt-4 flex flex-row-reverse space-x-reverse space-x-3">
        <component-button
          class="p4umc-primary"
          :label="primaryLabel"
          :disabled="primaryButtonDisabled"
          @click="primaryButtonClicked"
        />
        <component-button label="Abbrechen" @click="cancelButtonClicked" />
      </div>
    </div>
  </dialog>
</template>

<script>
  import {inject, computed, nextTick, ref, onUpdated} from "vue";

  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentSpinner from "@components/Spinner.vue";

  export default {
    name: "ComponentDialog",

    components: {
      ComponentSpinner,
      ComponentButton,
    },

    props: {
      dialogTitle: {
        type: String,
        default: "",
      },
      dialogContent: {
        type: String,
        default: "",
      },
      dialogType: {
        type: String,
        default: "",
      },
      primaryLabel: {
        type: String,
        default: "OK",
      },
      secondaryLabel: {
        type: String,
        default: "",
      },
      cancelButton: {
        type: Boolean,
        default: false,
      },
      dialogWidth: {
        type: String,
        default: "small",
      },
      dialogActive: {
        type: Boolean,
        default: false,
      },
      primaryButtonDisabled: {
        type: Boolean,
        default: false,
      },
      isDropdownInsideDialog: {
        type: Boolean,
        default: true,
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
    },

    emits: [
      "dialogOpened",
      "dialogClosed",
      "primaryButtonClicked",
      "secondaryButtonClicked",
      "cancelButtonClicked",
      "okButtonClicked",
    ],

    setup(props, {emit, slots}) {
      const privacy = inject("$privacy");

      const dialogRef = ref(null);
      const active = ref(false);
      const scrollDetection = ref(null);

      const overflowClass = computed(() =>
        props.isDropdownInsideDialog ? "overflow-y-auto overscroll-contain" : "!overflow-visible",
      );

      const widthGreaterThanSmall = computed(() => {
        return {
          "sm:w-96": props.dialogWidth === "small",
          "sm:w-164": props.dialogWidth === "medium",
          "sm:w-256": props.dialogWidth === "large",
        };
      });
      const hasSlotContent = computed(() => !!slots.content);
      const hasSlotActions = computed(() => !!slots.actions);

      const handleCancel = () => {
        if (props.dialogType === "information") {
          close();
        }
      };

      onUpdated(() => {
        if (dialogRef.value !== false) {
          privacy.decryptStaticContent(dialogRef.value);
        }
      });

      const open = () => {
        active.value = true;

        nextTick(() => {
          if (!!dialogRef.value === false) {
            return;
          }

          dialogRef.value.showModal();
          addBackdropEventHandler();
        });

        emit("dialogOpened");
      };

      const close = () => {
        if (active.value) {
          active.value = false;

          if (!!dialogRef.value === false) {
            return;
          }

          dialogRef.value.close();
          emit("dialogClosed");
          removeBackdropEventHandler();
        }
      };

      const primaryButtonClicked = () => {
        emit("primaryButtonClicked");
        close();
      };

      const secondaryButtonClicked = () => {
        emit("secondaryButtonClicked");
        close();
      };

      const cancelButtonClicked = () => {
        emit("cancelButtonClicked");
        close();
      };

      const okButtonClicked = () => {
        emit("okButtonClicked");
        close();
      };

      const addBackdropEventHandler = () => {
        if (props.dialogType === "information") {
          dialogRef.value.addEventListener("click", backdropHandler);
        }
      };

      const removeBackdropEventHandler = () => {
        if (props.dialogType === "information") {
          dialogRef.value.removeEventListener("click", backdropHandler);
        }
      };

      const backdropHandler = (event) => {
        const rect = dialogRef.value.getBoundingClientRect();
        const isInDialog =
          rect.top <= event.clientY &&
          event.clientY <= rect.top + rect.height &&
          rect.left <= event.clientX &&
          event.clientX <= rect.left + rect.width;

        if (!isInDialog) {
          close();
        }
      };

      return {
        overflowClass,
        widthGreaterThanSmall,
        hasSlotContent,
        hasSlotActions,
        active,
        scrollDetection,
        dialogRef,
        handleCancel,
        open,
        close,
        primaryButtonClicked,
        secondaryButtonClicked,
        cancelButtonClicked,
        okButtonClicked,
      };
    },
  };
</script>
