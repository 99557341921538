<template>
  <div class="flex-grow flex flex-col">
    <section class="mb-6 flex flex-row justify-center items-center space-x-2 gap-1 flex-wrap">
      <slot name="title">
        <h1 class="text-3xl md:text-4xl text-center">{{ title }}</h1>
        <div v-if="optional">
          <component-category-badge color="info" class="!rounded-full !text-lg">optional</component-category-badge>
        </div>
      </slot>
    </section>

    <section class="flex flex-col gap-4 mb-10">
      <slot />
    </section>

    <section class="flex flex-col gap-3" :class="{'sm:flex-row': navigationHorizontal}">
      <slot name="navigation">
        <component-button
          v-if="forwardButton"
          :disabled="!forwardButtonEnabled"
          class="p4umc-primary"
          :class="{'sm:order-2 flex-1': navigationHorizontal}"
          @click="forwardButtonClicked"
        >
          Weiter
        </component-button>

        <component-button
          v-if="backButton"
          :class="{'sm:order-1 flex-1': navigationHorizontal}"
          @click="performBackward"
        >
          Zurück
        </component-button>
      </slot>
    </section>

    <section v-if="$slots['bottom']" class="mt-10">
      <slot name="bottom" />
    </section>

    <component-dialog
      v-if="confirmDialogTitle && $slots['confirm-dialog-content']"
      ref="confirmDialog"
      primary-label="Weiter"
      :dialog-title="confirmDialogTitle"
      @primary-button-clicked="performForward"
    >
      <template #content><slot name="confirm-dialog-content" /></template>
    </component-dialog>
  </div>
</template>

<script>
  import {inject, ref} from "vue";

  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentCategoryBadge from "@components/Badges/CategoryBadge.vue";

  export default {
    name: "PatientinputComponentStep",

    components: {ComponentCategoryBadge, ComponentDialog, ComponentButton},

    props: {
      title: {
        type: String,
        default: null,
      },
      optional: {
        type: Boolean,
        default: false,
      },
      forwardButton: {
        type: Boolean,
        default: true,
      },
      backButton: {
        type: Boolean,
        default: true,
      },
      forwardButtonEnabled: {
        type: Boolean,
        default: true,
      },
      showConfirmDialog: {
        type: Boolean,
        default: false,
      },
      confirmDialogTitle: {
        type: String,
        default: null,
      },
      navigationHorizontal: {
        type: Boolean,
        default: true,
      },
    },

    setup(props, ctx) {
      const navigate = inject("navigate");

      const confirmDialog = ref(null);

      const forwardButtonClicked = () => {
        if (props.showConfirmDialog && props.confirmDialogTitle && ctx.slots["confirm-dialog-content"]) {
          confirmDialog.value.open();
        } else {
          performForward();
        }
      };

      const performBackward = () => {
        navigate("-");
      };

      const performForward = () => {
        navigate("+");
      };

      return {confirmDialog, forwardButtonClicked, performBackward, performForward};
    },
  };
</script>
