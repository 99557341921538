<template>
  <span
    class="inline-flex items-center rounded-md px-1.5 py-0.5 text-xs font-medium"
    :class="{
      'bg-orange-100 text-orange-600': color === 'yellow' || color === 'warning',
      'bg-mcred-100 text-mcred-600': color === 'red' || color === 'error',
      'bg-mcblue-100 text-mcblue-600': color === 'blue' || color === 'info',
      'bg-mcgreen-100 text-mcgreen-600': color === 'green' || color === 'success',
      'bg-gray-100 text-gray-600': color === 'gray' || color === null,
    }"
  >
    {{ label }}
    <slot />
  </span>
</template>
<script>
  export default {
    name: "ComponentCategoryBadge",
    props: {
      label: {
        type: [String, Number],
        default: null,
      },
      color: {
        type: String,
        default: null,
      },
    },
  };
</script>
