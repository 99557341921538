<template>
  <component-dashboard v-bind="$page.props" />
  <component-transfer-import-dialog :transfer="transfer" />
</template>

<script>
  import {defineComponent} from "vue";

  import ComponentDashboard from "@pages/Dashboard/Components/Dashboard.vue";
  import ComponentTransferImportDialog from "@components/Dialogs/TransferImportDialog.vue";

  export default defineComponent({
    name: "PagesDeeplinkTransfer",

    components: {
      ComponentTransferImportDialog,
      ComponentDashboard,
    },

    props: {
      transfer: {
        type: Object,
        required: true,
      },
    },
  });
</script>
