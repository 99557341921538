<template>
  <form @submit.prevent="save">
    <component-card
      title="Patientennotiz"
      subtitle="Sie haben überall im MediCheck Zugriff auf diese Notizen zu Ihrem Patienten."
      class="rounded-lg border-2 border-gray-200"
    >
      <component-editor v-model="form.note" editor-class="max-h-[75vh]" label="Patientennotiz" :encrypted="true" />

      <template #actions>
        <component-button class="p4umc-primary" label="Speichern" :disabled="form.processing" @click="save()" />
        <component-unsaved-changes :form="form" />
      </template>
    </component-card>
  </form>
</template>

<script>
  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentCard from "@components/Cards/Card.vue";
  import {useForm} from "@inertiajs/vue3";
  import ComponentUnsavedChanges from "@components/Alerts/UnsavedChanges.vue";
  import ComponentEditor from "@components/Editor/Editor.vue";

  export default {
    name: "PatientsPatientNoteData",
    components: {ComponentEditor, ComponentUnsavedChanges, ComponentCard, ComponentButton},
    props: {
      patient: {
        type: Object,
        required: true,
      },
    },

    setup(props) {
      const form = useForm({
        note: props?.patient?.note,
      });

      function save() {
        form.patch(route("patients.update", {patient: props.patient.id}), {preserveScroll: true});
      }

      return {
        form,
        save,
      };
    },
  };
</script>
