<template>
  <nav v-if="links.length > 3" class="flex items-center justify-between">
    <div class="-mt-px flex w-0 flex-1">
      <Component
        :is="links[0].url ? 'InertiaLink' : 'span'"
        :href="links[0].url"
        class="inline-flex items-center border-t-2 pr-4 pl-0 md:pl-4 py-4 text-sm font-medium hover:no-underline"
        :class="{
          'border-transparent text-gray-500': !links[0].active,
          'border-mcred text-mc-red': links[0].active,
          'hover:border-gray-300 hover:text-gray-700': links[0].url,
        }">
        <component-tooltip tooltip="vorherige Seite" :force-inline="true">
          <component-icon>arrow_back_ios</component-icon>
        </component-tooltip>

        <div class="md:hidden px-2">vorherige Seite</div>
      </Component>
    </div>
    <div class="hidden md:-mt-px md:flex">
      <Component
        :is="link.url ? 'InertiaLink' : 'span'"
        v-for="link in links.slice(1, -1)"
        :key="'pagination-' + link.label"
        :href="link.url"
        class="inline-flex items-center border-t-2 p-4 text-sm font-medium hover:no-underline"
        :class="{
          'border-transparent text-gray-500': !link.active,
          'border-mcred text-mc-red': link.active,
          'hover:border-gray-300 hover:text-gray-700': link.url,
        }"
        v-html="link.label" />
    </div>
    <div class="-mt-px flex w-0 flex-1 justify-end">
      <Component
        :is="links[links.length - 1].url ? 'InertiaLink' : 'span'"
        :href="links[links.length - 1].url"
        class="inline-flex items-center border-t-2 pl-4 pr-0 md:pr-4 py-4 text-sm font-medium hover:no-underline"
        :class="{
          'border-transparent text-gray-500': !links[links.length - 1].active,
          'border-mcred text-mc-red': links[links.length - 1].active,
          'hover:border-gray-300 hover:text-gray-700': links[links.length - 1].url,
        }">
        <div class="md:hidden px-2">nächste Seite</div>
        <component-tooltip tooltip="nächste Seite" :force-inline="true">
          <component-icon>arrow_forward_ios</component-icon>
        </component-tooltip>
      </Component>
    </div>
  </nav>
</template>
<script>
  import {defineComponent} from "vue";
  import {Link as InertiaLink} from "@inertiajs/vue3";

  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";

  export default defineComponent({
    name: "ComponentPagination",

    components: {InertiaLink, ComponentIcon, ComponentTooltip},

    props: {
      links: {
        type: Array,
        default: () => [],
      },
    },
  });
</script>
