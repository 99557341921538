<template>
  <component-card
    title="Patientenakte löschen"
    subtitle="Sie können die Patientenakte inkl. aller enthaltenen Daten löschen."
    class="rounded-lg border-2 border-gray-200"
  >
    <template #actions>
      <component-button label="Löschen" :disabled="form.processing" @click="confirmRemove()" />
    </template>
    <component-confirmation-dialog
      ref="refConfirmationDialog"
      title="Patienten löschen?"
      content="Der Patient wird mit allen gespeicherten Daten (Medikationsanalysen etc.) gelöscht."
      @confirmed="remove()"
    />
  </component-card>
</template>

<script>
  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentCard from "@components/Cards/Card.vue";
  import {useForm} from "@inertiajs/vue3";
  import ComponentConfirmationDialog from "@components/Dialogs/ConfirmationDialog.vue";
  import {ref} from "vue";

  export default {
    name: "PatientsPatientDelete",
    components: {
      ComponentConfirmationDialog,
      ComponentCard,
      ComponentButton,
    },
    props: {
      patient: Object,
    },

    setup(props) {
      const refConfirmationDialog = ref(null);

      const form = useForm({});

      function confirmRemove() {
        refConfirmationDialog.value.open();
      }

      function remove() {
        form.delete(route("patients.destroy", {patient: props.patient.id}));
      }

      return {
        form,
        refConfirmationDialog,
        confirmRemove,
        remove,
      };
    },
  };
</script>
