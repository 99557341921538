<template>
  <div>
    <h5 v-if="!layer" class="text-2xl">Offene Aufgaben</h5>
    <ul v-if="pending.length" ref="refDropArea" class="mt-4 space-y-2">
      <li v-for="todo in pending" :key="todo.id" class="flex items-baseline">
        <component-tooltip
          tooltip="Zeile verschieben"
          :force-hidden="isDragging"
          custom-class-label="flex items-center"
        >
          <component-icon class="text-gray-900 text-xl mt-0.5" drag-handle>drag_handle</component-icon>
        </component-tooltip>
        <component-todo-list-todo-item class="flex-1" :layer="layer" :todo="todo" :patient-id="patient.id" />
      </li>
    </ul>
    <div v-else class="mt-1 flex items-center space-x-2 text-gray-500">
      <component-icon>thumb_up_off_alt</component-icon>
      <span>Keine offenen Aufgaben</span>
    </div>

    <form @submit.prevent="save()">
      <div class="mt-4 flex items-center space-x-4">
        <component-input v-model="form.label" placeholder="Neue Aufgabe hinzufügen" :hide-clear="true" />
        <div>
          <component-spinner v-if="form.processing" class="w-8 h-8" />
          <component-icon-button v-else icon="add" class="p4umc-primary" />
        </div>
      </div>
    </form>

    <template v-if="completed.length">
      <button
        class="w-full mt-8 flex items-center justify-between font-semibold text-gray-500 hover:text-gray-900"
        @click="expanded = !expanded"
      >
        <span class="flex items-center">
          <component-icon class="text-base" :class="{'rotate-180': expanded}">arrow_drop_down</component-icon>
          <span class="ml-1 text-sm">erledigte Aufgaben</span>
        </span>
        <span ref="refCounterBadge">
          <component-category-badge :label="completed.length" color="gray" />
        </span>
      </button>

      <ul v-if="expanded" class="mt-4 space-y-2">
        <li v-for="todo in completed" :key="todo.id">
          <component-todo-list-todo-item :layer="layer" :todo="todo" :patient-id="patient.id" />
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
  import ComponentInput from "@components/Inputs/Input.vue";
  import ComponentIconButton from "@components/Buttons/IconButton.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentTodoListTodoItem from "@components/Lists/TodoList/TodoItem.vue";
  import {computed, onMounted, ref, watch} from "vue";
  import {useForm} from "@inertiajs/vue3";
  import ComponentSpinner from "@components/Spinner.vue";
  import {nextTick} from "vue";
  import ComponentCategoryBadge from "@components/Badges/CategoryBadge.vue";
  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";
  import {dragAndDrop, useDragAndDrop} from "@formkit/drag-and-drop/vue";
  import {handleDragstart, handleTouchstart, handleEnd, swap, remapNodes, multiHandleEnd} from "@formkit/drag-and-drop";

  export default {
    name: "ComponentTodoList",
    components: {
      ComponentTooltip,
      ComponentCategoryBadge,
      ComponentSpinner,
      ComponentTodoListTodoItem,
      ComponentIcon,
      ComponentIconButton,
      ComponentInput,
    },
    props: {
      patient: {
        type: Object,
        required: true,
      },
      /**
       * layer: embedded in layer
       */
      layer: {
        type: Boolean,
        default: false,
      },
    },
    setup(props) {
      const expanded = ref(false);
      const refCounterBadge = ref(null);

      const completed = computed(() => {
        return (props.patient?.todos ?? []).filter((todo) => todo.completed_at !== null).reverse();
      });

      const form = useForm({
        label: null,
      });

      const pendingItems = ref((props.patient?.todos ?? []).filter((todo) => todo.completed_at === null));

      const isDragging = ref(false);

      const sorting = useForm({});

      const [refDropArea, pending] = useDragAndDrop(pendingItems, {
        dragHandle: "[drag-handle]",
        dropZoneClass: "ring-2 ring-mcred-300",
        plugins: [swap()],
        handleDragstart: (data) => {
          isDragging.value = true;
          handleDragstart(data);
        },
        handleTouchstart: (data) => {
          isDragging.value = true;
          handleTouchstart(data);
        },
      });

      watch(
        () => props.patient.todos,
        (newValue) => {
          pendingItems.value = (newValue ?? []).filter((todo) => todo.completed_at === null);
        },
      );

      watch(
        () => pending.value,
        (newValue, oldValue) => {
          if (newValue.length === oldValue.length) {
            // same amount of items => MIGHT be a sorting event
            if (newValue.map((item) => item.id).join(",") !== oldValue.map((item) => item.id).join(",")) {
              // the order of the ids have changed => MUST be a sorting event
              sorting
                .transform((data) => ({
                  ...data,
                  ...{order: newValue.map((item) => item.id)},
                }))
                .post(route("todos.sort", {patient: props.patient.id}), {
                  preserveScroll: true,
                });
            }
          }
        },
      );

      watch(
        () => completed.value,
        (newValue, oldValue) => {
          if (newValue.length === 1 && oldValue.length === 0) {
            if (expanded.value === false) {
              expanded.value = true;
            }
            nextTick(() => {
              refCounterBadge.value?.classList?.add("animate-bounce", "[animation-iteration-count:1!important]"); //  []");
            });
          } else {
            refCounterBadge.value?.classList?.remove("animate-bounce", "[animation-iteration-count:1!important]"); //  [animation-iteration-count:1!important]");
          }
        },
      );

      function save() {
        form.post(route("todos.store", {patient: props.patient.id}), {
          preserveScroll: true,
          onSuccess: () => {
            form.reset();
          },
        });
      }

      return {expanded, completed, pending, form, save, refCounterBadge, refDropArea, isDragging};
    },
  };
</script>
