<template>
  <component-dialog
    ref="scannerDialog"
    :dialog-title="dialogTitle"
    :dialog-type="dialogType"
    :primary-button-disabled="primaryButtonDisabled"
    @ok-button-clicked="$emit('okButtonClicked')"
  >
    <template #content>
      <div v-if="errorMsg">
        <component-alert type="error">{{ errorMsg }}</component-alert>
      </div>

      <div v-else-if="!cameraReady" class="flex justify-center items-center" :class="loaderClass">
        <component-spinner class="w-12" />
      </div>

      <div v-show="cameraReady && !errorMsg" class="flex flex-col gap-3">
        <slot />

        <component-barcode-scanner
          :class="scannerClass"
          :barcode-formats="formats"
          :paused="paused"
          @camera-on="onReady"
          @detect="$emit('detect', $event)"
          @error="onError"
        >
          <div v-show="paused" class="h-full w-full flex flex-col justify-center items-center bg-white/75 gap-3 p-3">
            <slot name="paused">
              <component-spinner class="w-12" />
            </slot>
          </div>
        </component-barcode-scanner>
      </div>
    </template>
  </component-dialog>
</template>

<script>
  import {onBeforeUnmount, ref} from "vue";

  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentSpinner from "@components/Spinner.vue";
  import ComponentAlert from "@components/Alerts/Alert.vue";
  import ComponentBarcodeScanner from "@components/BarcodeScanner/BarcodeScanner.vue";

  /**
   * @typedef {import("vue").PropType} PropType
   * @typedef {import("vue").Ref} Ref
   */

  export default {
    name: "PatientinputComponentScannerDialog",

    components: {
      ComponentBarcodeScanner,
      ComponentAlert,
      ComponentSpinner,
      ComponentDialog,
    },

    props: {
      /** @type {PropType<String>} */
      dialogTitle: {
        required: true,
        type: String,
      },
      /** @type {PropType<String>} */
      dialogType: {
        type: String,
        default: "",
      },
      formats: {
        required: true,
        type: Array,
      },
      /** @type {PropType<String>} */
      loaderClass: {
        type: String,
        default: "",
      },
      /** @type {PropType<String>} */
      scannerClass: {
        type: String,
        default: "",
      },
      /** @type {PropType<Boolean>} */
      primaryButtonDisabled: {
        type: Boolean,
        default: false,
      },
      /** @type {PropType<Boolean>} */
      paused: {
        type: Boolean,
        default: false,
      },
    },

    emits: ["detect", "okButtonClicked"],

    setup() {
      const /** @type {Ref<HTMLElement>} */ scannerDialog = ref(null);

      const /** @type {Ref<Boolean>} */ cameraReady = ref(false);
      const /** @type {Ref<String>} */ errorMsg = ref(null);

      let tempTime = 0;
      const setupTime = ref(0);

      const open = () => {
        cameraReady.value = false;
        errorMsg.value = null;
        scannerDialog.value.open();
        tempTime = Date.now();
      };

      const close = () => {
        scannerDialog.value.close();
      };

      const onReady = () => {
        cameraReady.value = true;
        setupTime.value = Date.now() - tempTime;
      };

      const onError = (error) => {
        errorMsg.value = error.message;
      };

      onBeforeUnmount(() => {
        close();
      });

      return {
        scannerDialog,
        cameraReady,
        errorMsg,
        open,
        close,
        onReady,
        onError,
        setupTime,
      };
    },
  };
</script>
