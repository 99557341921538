<template>
  <div class="mt-5">
    <div class="flex flex-col gap-3">
      <h3 class="text-lg leading-6 font-medium text-gray-700">BMP-Datenmatrix scannen</h3>

      <component-alert v-if="error.type && error.text" :type="error.type" class="!mb-0">
        {{ error.text }}
      </component-alert>

      <p>Bitte jetzt die Datenmatrix mit einem apothekenüblichen Barcodescanner einlesen.</p>

      <div class="flex justify-center">
        <img src="@assets/icons/barcodescanner.svg" alt="Barcodescanner" />
      </div>
    </div>

    <input ref="inputRef" class="w-0 h-0 focus:outline-none focus:ring-0 border-none" @input="processTextInput" />
  </div>
</template>

<script>
  import {onMounted, onUpdated, ref} from "vue";

  import ComponentAlert from "@components/Alerts/Alert.vue";

  export default {
    name: "ComponentBmpImportDialogBarcodeScannerTab",

    components: {ComponentAlert},

    props: {
      /** @type {import("vue").Prop<{type, text}>} */
      error: {
        type: Object,
        required: true,
      },
    },

    emits: ["input"],

    setup(props, {emit}) {
      const /** @type {import("vue").Ref<HTMLInputElement>} */ inputRef = ref(null);

      const /** @param {InputEvent} event **/ processTextInput = (event) => {
          emit("input", event.target.value);
        };

      const setFocus = () => {
        setTimeout(() => {
          if (inputRef.value) {
            inputRef.value.focus();
          }
        }, 50);
      };

      onMounted(setFocus);
      onUpdated(setFocus);

      return {inputRef, processTextInput};
    },
  };
</script>

<style scoped></style>
