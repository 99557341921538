<template>
  <patientinput-component-step title="Vielen Dank!" :back-button="false" :forward-button="false">
    <component-alert type="succes">Ihre Angaben wurden erfolgreich übermittelt.</component-alert>

    <div>
      <p>
        Bitte halten Sie folgende weitere Daten
        <span v-if="form.masterdata.patient.gender === 'female'">zur Patientin</span>
        <span v-else>zum Patienten</span>
        zum Medikationsgespräch bereit:
      </p>

      <ul class="list-disc pl-6">
        <li>
          Aktueller Medikationsplan

          <ul class="list-[circle] pl-6">
            <li>Dauermedikation</li>
            <li>Akutmedikation</li>
            <li>Bedarfsmedikation</li>
          </ul>
        </li>
        <li>Übersicht über Arzneimittel in Eigenverantwortung</li>
        <li>Ggf. Arztbrief/Krankenhausbericht</li>
        <li>Ggf. Pflegebericht</li>
      </ul>
    </div>
  </patientinput-component-step>
</template>

<script>
  import PatientinputComponentStep from "@pages/Patientinput/Components/Step.vue";
  import ComponentAlert from "@components/Alerts/Alert.vue";

  export default {
    name: "PatientinputComponentStepSuccess",

    components: {
      PatientinputComponentStep,
      ComponentAlert,
    },

    props: {
      form: {
        type: Object,
        required: true,
      },
    },
  };
</script>
