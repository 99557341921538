<template>
  <component-dialog
    ref="refIndicationMenu"
    dialog-width="medium"
    dialog-title="Grund / Indikationen"
    :is-dropdown-inside-dialog="false"
    :is-loading="isLoading"
  >
    <template #info-icon>
      <component-info-icon>
        <template #content>
          <p>
            Wählen Sie aus typischen, für dieses FAM zugelassenen („in-label“-) Indikationen oder wählen Sie
            „Weitere/andere ICD-10-Code“
          </p>
        </template>
      </component-info-icon>
    </template>

    <template #content>
      <component-alert v-if="unloadableIndications && unloadableIndications.length > 0" type="warning">
        <h4 class="font-bold">
          In diesem Bereich befinden sich Indikationen, die in der aktualisierten Datenbank ersetzt (oder
          geschlechtsspezifisch zugeordnet) wurden.
        </h4>
        <p class="mt-2">
          Bitte tauschen Sie folgende Indikationen durch geeignete neue aus, damit sie im Check erkannt und
          berücksichtigt werden:
        </p>
        <ul class="mt-2 space-y-1">
          <li v-for="(item, index) in unloadableIndications" :key="index">
            <em>{{ item.name }}</em>
          </li>
        </ul>
      </component-alert>

      <medication-component-indication-checkbox
        :indications-options="indicationList"
        :new-indication-list="newIndicationList"
        :indications-unknown="indicationUnknown"
        :hash-id="hashId"
        :is-loading="isLoading"
        @form-change="handleFormChange"
      />

      <div class="flex py-3 border-b border-t">
        <component-checkbox v-model="indicationUnknown">Unbekannt</component-checkbox>
      </div>

      <div class="mt-4 text-sm text-gray-500 mb-2">Weitere / andere / ICD-10 Code:</div>

      <medication-component-indication-checkbox
        :indications-options="customIndicationsList"
        :new-indication-list="newIndicationList"
        :indications-unknown="indicationUnknown"
        :hash-id="hashId"
        :is-loading="isLoading"
        @form-change="handleFormChange"
      />

      <record-component-min-c-search
        class="pb-6"
        :reset-on-select="true"
        filter="indications"
        placeholder="Suchbegriff eingeben..."
        @input="addIndication"
      />
    </template>

    <template #actions>
      <component-button class="p4umc-primary" label="Speichern" :disabled="isLoading" @click="saveIndications" />

      <component-button label="Abbrechen" :disabled="isLoading" @click="resetMedicationIndication" />
    </template>
  </component-dialog>
</template>

<script>
  import {ref, watch} from "vue";
  import {router, useForm, usePage} from "@inertiajs/vue3";
  import {cloneDeep, each} from "lodash";

  import {coreRouter} from "@utils/coreRouter/coreRouter.js";

  import ComponentAlert from "@components/Alerts/Alert.vue";
  import ComponentCheckbox from "@components/Checkboxes/Checkbox.vue";
  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentInfoIcon from "@components/Icons/InfoIcon.vue";

  import {updateCompleteForm, updateForm} from "@pages/Records/Components/Sections/MedicationBar/Utils/formHandler.js";

  import RecordComponentMinCSearch from "@pages/Records/Components/MinCSearch.vue";
  import MedicationComponentIndicationCheckbox from "@pages/Records/Components/Sections/MedicationBar/Components/Indications/IndicationCheckbox.vue";
  import ComponentButton from "@components/Buttons/Button.vue";

  export default {
    name: "MedicationComponentIndicationDialog",

    components: {
      ComponentButton,
      ComponentAlert,
      ComponentCheckbox,
      ComponentDialog,
      ComponentInfoIcon,
      MedicationComponentIndicationCheckbox,
      RecordComponentMinCSearch,
    },

    props: {
      isDetailDialog: {
        type: Boolean,
        default: false,
      },
    },

    emits: ["close", "formChange"],

    setup(props, {emit}) {
      const page = usePage();

      const refIndicationMenu = ref(null);

      const abDataKeyFam = ref(null);
      const customIndicationsList = ref([]);
      const hashId = ref("");
      const indicationList = ref([]);
      const indicationUnknown = ref(false);
      const isLoading = ref(true);
      const newIndicationList = ref(null);
      const unloadableIndications = ref([]);
      const medicationForm = useForm({
        value: {
          indications: null,
          indication_unknown: null,
        },
      });

      watch(
        () => medicationForm.value.indications,
        (newValue) => {
          if (newValue && newValue.length > 0 && indicationUnknown.value) {
            handleFormChange({key: "indication_unknown", newValue: 0});
          }

          newIndicationList.value = newValue;
          reloadIndicationLists();
        },
        {deep: true},
      );

      watch(
        () => medicationForm.value.indication_unknown,
        (newValue) => {
          indicationUnknown.value = !!newValue;
        },
      );

      watch(
        () => indicationUnknown.value,
        (newValue) => {
          if (newValue) {
            if (props.isDetailDialog) {
              emit("formChange", {key: "indications", newValue: []});
              emit("formChange", {key: "indication_unknown", newValue: 1});
            } else {
              handleFormChange({key: "indications", newValue: []});
              handleFormChange({key: "indication_unknown", newValue: 1});
            }
          } else {
            if (props.isDetailDialog) {
              emit("formChange", {key: "indication_unknown", newValue: 0});
            } else {
              handleFormChange({key: "indication_unknown", newValue: 0});
            }
          }
        },
      );

      const open = ({medication, medicationKeyFam, medicationHashId}) => {
        medicationForm.value = medication;
        indicationUnknown.value = !!medicationForm.value.indication_unknown;
        newIndicationList.value = medicationForm.value.indications;

        abDataKeyFam.value = medicationKeyFam;
        hashId.value = medicationHashId;

        refIndicationMenu.value.open();

        isLoading.value = true;

        loadIndicationsFromCore();
      };

      const close = () => {
        refIndicationMenu.value.close();
      };

      const addIndication = (newIndication) => {
        const formattedIndication = {
          id: null,
          name: newIndication.Name,
          abdata_key_miv: newIndication.Key_MIV,
          name_icdc: newIndication.name_icdc || null,
          abdata_key_icdc: newIndication.abdata_key_icdc || null,
          gender: newIndication.Geschlecht,
          counter: newIndication.Zaehler,
          indication_leads_to_disease: newIndication.indication_leads_to_disease,
        };

        const checkDouble = newIndicationList.value.some((indication) => indication.name === formattedIndication.name);

        if (!checkDouble) {
          newIndicationList.value.push(formattedIndication);

          if (props.isDetailDialog) {
            emit("formChange", {key: "indications", newValue: newIndicationList.value});
          } else {
            handleFormChange({key: "indications", newValue: newIndicationList.value});
          }
        }

        reloadIndicationLists();
      };

      const reloadIndicationLists = () => {
        customIndicationsList.value = [];
        indicationList.value = [];

        loadIndicationsFromCore();
      };

      const loadIndicationsFromCore = () => {
        isLoading.value = true;

        if (abDataKeyFam.value) {
          coreRouter.loadMinDataViaKeyFam(abDataKeyFam.value, {
            onSuccess: (response) => {
              indicationList.value = [];

              each(response.data, (indication) => {
                indicationList.value.push({
                  id: null,
                  name: indication.Name,
                  abdata_key_miv: indication.Key_MIV,
                  name_icdc: indication.name_icdc || null,
                  abdata_key_icdc: indication.abdata_key_icdc || null,
                  gender: indication.Geschlecht,
                  counter: indication.Zaehler,
                  indication_leads_to_disease: indication.indication_leads_to_disease,
                });
              });

              filterLists();
            },
            onFinish: () => {
              isLoading.value = false;
            },
          });
        } else {
          isLoading.value = false;
        }
      };

      const filterLists = () => {
        newIndicationList.value.map((formIndication) => {
          if (formIndication.name) {
            coreRouter.loadIndications(`?filter=indications&q[key_miv]=${formIndication.abdata_key_miv}`, {
              onSuccess: (response) => {
                if (response.data.length > 0) {
                  if (!formIndication.gender) {
                    const mainIndication = response.data.find(
                      (coreIndication) => coreIndication.Vorzugsbezeichnung === 1,
                    );

                    formIndication.gender = mainIndication.Geschlecht;
                  }
                } else {
                  unloadableIndications.value = addIfNotInList(unloadableIndications, formIndication);
                }
              },
            });
          } else {
            unloadableIndications.value = addIfNotInList(unloadableIndications, formIndication);
          }

          const matchWithIndicationList = indicationList.value.find(
            (indication) => indication.name === formIndication.name,
          );

          if (matchWithIndicationList) {
            Object.assign(matchWithIndicationList, formIndication);
          } else {
            customIndicationsList.value = addIfNotInList(customIndicationsList, formIndication);
          }
        });
      };

      const addIfNotInList = (list, newValue) => {
        const checkDouble = list.value.some((item) => item.name === newValue.name);

        if (!checkDouble) list.value.push(newValue);

        return list.value;
      };

      const resetMedicationIndication = () => {
        if (props.isDetailDialog) {
          emit("close");
          close();
        } else {
          const medication = cloneDeep(
            page.props.record.medications.find((oldMedication) => oldMedication.id === medicationForm.value.id),
          );

          medicationForm.value.indications = medication.indications;
          medicationForm.value.indication_unknown = medication.indication_unknown;

          close();
        }
      };

      const saveIndications = () => {
        if (props.isDetailDialog) {
          close();
        } else {
          isLoading.value = true;
          const indicationList = medicationForm.value.indications;

          Promise.all(
            each(indicationList, (indicationItem) => {
              return new Promise(() => {
                if (indicationItem.id === null) {
                  const diseaseObject = getIndicationAsDiseasesObject(indicationItem);

                  const checkDiseases = page.props.record.diseases.some(
                    (diseaseItem) =>
                      diseaseItem.abdata_key_miv === diseaseObject.abdata_key_miv &&
                      diseaseItem.name === diseaseObject.name,
                  );

                  if (!checkDiseases) {
                    router.post(
                      route("diseases.store", {patient: page.props.patient.id, record: page.props.record.id}),
                      diseaseObject,
                      {
                        preserveScroll: true,
                      },
                    );
                  }
                }
              });
            }),
          ).then(() => {
            patchMedication();
          });
        }
      };

      const getIndicationAsDiseasesObject = (indication) => {
        if (indication.indication_leads_to_disease) {
          return {
            abdata_key_miv: indication.indication_leads_to_disease.Key_MIV,
            disease: indication.indication_leads_to_disease.Name,
            abdata_key_icdc: indication.abdata_key_icdc || null,
            name_icdc: indication.name_icdc || null,
            since: "",
          };
        } else {
          return {
            abdata_key_miv: indication.abdata_key_miv || indication.Key_MIV || null,
            disease: indication.name || indication.Name || null,
            abdata_key_icdc:
              indication.abdata_key_icdc ||
              (indication.icdcs && indication.icdcs.length ? indication.icdcs[0].Key_ICDC : null),
            name_icdc:
              indication.name_icdc || (indication.icdcs && indication.icdcs.length ? indication.icdcs[0].Name : null),
            since: "",
          };
        }
      };

      const patchMedication = () => {
        medicationForm
          .transform((data) => {
            return data.value;
          })
          .put(
            route("medications.update", {
              patient: page.props.patient.id,
              record: page.props.record.id,
              medication: medicationForm.value.id,
            }),
            {
              preserveScroll: true,
              only: ["record", "flash"],
              onFinish: () => {
                isLoading.value = false;
                close();
              },
            },
          );
      };

      const handleFormChange = ({key, newValue}) => {
        if (props.isDetailDialog) {
          emit("formChange", {key: key, newValue: newValue});
        } else {
          if (key) {
            updateForm(medicationForm.value, key, newValue);
          } else {
            updateCompleteForm(medicationForm, newValue);
          }
        }
      };

      return {
        /** ref */
        refIndicationMenu,

        /** const */
        customIndicationsList,
        hashId,
        indicationList,
        indicationUnknown,
        isLoading,
        newIndicationList,
        unloadableIndications,

        /** function */
        open,
        close,
        addIndication,
        resetMedicationIndication,
        saveIndications,
        handleFormChange,
      };
    },
  };
</script>
