<template>
  <patientinput-component-step :forward-button="false" :back-button="false" :navigation-horizontal="false">
    <template #title>
      <img src="/resources/assets/images/mcp_logo_RGB_pos.svg" width="250" alt="MediCheck" class="mt-3" />
    </template>

    <h1 class="text-3xl md:text-4xl text-center font-medium">Herzlich Willkommen …</h1>

    <img src="@images/hero-image.jpg" alt="" class="rounded" />

    <p>
      … zur Vorerfassung für Ihre Medikationsberatung
      <span v-if="company">bei {{ company }}</span>
      (Dauer ca. 10 Minuten).
    </p>

    <div>
      <p>Idealerweise haben Sie Folgendes gleich bereitliegen:</p>

      <ul class="list-disc ml-5">
        <li>Falls vorhanden: Medikationsplan oder andere Anwendungspläne, Dosieranweisungen etc.</li>
        <li>
          Alle Medikamente, die Sie anwenden (verordnet, rezeptfrei, pflanzlich, äußerlich anzuwenden, Vitamine,
          Mineralstoffe, Nahrungsergänzungsmittel, etc.)
        </li>
      </ul>
    </div>

    <p>
      Verantwortlich für die Datenverarbeitung im Sinne der DSGVO ist
      <span v-if="company">{{ company }}.</span>
      <span v-else>Ihre Apotheke.</span>
    </p>

    <template #navigation>
      <component-button class="p4umc-primary" @click="$emit('navigate', '+')">Mit der Eingabe starten</component-button>
    </template>
  </patientinput-component-step>
</template>

<script>
  import {usePage} from "@inertiajs/vue3";
  import {computed} from "vue";

  import ComponentButton from "@components/Buttons/Button.vue";
  import PatientinputComponentStep from "@pages/Patientinput/Components/Step.vue";

  export default {
    name: "PatientinputComponentStepWelcome",

    components: {
      ComponentButton,
      PatientinputComponentStep,
    },

    props: {
      form: {
        type: Object,
        required: true,
      },
    },

    emits: ["navigate"],

    setup() {
      const page = usePage();

      const company = computed(() => page.props.company);

      return {company};
    },
  };
</script>
