<template>
  <div class="rounded-md border border-gray-300 bg-white px-6 py-4">
    <div class="flex border-b-1 border-gray-300 focus-within:border-mcred">
      <div class="w-10 flex items-end justify-center pb-2">
        <component-spinner v-if="isLoading" class="w-5 h-5" />

        <component-icon v-else class="text-mcred text-2xl cursor-pointer rotate-90" @click="setFocus">
          search
        </component-icon>
      </div>

      <div class="w-full">
        <input
          :id="fieldId"
          ref="refInput"
          v-model="searchTerm"
          v-click-outside="onClickOutside"
          autocomplete="off"
          type="text"
          class="pl-0 h-9 !text-base !border-0 focus:!border-0"
          :placeholder="placeholder"
          @focusout="handleFocusOut"
          @keydown.down="selectNext"
          @keydown.tab="onClickOutside"
        />
      </div>
    </div>

    <div v-if="showEmptySlot" class="mt-3">Zu Ihrer Eingabe konnte nichts gefunden werden.</div>

    <div v-else-if="isError" class="mt-3">Beim Suchen ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.</div>

    <div v-else-if="results.length > 0">
      <ul
        ref="refResultList"
        tabindex="-1"
        role="listbox"
        class="border-x border-b border-gray-300 bg-white px-6 py-1 max-h-60 overflow-y-auto rounded-b-md"
      >
        <li
          v-for="(item, index) in results"
          :id="`resultList${index}`"
          :key="index"
          role="option"
          tabindex="-1"
          class="z-10 cursor-pointer py-1 px-3 text-gray-900 hover:bg-gray-100 border-1 border-white focus:border-1 focus:border-mcred focus-visible:outline-none rounded"
          @click="selectItem(item)"
          @keydown.enter="selectItem(item)"
          @keydown.up="selectPrevious"
          @keydown.down="selectNext"
          @keydown.tab="onClickOutside"
        >
          <slot name="autocomplete-item" :item="item" :term="searchTerm" />
        </li>
      </ul>
    </div>

    <slot name="after-search" />
  </div>
</template>

<script>
  import {computed, reactive, ref, toRefs, watch} from "vue";
  import {debounce} from "lodash";

  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentSpinner from "@components/Spinner.vue";

  export default {
    name: "PatientinputComponentAutocomplete",

    components: {ComponentSpinner, ComponentIcon},

    props: {
      placeholder: {
        type: String,
        default: "",
      },
      fetchMethod: {
        type: Function,
        default: null,
      },
    },

    emits: ["selected"],

    setup(props, {emit}) {
      const minSearchTermLength = 4;

      const /** @type {import("vue").Ref<String>} */ fieldId = ref(crypto.randomUUID());

      const /** @type {import("vue").Ref<HTMLInputElement>} */ refInput = ref(null);
      const /** @type {import("vue").Ref<HTMLUListElement>} */ refResultList = ref(null);

      const state = reactive({
        /** @type {?String} */ searchTerm: null,
        /** @type {Array} */ results: [],
        /** @type {Boolean} */ isLoading: false,
        /** @type {Boolean} */ isError: false,
      });

      const hasSearchTerm = computed(() => state.searchTerm?.trim().length >= minSearchTermLength);

      const showEmptySlot = computed(() => hasSearchTerm.value && !state.isLoading && state.results.length === 0);

      watch(
        () => state.searchTerm,
        (newSearchTerm) => {
          state.isLoading = !!newSearchTerm;
        },
      );

      watch(
        () => state.searchTerm,
        debounce(() => {
          if (hasSearchTerm.value === false) {
            state.results = [];
            state.isLoading = false;
            return;
          }

          state.isLoading = true;
          state.isError = false;

          props
            .fetchMethod(state.searchTerm)
            .then((res) => {
              if (hasSearchTerm.value === false) {
                state.isLoading = false;
              } else if (res === false) {
                // a cancel token ended the former request and we keep waiting
                state.isLoading = true;
              } else if (typeof state.results == "object") {
                state.isLoading = false;
                state.results = res;
              }
            })
            .catch(() => {
              state.isLoading = false;
              state.isError = true;
              reset();
            });
        }, 1000),
      );

      /** @param {FocusEvent} event */
      const handleFocusOut = (event) => {
        if (event.relatedTarget && ![...(refResultList.value?.children ?? [])].includes(event.relatedTarget)) {
          reset();
        }
      };

      const onClickOutside = () => {
        reset();
      };

      const selectItem = (item) => {
        emit("selected", item);
        reset();
      };

      const reset = () => {
        state.results = [];
        state.searchTerm = null;
      };

      const focus = () => {
        setTimeout(() => {
          refInput.value.focus();
        }, 10);
      };

      /** @param {KeyboardEvent} event */
      const selectPrevious = (event) => {
        event.preventDefault();

        const index = [...refResultList.value.children].indexOf(event.target);

        if (index > 0) {
          refResultList.value.children.item(index - 1).focus();
        } else if (index === 0) {
          refResultList.value.children.item(refResultList.value.children.length - 1).focus();
        }
      };

      /** @param {KeyboardEvent} event */
      const selectNext = (event) => {
        event.preventDefault();

        const index = [...refResultList.value.children].indexOf(event.target);

        if (index === -1 && state.results.length > 0) {
          refResultList.value.children.item(0).focus();
        } else if (index < refResultList.value.children.length - 1) {
          refResultList.value.children.item(index + 1).focus();
        } else if (index === refResultList.value.children.length - 1) {
          refResultList.value.children.item(0).focus();
        }
      };

      const setFocus = () => {
        refInput.value.focus();
      };

      return {
        fieldId,
        refInput,
        refResultList,
        ...toRefs(state),
        showEmptySlot,
        hasSearchTerm,
        handleFocusOut,
        onClickOutside,
        selectItem,
        reset,
        focus,
        selectPrevious,
        selectNext,
        setFocus,
      };
    },
  };
</script>
