<template>
  <tr class="border-b-2 border-gray-400 leading-tight">
    <th class="px-0.5 py-2 font-normal text-left align-top">
      <div class="">Name, Vorname</div>
      <div class="text-xs">Geburtsdatum Versichertennummer</div>
    </th>
    <th class="px-0.5 py-2 font-normal text-left align-top">
      <div>Information</div>
    </th>
    <th v-if="$page.props.can.view_todos" class="px-0.5 py-2 font-normal text-left align-top">Offene Aufgaben</th>
    <th class="px-0.5 py-2 font-normal text-left align-top">
      <div>
        <button class="flex items-center space-x-2 text-left" @click.prevent="orderBy('-updated_at')">
          <span>Letzte Änderung</span>
          <component-icon v-if="!order.includes('updated_at')">unfold_more</component-icon>
          <component-icon v-if="order === 'updated_at'">expand_less</component-icon>
          <component-icon v-if="order === '-updated_at'">expand_more</component-icon>
        </button>
      </div>
      <div class="text-xs">Bearbeiter</div>
    </th>
    <th>
      <!-- Aktionen -->
    </th>
  </tr>
</template>

<script>
  import {ref} from "vue";
  import ComponentIcon from "@components/Icons/Icon.vue";

  export default {
    name: "PatientsPatientHead",
    components: {
      ComponentIcon,
    },
    props: {
      modelValue: String,
    },
    emits: ["update:modelValue"],

    setup(props, {emit}) {
      const order = ref(props.modelValue || "");

      function orderBy(by) {
        if (order.value.includes(by)) {
          // already sorted by this colum, so flip direction
          if (by.slice(0, 1) === "-") {
            // asc
            order.value = by.slice(1); // remove "-"
          } else {
            // desc
            order.value = "-" + by; // add "-"
          }
        } else {
          // not already sorted by this column, asc is default
          order.value = by;
        }
        emit("update:modelValue", order.value);
      }

      return {orderBy, order};
    },
  };
</script>
