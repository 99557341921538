<template>
  <button v-if="showDonut" @click="openRiskDialog">
    <component-tooltip
      tooltip="Angezeigt werden die Anzahl aller Arzneimittel-bezogener Check-Treffer sowie deren Risikofarben. Hinweis: Interaktionen werden nicht mehrfach gezählt."
    >
      <svg width="50px" height="50px" viewBox="0 0 100 100">
        <circle
          :cx="donutCenter"
          :cy="donutCenter"
          :r="donutRadius"
          class="text-very-high-risk"
          stroke="currentColor"
          stroke-width="15"
          fill="transparent"
          :stroke-dasharray="veryHighRiskDashArray.join(',')"
          :transform="veryHighRiskRotation"
        />
        <circle
          :cx="donutCenter"
          :cy="donutCenter"
          :r="donutRadius"
          class="text-high-risk"
          stroke="currentColor"
          stroke-width="15"
          fill="transparent"
          :stroke-dasharray="highRiskDashArray.join(',')"
          :transform="highRiskRotation"
        />
        <circle
          :cx="donutCenter"
          :cy="donutCenter"
          :r="donutRadius"
          class="text-medium-risk"
          stroke="currentColor"
          stroke-width="15"
          fill="transparent"
          :stroke-dasharray="mediumRiskDashArray.join(',')"
          :transform="mediumRiskRotation"
        />
        <circle
          :cx="donutCenter"
          :cy="donutCenter"
          :r="donutRadius"
          class="text-low-risk"
          stroke="currentColor"
          stroke-width="15"
          fill="transparent"
          :stroke-dasharray="lowRiskDashArray.join(',')"
          :transform="lowRiskRotation"
        />
        <g>
          <text x="50%" y="57%" style="font-size: 24px; text-anchor: middle">
            {{ totalRiskPercents.totalNumber }}
          </text>
        </g>
      </svg>
    </component-tooltip>
  </button>

  <component-dialog
    ref="refDialog"
    dialog-title="Arzneimittel-Risiken (gesamt)"
    dialog-type="information"
    dialog-width="medium"
  >
    <template #content>
      <p class="mt-0 mb-4">
        Angezeigt werden die Anzahl automatisiert detektierter Check-Treffer in den Farben rot (Risiken: sehr hoch),
        orange (Risiken: mittel-hoch), gelb (Risiken: gering-mittel) und grau (sonstige Risiken).
      </p>

      <p>
        Es wurden {{ totalRiskPercents.totalNumber }} Check-Treffer bei den folgenden {{ famsWithChecks }} FAMs
        gefunden:
      </p>

      <ul>
        <li v-for="(medication, index) in medicationRiskClassesWithRisks" :key="index">
          <div>
            <div class="flex items-center space-x-2 p-2">
              <component-icon class="mr-0 text-sm text-gray-500">fiber_manual_record</component-icon>
              <span class="block overflow-hidden text-base">{{ medication.famName }}</span>
            </div>

            <ul class="mt-1 ml-7">
              <li v-for="(risk, index) in medication.risks" :key="index" class="flex items-start space-x-2 mb-3">
                <component-icon class="text-sm mt-0.5">remove</component-icon>
                <span class="block text-sm overflow-hidden">
                  <span v-if="!embeddedInRow && risk.color === 'rot' && !configData.veryhighrisk">
                    {{ risk.checkName }},
                  </span>
                  <span v-else-if="!embeddedInRow && risk.color === 'orange' && !configData.highrisk">
                    {{ risk.checkName }},
                  </span>
                  <span v-else-if="!embeddedInRow && risk.color === 'gelb' && !configData.midrisk">
                    {{ risk.checkName }},
                  </span>
                  <span v-else-if="!embeddedInRow && risk.color === 'grau' && !configData.lowrisk">
                    {{ risk.checkName }},
                  </span>
                  <span
                    v-else-if="!embeddedInRow"
                    class="cursor-pointer underline"
                    @click="goToAnchor(risk.id, risk.riskClass)"
                  >
                    {{ risk.checkName }},
                  </span>
                  <span v-else-if="embeddedInRow">{{ risk.checkName }},</span>

                  <span v-if="risk.color === 'rot'">
                    Risiken: sehr hoch
                    <span class="text-red-600">(rot)</span>
                    : {{ risk.count }} Treffer
                  </span>
                  <span v-if="risk.color === 'orange'">
                    Risiken: mittel-hoch
                    <span class="text-orange-500">(orange)</span>
                    : {{ risk.count }} Treffer
                  </span>
                  <span v-if="risk.color === 'gelb'">
                    Risiken: gering-mittel
                    <span class="text-yellow-500">(gelb)</span>
                    : {{ risk.count }} Treffer
                  </span>
                  <span v-if="risk.color === 'grau'">
                    Risiken: sonstige
                    <span class="text-gray-500">(grau)</span>
                    : {{ risk.count }} Treffer
                  </span>
                </span>
              </li>
            </ul>
          </div>
        </li>
      </ul>

      <p v-if="showSubTitle" class="mt-6">Zu folgenden FAMs wurden keine Check-Treffer gefunden:</p>

      <ul class="mt-2">
        <li
          v-for="(medication, index) in medicationRiskClassesWithoutRisks"
          :key="index"
          class="flex items-center mb-2 p-2"
        >
          <component-icon class="mr-0 text-sm text-gray-500">fiber_manual_record</component-icon>
          <span class="block overflow-hidden text-base pl-2">{{ medication.famName }}</span>
        </li>
      </ul>
    </template>
  </component-dialog>
</template>
<script>
  import {computed, ref} from "vue";
  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";
  export default {
    name: "ComponentRiskDonutChart",
    components: {ComponentTooltip, ComponentIcon, ComponentDialog},

    props: {
      embeddedInRow: {
        type: Boolean,
        default: false,
      },
      medications: {
        type: Array,
        required: true,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },

    setup(props) {
      const INTERACTION_CHECK = 1;

      const showSubTitle = ref(false);
      const donutRadius = 30;
      const donutCenter = 50; // viewBox is 100x100, so center is at 50,50

      const refDialog = ref(null);

      // TODO: config data should be loaded from data service?
      const configData = {
        veryhighrisk: true,
        highrisk: true,
        midrisk: true,
        lowrisk: true,
      };

      const totalMedicationRiskClasses = computed(() => {
        let totalMedicationRisks = [];

        props.medications.forEach((medication) => {
          let totalRisks = [];
          let color = "";

          Object.entries(medication.statistic ?? []).forEach(([key, statsData]) => {
            if (key === "veryHighRisk") {
              color = "rot";
            } else if (key === "highRisk") {
              color = "orange";
            } else if (key === "mediumRisk") {
              color = "gelb";
            } else if (key === "lowRisk") {
              color = "grau";
            }

            if (key !== "skipped") {
              statsData.forEach((statsNode) => {
                totalRisks.push({
                  checkName: statsNode.name,
                  count: statsNode.count,
                  color: color,
                  riskClass: key,
                  id: statsNode.id,
                });
              });
            }
          });

          totalMedicationRisks.push({
            famName: medication.fam,
            risks: totalRisks,
          });

          if (totalRisks.length === 0) {
            showSubTitle.value = true;
          }
        });

        return totalMedicationRisks;
      });

      const medicationRiskClassesWithRisks = computed(() => {
        return totalMedicationRiskClasses.value.filter((medication) => medication.risks.length > 0);
      });

      const medicationRiskClassesWithoutRisks = computed(() => {
        return totalMedicationRiskClasses.value.filter((medication) => medication.risks.length === 0);
      });

      const famsWithChecks = computed(() => {
        return medicationRiskClassesWithRisks.value.length;
      });

      const showDonut = computed(() => {
        return props.medications.length > 0 && famsWithChecks.value > 0 && !props.disabled;
      });

      const totalRiskPercents = computed(() => {
        let veryHighRisk = 0;
        let highRisk = 0;
        let mediumRisk = 0;
        let lowRisk = 0;
        let totalNumber = 0;

        let veryHighRiskPercent = 0;
        let highRiskPercent = 0;
        let mediumRiskPercent = 0;
        let lowRiskPercent = 0;

        props.medications.forEach((medication) => {
          // Stats not filled
          if (medication.statistic.length === 0) {
            return;
          }

          // Add only the half count of interaction check.
          // Because each interaction claim involves two
          // medications!
          if (medication.statistic["veryHighRisk"]) {
            medication.statistic["veryHighRisk"].forEach((check) => {
              if (check.id === INTERACTION_CHECK) {
                veryHighRisk += check.count / 2;
              } else {
                veryHighRisk += check.count;
              }
            });
          }

          if (medication.statistic["highRisk"]) {
            medication.statistic["highRisk"].forEach((check) => {
              if (check.id === INTERACTION_CHECK) {
                highRisk += check.count / 2;
              } else {
                highRisk += check.count;
              }
            });
          }

          if (medication.statistic["mediumRisk"]) {
            medication.statistic["mediumRisk"].forEach((check) => {
              if (check.id === INTERACTION_CHECK) {
                mediumRisk += check.count / 2;
              } else {
                mediumRisk += check.count;
              }
            });
          }

          if (medication.statistic["lowRisk"]) {
            medication.statistic["lowRisk"].forEach((check) => {
              if (check.id === INTERACTION_CHECK) {
                lowRisk += check.count / 2;
              } else {
                lowRisk += check.count;
              }
            });
          }
        });

        // Normalize values to avoid decimal numbers
        veryHighRisk = Math.floor(veryHighRisk);
        highRisk = Math.floor(highRisk);
        mediumRisk = Math.floor(mediumRisk);
        lowRisk = Math.floor(lowRisk);

        totalNumber = veryHighRisk + highRisk + mediumRisk + lowRisk;

        veryHighRiskPercent = totalNumber !== 0 ? veryHighRisk / totalNumber : -1;
        highRiskPercent = totalNumber !== 0 ? highRisk / totalNumber : -1;
        mediumRiskPercent = totalNumber !== 0 ? mediumRisk / totalNumber : -1;
        lowRiskPercent = totalNumber !== 0 ? lowRisk / totalNumber : -1;

        return {
          veryHighRisk: veryHighRiskPercent,
          highRisk: highRiskPercent,
          mediumRisk: mediumRiskPercent,
          lowRisk: lowRiskPercent,
          totalNumber: totalNumber,
        };
      });

      const veryHighRiskDashArray = computed(() => {
        // circum ference = 2 * pi * radius
        // x percent of circum ference = 2 * pi * r * (x / 100)
        return [2 * Math.PI * donutRadius * totalRiskPercents.value.veryHighRisk, 2 * Math.PI * donutRadius];
      });

      const veryHighRiskRotation = computed(() => {
        // offset = 0
        return "rotate(" + calculateRotationAngle(0) + " " + donutCenter + " " + donutCenter + ")";
      });

      const highRiskDashArray = computed(() => {
        // circum ference = 2 * pi * radius
        // x percent of circum ference = 2 * pi * r * (x / 100)
        return [2 * Math.PI * donutRadius * totalRiskPercents.value.highRisk, 2 * Math.PI * donutRadius];
      });

      const highRiskRotation = computed(() => {
        // offset = veryHighRiskPercentage
        return (
          "rotate(" +
          calculateRotationAngle(totalRiskPercents.value.veryHighRisk) +
          " " +
          donutCenter +
          " " +
          donutCenter +
          ")"
        );
      });

      const mediumRiskDashArray = computed(() => {
        // circum ference = 2 * pi * radius
        // x percent of circum ference = 2 * pi * r * (x / 100)
        return [2 * Math.PI * donutRadius * totalRiskPercents.value.mediumRisk, 2 * Math.PI * donutRadius];
      });

      const mediumRiskRotation = computed(() => {
        // offset = veryHighRiskPercentage + highRiskPercentage
        return (
          "rotate(" +
          calculateRotationAngle(totalRiskPercents.value.veryHighRisk + totalRiskPercents.value.highRisk) +
          " " +
          donutCenter +
          " " +
          donutCenter +
          ")"
        );
      });

      const lowRiskDashArray = computed(() => {
        // circum ference = 2 * pi * radius
        // x percent of circum ference = 2 * pi * r * (x / 100)
        return [2 * Math.PI * donutRadius * totalRiskPercents.value.lowRisk, 2 * Math.PI * donutRadius];
      });

      const lowRiskRotation = computed(() => {
        // offset = veryHighRiskPercentage + highRiskPercentage + mediumRiskPercentage
        return (
          "rotate(" +
          calculateRotationAngle(
            totalRiskPercents.value.veryHighRisk +
              totalRiskPercents.value.highRisk +
              totalRiskPercents.value.mediumRisk,
          ) +
          " " +
          donutCenter +
          " " +
          donutCenter +
          ")"
        );
      });

      function calculateRotationAngle(offsetPercentage) {
        return (offsetPercentage * 100 * 360) / 100 - 90;
      }

      function openRiskDialog() {
        refDialog.value.open();
      }

      const riskClasses = {
        veryHighRisk: "red",
        highRisk: "orange",
        mediumRisk: "yellow",
        lowRisk: "grey",
        notShownChecks: "notShownChecks",
      };

      function goToAnchor(checkId, risk) {
        let claimClass = riskClasses[risk];

        // close layer
        refDialog.value.close();

        let target = document.querySelector(`a[data-risk-goto-target='${claimClass}-${checkId}']`);

        // toggle open
        if (target.getAttribute("aria-expanded") === false) {
          target.click();
        }

        // scroll into view
        target.scrollIntoView({behavior: "smooth", block: "start"});
      }

      return {
        showSubTitle,
        donutRadius,
        donutCenter,
        configData,
        medicationRiskClassesWithRisks,
        medicationRiskClassesWithoutRisks,
        famsWithChecks,
        showDonut,
        totalRiskPercents,
        veryHighRiskDashArray,
        veryHighRiskRotation,
        highRiskDashArray,
        highRiskRotation,
        mediumRiskDashArray,
        mediumRiskRotation,
        lowRiskDashArray,
        lowRiskRotation,
        refDialog,
        openRiskDialog,
        goToAnchor,
      };
    },
  };
</script>
