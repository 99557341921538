<template>
  <div class="grid grid-cols-12 gap-x-6 text-xs text-gray-700 uppercase font-bold pb-2 items-center">
    <div class="col-span-2 ml-2" :class="{'ml-8': isEditable}">Was</div>
    <div class="col-span-3">Wert (opt.)</div>
    <div class="col-span-2">Einheit (opt.):</div>
    <div class="col-span-2" :class="{'col-span-3': !isEditable}">Abweichung:</div>
    <div class="col-span-2" :class="{'col-span-1': isEditable}">Von (Datum):</div>
    <div class="col-span-1" />
  </div>

  <ul ref="parent">
    <template v-for="laborValue in laborValues" :key="laborValue.id">
      <template v-if="isLoading">
        <labor-value-skeleton-row />
      </template>

      <template v-else>
        <labor-table-row :is-editable="isEditable" :laborvalue="laborValue" :is-dragging="isDragging" />
      </template>
    </template>
  </ul>
</template>

<script>
  import {ref, watch} from "vue";
  import {router, usePage} from "@inertiajs/vue3";
  import {filter, isEqual} from "lodash";
  import {useDragAndDrop} from "@formkit/drag-and-drop/vue";
  import {handleDragstart, handleTouchstart, swap} from "@formkit/drag-and-drop";

  import {mainLaborvalueList} from "@pages/Records/Components/Sections/LaborBar/enums.js";

  import LaborTableRow from "@pages/Records/Components/Sections/LaborBar/Components/Row.vue";
  import LaborValueSkeletonRow from "@pages/Records/Components/Sections/LaborBar/Components/SkeletonRow.vue";

  export default {
    name: "LaborTable",

    components: {
      LaborValueSkeletonRow,
      LaborTableRow,
    },

    props: {
      isEditable: {
        type: Boolean,
        default: false,
      },
      recordData: {
        type: Object,
        required: true,
      },
      filteredLaborvalues: {
        type: Array,
        required: true,
      },
      isLoading: {
        type: Boolean,
        required: true,
      },
    },

    setup(props) {
      const page = usePage();
      const isDragging = ref(false);

      const config = {
        dragHandle: ".drag-area",
        dropZoneClass: "ring-2 ring-mcred-300",
        plugins: [swap()],
        handleDragstart: (data) => {
          isDragging.value = true;
          handleDragstart(data);
        },
        handleTouchstart: (data) => {
          isDragging.value = true;
          handleTouchstart(data);
        },
      };

      const [parent, laborValues] = useDragAndDrop(props.filteredLaborvalues, config);

      watch(
        () => props.filteredLaborvalues,
        () => {
          laborValues.value = props.filteredLaborvalues;
        },
        {deep: true},
      );

      watch(
        () => laborValues.value,
        (value, oldValue) => {
          if (value.length === oldValue.length) {
            const oldSortOrder = [
              ...filter(props.recordData.laborvalues, (laborvalue) => {
                return mainLaborvalueList.includes(laborvalue.mc_laborvalue_key);
              }).map((laborValue) => laborValue.id),
              ...oldValue.map((laborValue) => laborValue.id),
            ];

            const newSortOrder = [
              ...filter(props.recordData.laborvalues, (laborvalue) => {
                return mainLaborvalueList.includes(laborvalue.mc_laborvalue_key);
              }).map((laborValue) => laborValue.id),
              ...value.map((laborValue) => laborValue.id),
            ];

            if (!isEqual(oldSortOrder, newSortOrder)) {
              router.post(
                route("sort.store", {
                  patient: page.props.patient.id,
                  record: page.props.record.id,
                  relation: "laborvalues",
                }),
                {
                  ids: newSortOrder,
                },
                {
                  preserveScroll: true,
                  only: ["record", "flash"],
                  onFinish: () => {
                    isDragging.value = false;
                  },
                },
              );
            }
          }
        },
      );

      return {
        parent,
        laborValues,
        isDragging,
      };
    },
  };
</script>

<style scoped></style>
