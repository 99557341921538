<template>
  <tr class="border-b-2 border-gray-400 leading-tight">
    <!-- toggle & type -->
    <th class="px-0.5 py-2 font-normal text-left align-top">
      <div>Art des Dokuments</div>
      <div class="text-xs">Interne Bezeichnung</div>
    </th>
    <!-- progress -->
    <th class="px-0.5 py-2 font-normal text-left align-top">
      <div>Fortschritt</div>
    </th>
    <!-- created_at -->
    <th class="px-0.5 py-2 font-normal text-left align-top">
      <div>
        <button class="flex items-center space-x-2 text-left" @click.prevent="orderBy('-created_at')">
          <span>Erstellt</span>
          <component-icon v-if="!order.includes('created_at')">unfold_more</component-icon>
          <component-icon v-if="order === 'created_at'">expand_less</component-icon>
          <component-icon v-if="order === '-created_at'">expand_more</component-icon>
        </button>
      </div>
      <div class="text-xs">Herkunft</div>
    </th>
    <!-- updated_at -->
    <th class="px-0.5 py-2 font-normal text-left align-top">
      <div>
        <button class="flex items-center space-x-2 text-left" @click.prevent="orderBy('-updated_at')">
          <span>Geändert</span>
          <component-icon v-if="!order.includes('updated_at')">unfold_more</component-icon>
          <component-icon v-if="order === 'updated_at'">expand_less</component-icon>
          <component-icon v-if="order === '-updated_at'">expand_more</component-icon>
        </button>
      </div>
      <div class="text-xs">Bearbeiter</div>
    </th>

    <!-- sharing & events -->
    <th class="px-0.5 py-2 font-normal text-left align-top">
      <div>Team</div>
      <div v-if="$page.props.can.view_shared_records" class="text-xs">Freigabe / Kommentare</div>
      <div v-else class="text-xs">Kommentare</div>
    </th>

    <!-- status -->
    <th class="px-0.5 py-2 font-normal text-left align-top">
      <div>Status</div>
    </th>

    <!-- actions -->
    <th />
  </tr>
</template>

<script>
  import {ref} from "vue";
  import ComponentIcon from "@components/Icons/Icon.vue";

  export default {
    name: "PatientsDocumentHead",
    components: {
      ComponentIcon,
    },
    props: {
      modelValue: String,
    },
    emits: ["update:modelValue"],
    setup(props, {emit}) {
      const order = ref(props.modelValue || "");

      function orderBy(by) {
        if (order.value.includes(by)) {
          // already sorted by this colum, so flip direction
          if (by.slice(0, 1) === "-") {
            // asc
            order.value = by.slice(1); // remove "-"
          } else {
            // desc
            order.value = "-" + by; // add "-"
          }
        } else {
          // not already sorted by this column, asc is default
          order.value = by;
        }
        emit("update:modelValue", order.value);
      }

      return {orderBy, order};
    },
  };
</script>
