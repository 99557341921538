<template>
  <component-dialog
    ref="confirmationDialog"
    :dialog-title="title"
    :dialog-content="content"
    primary-label="OK"
    @primary-button-clicked="confirm()"
    @cancel-button-clicked="close()"
  />
</template>

<script>
  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import {ref} from "vue";

  export default {
    name: "ComponentConfirmationDialog",

    components: {ComponentDialog},

    props: {
      title: {
        type: String,
        required: true,
      },
      content: {
        type: String,
        default: null,
      },
    },

    emits: ["confirmed"],

    setup(props, {emit}) {
      const confirmationDialog = ref(null);

      // allows to "store" a payload while the dialog is open
      let payload = null;

      function confirm() {
        emit("confirmed", payload);
        close();
      }

      function open(value) {
        payload = value ?? null;
        confirmationDialog.value.open();
      }

      function close() {
        confirmationDialog.value.close();
      }

      return {confirmationDialog, open, confirm, close};
    },
  };
</script>
