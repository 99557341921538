<template>
  <div class="grid grid-cols-12 gap-x-6 text-xs text-gray-700 uppercase font-bold pb-2">
    <div class="col-span-8 ml-2" :class="{'ml-8': isEditable}">Erkrankung / ICD-10 / Allergie / Lebensumstand:</div>
    <div class="col-span-3">Seit (Datum; opt.):</div>
    <div class="col-span-1" />
  </div>

  <ul ref="parent">
    <template v-for="disease in diseases" :key="disease.id">
      <template v-if="isLoading">
        <disease-skeleton-row />
      </template>

      <template v-else>
        <diseases-table-row :is-editable="isEditable" :disease="disease" :is-dragging="isDragging" />
      </template>
    </template>
  </ul>
</template>

<script>
  import {ref, watch} from "vue";
  import {router, usePage} from "@inertiajs/vue3";
  import {isEqual} from "lodash";
  import {useDragAndDrop} from "@formkit/drag-and-drop/vue";
  import {handleDragstart, handleTouchstart, swap} from "@formkit/drag-and-drop";

  import DiseasesTableRow from "@pages/Records/Components/Sections/DiseaseBar/Components/Row.vue";
  import DiseaseSkeletonRow from "@pages/Records/Components/Sections/DiseaseBar/Components/SkeletonRow.vue";

  export default {
    name: "DiseasesTable",

    components: {DiseaseSkeletonRow, DiseasesTableRow},

    props: {
      isEditable: {
        type: Boolean,
        required: true,
      },
      recordData: {
        type: Object,
        required: true,
      },
      isLoading: {
        type: Boolean,
        required: true,
      },
    },

    setup(props) {
      const page = usePage();
      const isDragging = ref(false);

      const config = {
        dragHandle: "[drag-handle]",
        dropZoneClass: "ring-2 ring-mcred-300",
        plugins: [swap()],
        handleDragstart: (data) => {
          isDragging.value = true;
          handleDragstart(data);
        },
        handleTouchstart: (data) => {
          isDragging.value = true;
          handleTouchstart(data);
        },
      };

      const [parent, diseases] = useDragAndDrop(props.recordData.diseases, config);

      watch(
        () => props.recordData.diseases,
        () => {
          diseases.value = props.recordData.diseases;
        },
        {deep: true},
      );

      watch(
        () => diseases.value,
        (value, oldValue) => {
          if (value.length === oldValue.length) {
            const oldSortOrder = oldValue.map((medication) => medication.id);
            const newSortOrder = value.map((medication) => medication.id);

            if (!isEqual(oldSortOrder, newSortOrder)) {
              router.post(
                route("sort.store", {
                  patient: page.props.patient.id,
                  record: page.props.record.id,
                  relation: "diseases",
                }),
                {
                  ids: newSortOrder,
                },
                {
                  preserveScroll: true,
                  only: ["record", "flash"],
                  onFinish: () => {
                    isDragging.value = false;
                  },
                },
              );
            }
          }
        },
      );

      return {diseases, parent, isDragging};
    },
  };
</script>
