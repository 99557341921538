<template>
  <tr
    class="hover:bg-gray-100 leading-tight cursor-[row-resize]"
    :class="{'border-b-2 border-gray-200': !expanded}"
    @click="(event) => toggleRow(event)"
  >
    <!-- 🔻 toggle & type -->
    <td class="px-0.5 py-2">
      <div class="flex space-x-2 items-center">
        <component-tooltip v-if="!expanded" tooltip="ausklappen">
          <component-icon clickable class="text-mcred" @click="expanded = true">arrow_drop_down</component-icon>
        </component-tooltip>
        <component-tooltip v-else-if="expanded" tooltip="einklappen">
          <component-icon clickable @click="expanded = false">arrow_drop_up</component-icon>
        </component-tooltip>

        <div>
          <div>
            <inertia-link
              :href="recordEditLink"
              class="text-gray-700 hover:text-gray-900 font-semibold cursor-pointer hover:underline"
            >
              <span class="text-sm md:hidden">Analyse</span>
              <span class="hidden md:inline">Medikations&shy;analyse</span>
            </inertia-link>
          </div>

          <component-tooltip v-if="record.label" tooltip="Interne Bezeichnung" :force-inline="true">
            <a class="inline-block text-xs text-gray-900" @click.prevent="$emit('open:editRecordLabel')">
              {{ record.label }}
            </a>
          </component-tooltip>
          <a v-else class="inline-block text-xs" href="#" @click.prevent="$emit('open:editRecordLabel')">
            Bezeichnung vergeben
          </a>
        </div>
        <component-risk-donut-chart :medications="record.medications" :embedded-in-row="true" />
      </div>
    </td>

    <!-- progress -->
    <td class="px-0.5 py-2">
      <component-record-progress :record="record" />
    </td>

    <!-- created at -->
    <td class="px-0.5 py-2">
      {{ record.created_at }}
      <div v-if="recordOrigin">
        <component-tooltip tooltip="Herkunft" :force-inline="true">
          <span class="text-xs">
            {{ recordOrigin }}
          </span>
        </component-tooltip>
      </div>
    </td>

    <!-- updated at -->
    <td class="px-0.5 py-2">
      {{ record.updated_at }}
      <div v-if="record.username">
        <component-tooltip tooltip="Bearbeiter" :force-inline="true">
          <span class="text-xs">{{ record.username }}</span>
        </component-tooltip>
      </div>
    </td>

    <!-- sharing & events -->
    <td class="px-0.5 py-2">
      <div class="flex space-x-2">
        <!-- share button -->
        <template
          v-if="
            $page.props.can.view_shared_records &&
            !isRevoked &&
            $page.props.can.allow_shared_document_forwarding &&
            hasThirdParty('p4u')
          "
        >
          <component-tooltip
            v-if="isShareable && sharedByMe"
            :force-inline="true"
            tooltip="Variante erneut teilen (wurde bereits geteilt)"
          >
            <component-icon-button class="p4umc-primary" icon="share" @click="$emit('open:shareRecord')" />
          </component-tooltip>

          <component-tooltip
            v-else-if="isShareable"
            :force-inline="true"
            tooltip="Variante teilen (wurde bislang noch nicht geteilt)"
          >
            <component-icon-button
              class="text-gray-500 bg-white border-mcred hover:bg-gray-300 hover:border-mcred"
              icon="share"
              @click="$emit('open:shareRecord')"
            />
          </component-tooltip>

          <component-tooltip
            v-else
            :force-inline="true"
            tooltip="Ohne Analyseergebnis kann diese Variante nicht geteilt werden"
          >
            <component-icon-button icon="share" disabled />
          </component-tooltip>
        </template>

        <!-- share status button (always available due to list of comments) -->
        <template v-if="record?.notifications.length">
          <component-tooltip placement="top-start">
            <!-- "question_answer" or specific icon via getNotificationIcon(record.notifications[0]) -->
            <component-icon-button class="p4umc-flat" icon="question_answer" @click="$emit('open:shareStatus')" />
            <template #tooltip>
              <div class="flex space-x-2 items-baseline">
                <component-icon>{{ getNotificationIcon(record.notifications[0]) }}</component-icon>
                <div>
                  <div class="!font-thin">{{ getNotificationText(record.notifications[0]) }}</div>
                  <div>{{ getNotificationPartner(record.notifications[0]) }}</div>
                </div>
              </div>
            </template>
          </component-tooltip>
        </template>
      </div>
    </td>

    <td class="px-0.5 py-2">
      <div class="pr-1 md:pr-2">
        <component-record-status :record="record" inner-class="min-w-24 max-w-32" />
      </div>
    </td>

    <!-- actions -->
    <td class="px-0.5 py-2">
      <div class="flex justify-end">
        <component-tooltip
          :tooltip="
            record.is_pos_record
              ? 'Sie können den Fall nicht löschen, da es sich um einen Fall aus der Warenwirtschaft handelt'
              : !$page.props.can.delete_records
                ? 'Sie können den Fall nicht löschen, da Berechtigungen fehlen'
                : 'Löschen'
          "
        >
          <component-icon-button
            icon="delete"
            :disabled="!$page.props.can.delete_records || record.is_pos_record"
            @click="$emit('confirm:deleteItem')"
          />
        </component-tooltip>
      </div>
    </td>
  </tr>
  <!-- expandable details -->
  <tr v-if="expanded" class="[:hover_+_&]:bg-gray-100 border-b-2 border-gray-200 leading-tight">
    <td colspan="7" class="text-sm pl-5">
      <table v-if="record.medications.length > 0" class="w-full">
        <thead>
          <tr class="font-semibold text-left text-gray-700">
            <th class="px-1 py-0.5 align-top w-4/12 md:w-3/12 lg:w-2/12">Wirkstoff</th>
            <th class="px-1 py-0.5 align-top hidden md:table-cell md:w-2/12 lg:w-1/12">Stärke</th>
            <th class="px-1 py-0.5 align-top w-3/12 md:w-3/12 lg:w-3/12">Handelsname</th>
            <th class="px-1 py-0.5 align-top w-2/12 md:w-2/12 lg:w-2/12">Dosierung</th>
            <th class="px-1 py-0.5 align-top hidden lg:table-cell lg:w-1/12">Einheit</th>
            <th class="px-1 py-0.5 align-top w-3/12 md:w-2/12 lg:w-3/12">Grund</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="medication in record.medications" :key="medication.id" class="border-t-1 border-gray-200">
            <td class="px-1 py-0.5">
              {{ medication.substances.map((substance) => substance.name).join("\n") }}
            </td>
            <td class="px-1 py-0.5 hidden md:table-cell">
              {{ medication.substances.map((substance) => substance.strength).join("\n") }}
            </td>
            <td class="px-1 py-0.5">{{ medication.fam }}</td>
            <td class="px-1 py-0.5">
              <div v-if="medication.dosage.dosagealternative === null" class="whitespace-nowrap">
                {{ medication.dosage.dosage_string_formatted }}
              </div>
              <div v-else>
                {{ medication.dosage.dosagealternative.text ?? medication.dosage.dosagealternative.additional }}
              </div>
            </td>
            <td class="px-1 py-0.5 hidden lg:table-cell">
              <div>{{ medication.dosage.selected_unit }}</div>
            </td>
            <td class="px-1 py-0.5">
              {{ medication.indications.map((indication) => indication.name).join(", ") }}
            </td>
          </tr>
        </tbody>
      </table>
      <component-empty-state v-else class="mt-0 mb-2">
        Diese Variante enthält noch keine Medikation.
      </component-empty-state>
    </td>
  </tr>
</template>

<script>
  import {computed, inject, ref} from "vue";
  import {Link as InertiaLink} from "@inertiajs/vue3";

  import {sharedrecordStatus, recordNotifications, recordOriginTexts} from "@pages/Records/Enums/Enums.js";

  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";
  import ComponentRecordProgress from "@pages/Records/Components/RecordProgress.vue";
  import ComponentIconButton from "@components/Buttons/IconButton.vue";
  import ComponentRiskDonutChart from "@components/Risks/RiskDonutChart.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentRecordStatus from "@pages/Records/Components/RecordStatus.vue";
  import ComponentEmptyState from "@components/EmptyState/EmptyState.vue";

  export default {
    name: "PatientsDocumentRecordRow",

    components: {
      ComponentEmptyState,
      ComponentRecordStatus,
      ComponentIcon,
      ComponentRiskDonutChart,
      ComponentIconButton,
      ComponentRecordProgress,
      InertiaLink,
      ComponentTooltip,
    },

    props: {
      patient: {
        type: Object,
        required: true,
      },
      record: {
        type: Object,
        required: true,
      },
    },

    emits: ["confirm:deleteItem", "open:shareRecord", "open:shareStatus", "open:editRecordLabel"],

    setup(props) {
      const hasThirdParty = inject("$hasThirdParty");

      const expanded = ref(false);

      const hasResult = computed(() => props.record.progress !== "formEmpty" && props.record.progress !== "formFilled");

      const sharedByMe = computed(() => props.record.shared_by_me);
      const sharedWithMe = computed(() => props.record.shared_with_me);
      const returnedToMe = computed(() => props.record.returned_to_me);
      const isRevoked = computed(() => props.record.shared_status === sharedrecordStatus.revoked);
      const isShareable = computed(() => props.record.can_share_this_record ?? (!isRevoked.value && hasResult.value));
      const isCompleteable = computed(() => props.record.can_complete_this_record);

      const recordEditLink = computed(() => {
        return route("records.edit", {patient: props.patient.id, record: props.record.id});
      });

      const recordOrigin = computed(() => {
        if (props.record.origin) {
          return recordOriginTexts[props.record.origin] ?? null;
        }

        return null;
      });

      const ownerName = computed(() => {
        if ((props.record.shared_owners ?? []).length === 0) {
          return null;
        }
        let owner = props.record.shared_owners[0];
        return owner.owner_company ? owner.owner_company : owner.owner_name;
      });

      // /** Object contains value in (optional) key */
      // function contains(objects, value, key = "record_id") {
      //   return objects && objects.some((object) => object[key] === value);
      // }

      function getNotificationIcon(notification) {
        if (notification?.type === recordNotifications.sharedByMe) {
          return "call_made";
        }

        if (notification?.type === recordNotifications.sharedWithMe) {
          return "call_received";
        }
        if (notification?.type === recordNotifications.takenAwayFromMe) {
          return "person_add_disabled";
        }
        if (notification?.type === recordNotifications.revokedByMe) {
          return "person_add_disabled";
        }
        if (notification?.type === recordNotifications.completedFromMe) {
          return "done";
        }

        if (notification?.type === recordNotifications.returnedToMe) {
          return "keyboard_backspace";
        }

        if (notification?.type === recordNotifications.editedFromMe) {
          return "edit";
        }
        if (notification?.type === recordNotifications.commented) {
          return "mode_comment";
        }
      }

      function getNotificationPartner(notification) {
        if (notification?.audience === "group") {
          return "Seminar-/Link-Freigabe";
        }

        if (notification?.type === recordNotifications.commented && !notification?.partner_name) {
          // von ...
          return "extern hinzugezogenem Arzt";
        }

        return notification?.partner_company || notification?.partner_name;
      }

      function getNotificationText(notification) {
        let text = notification.updated_at ?? "zuletzt";

        if (notification?.type === recordNotifications.sharedByMe) {
          return text + " von mir freigegeben" + (notification?.audience === "group" ? " als" : " an");
        }

        if (notification?.type === recordNotifications.sharedWithMe) {
          return text + " für mich freigegeben von";
        }
        if (notification?.type === recordNotifications.takenAwayFromMe) {
          return text + " widerrufen von";
        }
        if (notification?.type === recordNotifications.revokedByMe) {
          return text + " von mir widerrufen gegenüber";
        }
        if (notification?.type === recordNotifications.completedFromMe) {
          return text + " Zurückgesendet an";
        }

        if (notification?.type === recordNotifications.returnedToMe) {
          return text + (notification?.with_changes ? " mit Änderungen" : "") + " an mich zurückgesendet von";
        }

        if (notification?.type === recordNotifications.editedFromMe) {
          return text + " von mir bearbeitet";
        }
        if (notification?.type === recordNotifications.commented) {
          return text + " kommentiert von";
        }
      }

      function toggleRow(event, href) {
        let node = event.target ?? event;

        if (!node.nodeName) {
          return;
        }

        if (
          node.nodeName.toLowerCase() === "a" ||
          node.nodeName.toLowerCase() === "button" ||
          node.nodeName.toLowerCase() === "select" ||
          node.nodeName.toLowerCase() === "input" ||
          node.nodeName.toLowerCase() === "textarea" ||
          node.nodeName.toLowerCase() === "dialog"
        ) {
          // click on a clickable element (a or button)
          return;
        }

        if (node.nodeName.toLowerCase() === "td") {
          // click on <td> => TOGGLE row
          expanded.value = !expanded.value;
          return;
        }

        if (node.parentNode) {
          // click on any other element: check if parent is <td>
          return toggleRow(node.parentNode);
        }
      }

      return {
        hasThirdParty,
        recordNotifications,
        expanded,
        sharedByMe,
        sharedWithMe,
        returnedToMe,
        isRevoked,
        isShareable,
        isCompleteable,
        recordEditLink,
        recordOrigin,
        ownerName,
        getNotificationIcon,
        getNotificationPartner,
        getNotificationText,
        toggleRow,
      };
    },
  };
</script>
