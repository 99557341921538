<template>
  <div>
    <dashboard-widget class="min-h-48">
      <template #title>
        <a href="#" class="group hover:no-underline flex items-center space-x-3" @click.prevent="openBmpImportDialog()">
          <component-icon class="p-2 bg-mcgreen group-hover:bg-mcgreen-450 rounded-full text-white font-normal">
            barcode_reader
          </component-icon>
          <span class="text-gray-700 group-hover:text-gray-900">BMP importieren</span>
        </a>
      </template>
      <template #default>
        <p class="text-lg">Starten Sie mit einem Medikationsplan</p>
      </template>

      <template #actions>
        <component-button class="p4umc-accent" label="BMP importieren" @click="openBmpImportDialog()" />
      </template>
    </dashboard-widget>
    <component-bmp-import-dialog ref="refBmpImportDialog" />
  </div>
</template>
<script>
  import DashboardWidget from "./Widget.vue";
  import ComponentInput from "@components/Inputs/Input.vue";
  import ComponentSelect from "@components/Selects/Select.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentAutocomplete from "@components/Autocomplete/Autocomplete.vue";
  import {useForm} from "@inertiajs/vue3";
  import {patientGender} from "@pages/Patients/Enums/Enums.js";
  import {inject, reactive, ref, computed, watch} from "vue";
  import filterExistingPatients from "@utils/Helpers/FilterExistingPatients.js";
  import {debounce} from "lodash";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentBmpImportDialog from "@components/Dialogs/BmpImportDialog/BmpImportDialog.vue";
  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";
  import ComponentSpinner from "@components/Spinner.vue";
  import ComponentAlert from "@components/Alerts/Alert.vue";

  export default {
    name: "DashboardBmpImportExclusiveWidget",
    components: {
      ComponentBmpImportDialog,
      ComponentIcon,
      ComponentButton,
      DashboardWidget,
    },
    setup(props) {
      const refBmpImportDialog = ref(null);

      function openBmpImportDialog() {
        refBmpImportDialog.value.open();
      }

      return {
        refBmpImportDialog,
        openBmpImportDialog,
      };
    },
  };
</script>
