<template>
  <div>
    <a :id="urlRedirect.identifier" target="_blank" :href="urlRedirect.target_url ?? targetUrl">
      {{ urlRedirect.link_text ?? linkText ?? urlRedirect.target_url ?? targetUrl }}
    </a>
    <template v-if="urlRedirect.file_type ?? fileType !== ''">, {{ urlRedirect.file_type ?? fileType }}</template>
    <template v-if="urlRedirect.status ?? status !== ''">, {{ urlRedirect.status ?? status }}</template>
    <div v-if="urlRedirect.description ?? description !== ''" class="max-w-3xl">
      {{ urlRedirect.description ?? description }}
    </div>
  </div>
</template>
<script>
  export default {
    name: "DocumentComponentUrlRedirect",
    props: {
      urlRedirect: {
        type: Object,
        required: true,
      },
      targetUrl: {
        type: String,
        default: "",
      },
      linkText: {
        type: String,
        default: "",
      },
      fileType: {
        type: String,
        default: "",
      },
      status: {
        type: String,
        default: "",
      },
    },
    setup(props) {},
  };
</script>
