<template>
  <div class="flex flex-row-reverse space-x-reverse space-x-2 items-baseline">
    <component-dropdown>
      <template #button>
        <div
          class="flex w-full items-center justify-between px-2 py-3 text-sm font-semibold text-gray-500 hover:text-gray-900"
        >
          <span>Filter</span>
          <span
            v-if="flagsCount"
            class="ml-1.5 rounded bg-gray-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700"
            v-text="flagsCount"
          />
          <component-icon class="ml-1">expand_more</component-icon>
        </div>
      </template>
      <template #dropdown>
        <ul class="p-2 max-h-96 overflow-y-auto flex-col space-y-4">
          <li v-for="(flag, key) in availableFlags" :key="key" class="flex items-center">
            <div class="w-full">
              <label class="flex items-start space-x-2 text-sm group">
                <input
                  v-model="flags"
                  :value="key"
                  :checked="flags.includes(key)"
                  type="checkbox"
                  class="h-4 w-4 mt-0.5 rounded border-gray-300 text-mcred focus:ring-mcred"
                  @change="(event) => handleChange(key, event)"
                />
                <span class="whitespace-nowrap select-none text-gray-500 group-hover:text-gray-900 cursor-pointer">
                  <template v-if="allowsSubSelect(flag)">
                    {{ flag.label }}
                  </template>
                  <template v-else>
                    {{ flag }}
                  </template>
                </span>
              </label>
              <div v-if="allowsSubSelect(flag) && flags.includes(key)" class="ml-6">
                <select @change="handleSelectedOption(key, $event)">
                  <option v-if="flag?.nullable !== false" value="">bitte wählen</option>
                  <option
                    v-for="(oLabel, oKey) in flag.options"
                    :key="oKey"
                    :value="key + ':' + oKey"
                    :selected="flags.includes(key + ':' + oKey)"
                  >
                    {{ oLabel }}
                  </option>
                </select>
              </div>
            </div>
          </li>
        </ul>
      </template>
    </component-dropdown>

    <ul class="flex flex-row-reverse flex-wrap">
      <template v-for="key in flags" :key="key">
        <li
          v-if="!key.includes(':')"
          class="flex whitespace-nowrap ml-2 mb-2 items-center rounded-xl bg-gray-200 px-2 py-1 text-sm font-medium text-gray-600"
        >
          <span>
            <template v-if="allowsSubSelect(availableFlags[key])">
              {{ availableFlags[key].label }}

              <span v-if="getCurrentSubSelectedLabel(key) !== null" class="font-normal">
                {{ getCurrentSubSelectedLabel(key) }}
              </span>
            </template>
            <template v-else>
              {{ availableFlags[key] }}
            </template>
          </span>
          <button
            type="button"
            class="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-gray-500/20"
            @click="removeFlag(key)"
          >
            <span class="sr-only">Filter entfernen</span>
            <svg viewBox="0 0 14 14" class="h-3.5 w-3.5 stroke-gray-700/50 group-hover:stroke-gray-700/75">
              <path d="M4 4l6 6m0-6l-6 6" />
            </svg>
            <span class="absolute -inset-1" />
          </button>
        </li>
      </template>
    </ul>
  </div>
</template>
<script>
  import {computed, ref, watch} from "vue";

  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentDropdown from "@components/Dropdown/Dropdown.vue";

  export default {
    name: "ComponentFilterFlags",

    components: {ComponentDropdown, ComponentIcon},

    props: {
      modelValue: {
        type: Object,
        required: true,
      },
      availableFlags: {
        type: Object,
        required: true,
      },
      onlyOneAllowed: {
        type: Boolean,
        default: false,
      },
    },

    emits: ["update:modelValue"],

    setup(props, {emit}) {
      const flags = ref(props.modelValue);
      const flagsVisible = ref(false);

      watch(flags, (newValue) => {
        emit("update:modelValue", newValue);
      });

      const flagsCount = computed(() => {
        return flags.value.filter((flag) => {
          return !flag.includes(":");
        }).length;
      });

      function allowsSubSelect(flag) {
        return typeof flag === "object";
      }

      function getCurrentSubSelectedLabel(key) {
        let oKey = flags.value.find((flag) => flag.startsWith(key + ":")) ?? null;

        if (oKey) {
          return props.availableFlags[key].options[oKey.split(":")[1]];
        }

        return null;
      }

      function removeFlag(key) {
        flags.value = flags.value.filter((flag) => flag !== key && !flag.startsWith(key + ":"));
      }

      function handleChange(key, event) {
        if (
          allowsSubSelect(props.availableFlags[key]) &&
          event.target.checked &&
          props.availableFlags[key]?.nullable === false
        ) {
          // when selected item allows sub selection and nullable === false,
          // we force to add the first sub option
          flags.value.push(key + ":" + Object.keys(props.availableFlags[key].options)[0]);
        } else if (props.onlyOneAllowed && flags.value.length > 1 && event.target.checked) {
          flags.value = flags.value.filter((flag) => flag === key);
        } else if (!event.target.checked) {
          flags.value = flags.value.filter((flag) => {
            return !flag.startsWith(key + ":");
          });
        }
      }

      function handleSelectedOption(key, event) {
        // remove previous options (unique selection)
        flags.value = flags.value.filter((flag) => !flag.startsWith(key + ":"));

        // now add the selected item (if not empty)
        if (event.target.value !== "") {
          flags.value.push(event.target.value);
        }
      }

      return {
        flags,
        flagsCount,
        allowsSubSelect,
        getCurrentSubSelectedLabel,
        flagsVisible,
        removeFlag,
        handleChange,
        handleSelectedOption,
      };
    },
  };
</script>
