<template>
  <div class="bg-white shadow-md flex min-h-[4.5rem] items-center py-2 px-6" :class="{'hover:bg-gray-100': !showForm}">
    <div
      class="cursor-pointer bg-mcred w-6 h-6 rounded-full mr-3 flex items-center justify-center"
      @click="toggleUserForm"
    >
      <component-icon class="white-icon text-base">person</component-icon>
    </div>

    <div class="w-full">
      <div v-if="!showForm" class="cursor-pointer" @click="toggleUserForm">
        <div>
          <span class="text-md black-800 font-medium">Bearbeiter</span>

          <br />

          <span class="text-xs text-gray-700">{{ record.username }}, {{ status[record.userstatus] }}, {{ date }}</span>
        </div>
      </div>

      <div v-else>
        <h5 class="py-4 px-0.5 text-2xl">
          Bearbeiter

          <template v-if="!isEditable">
            <component-button class="p4umc-primary text-base" label="Bearbeiten" @click="changeIsEditable" />
          </template>
        </h5>

        <div class="ml-1 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          <component-input
            ref="user"
            name="user"
            tabindex="1"
            autocomplete="off"
            label="Bearbeiter:"
            :model-value="record.username"
            :disabled="!isEditable"
            @input="changeValue($event, 'username')"
          />

          <component-select
            id="userstatus"
            ref="userstatus"
            name="userstatus"
            :model-value="record.userstatus"
            :options="status"
            label="Beruf / Status:"
            :disabled="!isEditable"
            @change="handleStatusChange($event)"
          />

          <component-input label="Letztes Update:" :model-value="date" :disabled="true" />
        </div>

        <component-dialog
          ref="refWarningDialog"
          dialog-title="Warnhinweis"
          dialog-type="information"
          @dialog-closed="confirmStatusChange"
        >
          <template #content>
            <span class="info">CAVE:</span>
            Die Bewertung der Analyse und die Beratung im Rahmen eines Medikationsmanagements müssen durch einen
            Apotheker der Apotheke erfolgen (eine Unterstützung durch weiteres pharmazeutisches Personal ist allerdings
            erlaubt).
          </template>
        </component-dialog>
      </div>

      <component-button
        v-if="showForm && isEditable"
        class="ml-4 my-5 p4umc-primary"
        label="Speichern"
        @click="saveUserBar"
      />
    </div>
  </div>
</template>

<script>
  import {computed, ref} from "vue";
  import {usePage} from "@inertiajs/vue3";

  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentSelect from "@components/Selects/Select.vue";
  import ComponentInput from "@components/Inputs/Input.vue";
  import ComponentDialog from "@components/Dialogs/Dialog.vue";

  import {status} from "@pages/Records/Components/Sections/UserBar/enums.js";

  export default {
    name: "RecordComponentSectionUserBar",

    components: {ComponentDialog, ComponentInput, ComponentSelect, ComponentButton, ComponentIcon},

    props: {
      record: {
        type: Object,
        required: true,
      },
      isEditable: {
        type: Boolean,
        required: true,
      },
      changeValue: {
        type: Function,
        required: true,
      },
      saveRecord: {
        type: Function,
        required: true,
      },
    },

    setup(props) {
      const page = usePage();

      const showForm = ref(false);
      const refWarningDialog = ref(null);

      const date = computed(() => page.props.record.updated_at);

      const toggleUserForm = () => {
        showForm.value = !showForm.value;
      };

      const handleStatusChange = (newStatus) => {
        props.changeValue(newStatus, "userstatus");

        if (newStatus !== "pharmacist") {
          refWarningDialog.value.open();
        }
      };

      const confirmStatusChange = () => {
        refWarningDialog.value.close();

        props.saveRecord();
      };

      const saveUserBar = () => {
        showForm.value = false;
        props.saveRecord();
      };

      return {
        /** enum */
        status,

        /** const */
        showForm,
        refWarningDialog,

        /** computed */
        date,

        /** function */
        toggleUserForm,
        handleStatusChange,
        confirmStatusChange,
        saveUserBar,
      };
    },
  };
</script>
