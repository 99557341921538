import {invert} from "lodash";
import {daysNumber, daysOfWeekGermanShort, monthGermanNames, monthNumber} from "@/resources/js/Enums/dateEnums.js";
import calculateFraction from "@pages/Records/Components/Sections/MedicationBar/Utils/calculateFraction.js";

/**
 * @public
 * @param {string} type
 * @param {Object} dosageData
 * @return {string}
 */
export function getAlternativeDosageMessage(type, dosageData) {
  let alternativeDosageMessage = "";

  switch (type) {
    case "multipleHours":
      alternativeDosageMessage = buildMultipleHourMessage(dosageData);
      break;
    case "multipleDay":
      alternativeDosageMessage = buildMultipleDayMessage(dosageData);
      break;
    case "specialDays":
      alternativeDosageMessage = buildSpecialDaysMessage(dosageData);
      break;
    case "multipleWeek":
      alternativeDosageMessage = buildMultipleWeekMessage(dosageData);
      break;
    case "specialWeeks":
      alternativeDosageMessage = buildSpecialWeeksMessage(dosageData);
      break;
    case "onceAtMonth":
      alternativeDosageMessage = buildOnceAtMonthMessage(dosageData);
      break;
    case "specialMonth":
      alternativeDosageMessage = buildSpecialMonthMessage(dosageData);
      break;
    case "special":
      alternativeDosageMessage = "";
      break;
    default:
      alternativeDosageMessage = "Andere Dosierungsangaben";
      break;
  }

  alternativeDosageMessage = alternativeDosageMessage.trim();

  return (alternativeDosageMessage + escape(dosageData.additional)).trim();
}

/**
 * @private
 * @param {Object} dosageData
 * @return {string}
 */
function buildMultipleHourMessage(dosageData) {
  const intervalString = buildIntervalString(dosageData);

  return "Alle " + dosageData.interval + " Stunden: " + intervalString;
}

/**
 * @private
 * @param {Object} dosageData
 * @return {string}
 */
function buildMultipleDayMessage(dosageData) {
  const intervalString = buildIntervalString(dosageData);
  const intervalValue = dosageData.data.length;

  return intervalValue + " x / Tag: " + intervalString;
}

/**
 * @private
 * @param {Object} dosageData
 * @return {string}
 */
function buildSpecialDaysMessage(dosageData) {
  const intervalString = buildIntervalString(dosageData);

  return "Jeden " + dosageData.interval + ". Tag: " + intervalString;
}

/**
 * @private
 * @param {Object} dosageData
 * @return {string}
 */
function buildMultipleWeekMessage(dosageData) {
  const intervalString = buildIntervalString(dosageData);
  const intervalValue = dosageData.data.length;

  return intervalValue + " x / Woche: " + intervalString;
}

/**
 * @private
 * @param {Object} dosageData
 * @return {string}
 */
function buildSpecialWeeksMessage(dosageData) {
  const intervalString = buildIntervalString(dosageData);

  return "Jede " + dosageData.interval + ". Woche: " + intervalString;
}

/**
 * @private
 * @param {Object} dosageData
 * @return {string}
 */
function buildOnceAtMonthMessage(dosageData) {
  const intervalString = buildIntervalString(dosageData);

  return dosageData.data[0].dayofmonth
    ? "Am " + dosageData.data[0].dayofmonth + ". Tag des Monats: " + intervalString
    : "Einmal im Monat: " + intervalString;
}

/**
 * @private
 * @param {Object} dosageData
 * @return {string}
 */
function buildSpecialMonthMessage(dosageData) {
  const intervalString = buildIntervalString(dosageData);
  const intervalValue = dosageData.data.length;

  return intervalValue + " x / Jahr: " + intervalString;
}

/**
 * @private
 * @param {string} stringValue
 * @return {string}
 */
function escape(stringValue) {
  if (!stringValue) {
    return "";
  }

  return stringValue.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/"/g, "&quot;");
}

/**
 * @private
 * @param {int} currentMonthNumber
 * @return {string}
 */
function getMonthName(currentMonthNumber) {
  let month = invert(monthNumber)[currentMonthNumber];
  return monthGermanNames[month];
}

/**
 * @private
 * @param {int} dayNumber
 * @return {string}
 */
function getShortWeekdayName(dayNumber) {
  let day = invert(daysNumber)[dayNumber];
  return daysOfWeekGermanShort[day];
}

/**
 * @private
 * @param {Object} dosageData
 * @return {string}
 */
function buildIntervalString(dosageData) {
  let tmpMonthWeekdayTime;
  let intervalString = "";
  const currentUnit = typeof dosageData.type === "undefined" ? "" : dosageData.unit;

  for (let i = 0, length = dosageData.data.length; i < length; i++) {
    // separator
    if (intervalString.length > 0) {
      intervalString += " - ";
    }

    //  value
    if (!dosageData.data[i].value_as_fraction) {
      intervalString += new Intl.NumberFormat("de-DE", {maximumFractionDigits: 20, useGrouping: false}).format(
        dosageData.data[i].value,
      );
    } else {
      intervalString += calculateFraction.isFloatOrFraction(dosageData.data[i].value);
    }

    // type
    if (currentUnit.length > 0) {
      intervalString += " " + currentUnit;
    }

    // (weekday, time)
    tmpMonthWeekdayTime = [];
    if (dosageData.data[i].month && dosageData.data[i].month > 0) {
      tmpMonthWeekdayTime.push(getMonthName(dosageData.data[i].month));
    }
    if (dosageData.data[i].weekday && dosageData.data[i].weekday > 0) {
      tmpMonthWeekdayTime.push(getShortWeekdayName(dosageData.data[i].weekday));
    }
    if (dosageData.data[i].time && dosageData.data[i].time.length > 0) {
      tmpMonthWeekdayTime.push(dosageData.data[i].time);
    }
    if (tmpMonthWeekdayTime.length) {
      intervalString += " (" + tmpMonthWeekdayTime.join(", ") + ")";
    }
  }

  return intervalString;
}
