<template>
  <component-dialog ref="refCryptDialog" dialog-width="medium">
    <template #title>
      <span v-if="privacy.hasCryptStatus('VERIFICATION_INVALID')">
        Beim Startvorgang für die Verarbeitung fiktiver Fälle ist ein Fehler aufgetreten
      </span>
      <span v-else>Hinweis zu Ihrer MediCheck-Version</span>
    </template>

    <template #content>
      <p>
        <template v-if="isLoading">
          <div class="text-center">
            <component-spinner class="h-12 w-12 mt-8" />
          </div>
        </template>

        <template v-else>
          <template v-if="privacy.hasCryptStatus('VERIFICATION_INVALID')">
            Bitte melden Sie sich bei unserem Support und teilen dort mit, dass Sie eine
            <strong>eingeschränkte Version</strong>
            des MediCheck nutzen und ein
            <strong>Problem bei der Initialisierung der Verschlüsselung</strong>
            aufgetreten ist.
          </template>

          <template v-else>
            Sie verwenden eine Version des MediCheck, in der
            <strong>nur fiktive</strong>
            bzw. anonymisierte oder didaktische
            <strong>und zu Lehr- bzw. Ausbildungszwecken</strong>
            konzipierte oder
            <strong>modifizierte Fälle</strong>
            bearbeitet werden dürfen.
          </template>
        </template>
      </p>
    </template>

    <template #actions>
      <component-button
        :disabled="isLoading || privacy.hasCryptStatus('VERIFICATION_INVALID')"
        class="p4umc-primary"
        label="OK"
        @click="continueWithoutPrivacyPassword()"
        @keydown.enter="continueWithoutPrivacyPassword()"
      />

      <component-button class="p4umc-primary p4umc-flat" label="Abmelden" @click="logout" />
    </template>
  </component-dialog>
</template>

<script>
  import {onMounted, onUnmounted, ref, watch} from "vue";

  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentSpinner from "@components/Spinner.vue";

  export default {
    name: "CryptDialogsEducation",

    components: {ComponentSpinner, ComponentButton, ComponentDialog},

    props: {
      privacy: {
        type: Object,
        required: true,
        default: () => {},
      },
      isLoading: {
        type: Boolean,
        required: true,
        default: false,
      },
      logout: {
        type: Function,
        required: true,
        default: () => {},
      },
    },

    setup(props) {
      const educationPassword = "Holleri du dödel di diri diri dudel dö!";

      const refCryptDialog = ref(null);

      watch(
        () => props.isLoading,
        (newValue) => {
          if (newValue === false) {
            close();
          }
        },
      );

      onMounted(() => {
        props.privacy.plaintextPassword(educationPassword);
      });

      const open = () => {
        refCryptDialog.value.open();
      };

      const close = () => {
        if (refCryptDialog.value) refCryptDialog.value.close();
      };

      const continueWithoutPrivacyPassword = () => {
        if (props.privacy.hasCryptStatus("ENCRYPTION_NOT_SET") || props.privacy.hasCryptStatus("ENCRYPTION_INVALID")) {
          props.privacy.setRecoveryCodeHasBeenPrinted(true);

          setTimeout(() => {
            props.privacy.setPassword();
          }, 1000);
        } else if (props.privacy.hasCryptStatus("VERIFICATION_PENDING")) {
          setTimeout(() => {
            props.privacy.verifyPassword(
              () => {
                props.privacy.initCrypt();
                props.privacy.setEncryptLocalStorage(true);
                props.privacy.setCurrentStatus("VERIFICATION_VALID");
                close();
              },
              () => {
                props.privacy.setCurrentStatus("VERIFICATION_INVALID");
              },
            );
          }, 1000);
        }
      };

      return {refCryptDialog, open, close, continueWithoutPrivacyPassword};
    },
  };
</script>
