<template>
  <component-message display-mode="dashboard" />
  <component-dashboard v-bind="$page.props" />
</template>

<script>
  import {defineComponent} from "vue";

  import ComponentMessage from "@components/Messages/Message.vue";
  import ComponentDashboard from "@pages/Dashboard/Components/Dashboard.vue";

  export default defineComponent({
    name: "PagesDashboardIndex",

    components: {
      ComponentMessage,
      ComponentDashboard,
    },
  });
</script>
