<template>
  <li class="grid grid-cols-12 gap-x-6 p-2">
    <!-- row 1 - col 1 - "Was" + drag and drop icon -->
    <div class="col-span-2" :class="{'h-9': deletable}">
      <template v-if="!deletable">
        <span class="duration-300 -z-1 origin-0 truncate cursor-default text-gray-500 text-sm">Was:</span>
      </template>

      <div class="flex items-center">
        <template v-if="isEditable && dragable">
          <component-tooltip
            placement="right-end"
            tooltip="Zeile verschieben"
            custom-class-label="flex items-center"
            :force-hidden="isDragging"
          >
            <component-icon class="text-gray-900 text-xl drag-area w-5">drag_handle</component-icon>
          </component-tooltip>
        </template>

        <span
          class="text-sm border-b-1 border-gray-300 w-full p-2"
          :class="{'ml-1': dragable, 'border-dashed': !isEditable}"
        >
          {{ form.type ?? "" }}
        </span>
      </div>
    </div>

    <!-- row 1 - col 2 - "Wert (OPT)" -->
    <div class="col-span-3">
      <component-input
        :model-value="form.value"
        :helper-text="referenceText"
        :disabled="fieldsDisabled"
        :label="showValueLabel"
        @input="updateValue"
      />
    </div>

    <!-- row 1 - col 3 - "Einheit (OPT)" -->
    <div class="col-span-2">
      <component-select
        :id="'unit' + form.id"
        :model-value="form.mc_unit_id"
        :disabled="fieldsDisabled"
        :options="form.core.unitList"
        :label="showUnitLabel"
        key-value="id"
        key-name="unit"
        return-type="object"
        @change="updateUnit"
      />
    </div>

    <!-- row 1 - col 4 - "Abweichung (OPT)" -->
    <div class="col-span-2" :class="[{'col-span-3': !isEditable || !deletable}]">
      <component-select
        :id="'deviation' + form.id"
        v-model="form.deviation"
        :label="showDeviationLabel"
        :disabled="fieldsDisabled"
        :options="laborbardeviationValues"
        key-name="name"
      />
    </div>

    <!-- row 1 - col 5 - "Von (Datum)" -->
    <div class="col-span-2" :class="{'col-span-1': isEditable && deletable}">
      <component-full-date-input
        :model-value="form.date"
        :disabled="fieldsDisabled"
        :label="showDateLabel"
        @change="updateDate"
      />
    </div>

    <!-- row 1 - col 6 - "Actions" -->
    <template v-if="isEditable && deletable">
      <div class="col-span-1 px-2 py-1 items-center">
        <component-tooltip placement="left-start" tooltip="Zeile löschen">
          <component-icon-button
            class="text-white bg-gray-500 hover:bg-gray-400 border-gray-500 hover:border-gray-400"
            icon="delete"
            @click="showDeleteConfirmDialog"
          />
        </component-tooltip>
      </div>
    </template>
  </li>
</template>

<script>
  import {computed, inject, nextTick, onBeforeMount, ref, watch} from "vue";
  import {useForm, usePage} from "@inertiajs/vue3";
  import {cloneDeep, debounce} from "lodash";

  import NumberHelper from "@utils/Helpers/NumberHelper.js";

  import ComponentFullDateInput from "@components/Inputs/FullDateInput.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentIconButton from "@components/Buttons/IconButton.vue";
  import ComponentInput from "@components/Inputs/Input.vue";
  import ComponentSelect from "@components/Selects/Select.vue";
  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";

  import {
    getDeviationFromBloodpressureValue,
    getDeviationFromValue,
    getReferenceText,
    getReferenceValue,
  } from "@pages/Records/Components/Sections/LaborBar/Utils/laborvalue.js";
  import {updateCompleteForm} from "@pages/Records/Components/Sections/LaborBar/Utils/formHandler.js";
  import {bloodpressureKey} from "@pages/Records/Components/Sections/LaborBar/enums.js";
  import {laborbardeviationValues} from "@pages/Records/Components/Sections/LaborBar/enums.js";

  export default {
    name: "LaborTableRow",

    components: {
      ComponentIcon,
      ComponentIconButton,
      ComponentTooltip,
      ComponentFullDateInput,
      ComponentInput,
      ComponentSelect,
    },

    props: {
      isEditable: {
        type: Boolean,
        default: false,
      },
      laborvalue: {
        type: Object,
        required: true,
      },
      dragable: {
        type: Boolean,
        default: true,
      },
      deletable: {
        type: Boolean,
        default: true,
      },
      isDragging: {
        type: Boolean,
        default: false,
      },
    },

    setup(props) {
      const page = usePage();

      const deleteDialog = inject("recordEditDeleteRowDialog");

      const fresh = ref(true);
      const form = useForm({
        id: null,
        type: null,
        date: null,
        deviation: null,
        value: null,
        mc_laborvalue_id: null,
        mc_laborvalue_key: null,
        mc_unit_id: null,
        unit: null,
        reference_symptoms: null,
        core: {},
      });

      const fieldsDisabled = computed(() => !props.isEditable || form.processing || !form.core.mc_laborvalue_key);

      const referenceValue = computed(() => getReferenceValue(form.data(), page.props.patient.gender));

      const referenceText = computed(() => {
        return referenceValue.value && form.value ? getReferenceText(referenceValue.value) : null;
      });

      const showValueLabel = computed(() => (!props.deletable ? "Wert (opt.):" : ""));

      const showUnitLabel = computed(() => (!props.deletable ? "Einheit (opt.):" : ""));

      const showDeviationLabel = computed(() => (!props.deletable ? "Abweichung: " : ""));

      const showDateLabel = computed(() => (!props.deletable ? "Von (Datum): " : ""));

      onBeforeMount(() => silentUpdateForm());

      watch(
        () => props.laborvalue,
        () => {
          silentUpdateForm();
        },
        {deep: true},
      );

      watch(
        () => form.data(),
        () => {
          if (!fresh.value) {
            patchLaborvalue();
          }
        },
        {deep: true},
      );

      const silentUpdateForm = () => {
        fresh.value = true;
        updateCompleteForm(form, cloneDeep(props.laborvalue));
        nextTick(() => (fresh.value = false));
      };

      const formatValue = (value) => {
        // Add Slash to Bloodpressure
        if (bloodpressureKey !== form.mc_laborvalue_key) {
          return NumberHelper.formatFloatAsGermanNumberString(NumberHelper.formatStringAsFloat(value));
        }

        // Skip if is empty or valid value
        if (!value || value.length === 0 || value.match(/^[0-9]{2,3} \/ [0-9]{2,3}$/)) return value;

        if ((value.length === 2 && parseInt(value.substring(0, 1)) > 3) || value.length === 3) {
          let lastChar = value.substring(value.length - 1, value.length);

          if (lastChar.match(/[0-9]/)) {
            return value + " / ";
          } else {
            return value.substring(0, 2) + " / ";
          }
        } else if (value.length > 3) {
          let matches = value.match(/([0-9]{2,3})[^0-9]+([0-9]{0,2})/);

          if (matches) {
            return matches[1] + " / " + matches[2] ?? "";
          }
        }

        return value;
      };

      const updateValue = (newValue) => {
        if (newValue && newValue !== "") {
          form.value = formatValue(newValue.toString());

          if (!form.unit && form.core.unitList?.length > 0) {
            form.mc_unit_id = form.core.unitList[0].id;
            form.unit = form.core.unitList[0].unit;
          }
        } else {
          form.value = null;
        }

        if (referenceValue.value) {
          if (form.mc_laborvalue_key === bloodpressureKey) {
            form.deviation = getDeviationFromBloodpressureValue(
              newValue,
              referenceValue.value.low,
              referenceValue.value.high,
            );
          } else {
            form.deviation = getDeviationFromValue(newValue, referenceValue.value.low, referenceValue.value.high);
          }
        }
      };

      const updateUnit = (newUnit) => {
        form.value = null;

        form.mc_unit_id = newUnit.id;
        form.unit = newUnit.unit;
      };

      const showDeleteConfirmDialog = () => {
        deleteDialog({routeName: "laborvalues", payload: {laborvalue: form.id}});
      };

      const patchLaborvalue = debounce(() => {
        if (props.laborvalue.deviation !== form.deviation && props.laborvalue.value === form.value) {
          form.value = "";
        }

        if (!form.isDirty) return;

        if (form.id) {
          form.put(
            route("laborvalues.update", {
              patient: page.props.patient.id,
              record: page.props.record.id,
              laborvalue: form.id,
            }),
            {
              preserveScroll: true,
              only: ["record", "flash"],
            },
          );
        } else {
          form.post(
            route("laborvalues.store", {
              patient: page.props.patient.id,
              record: page.props.record.id,
            }),
            {
              preserveScroll: true,
              only: ["record", "flash"],
            },
          );
        }
      }, 3000);

      const updateDate = (newDate) => {
        form.date = newDate;
        patchLaborvalue();
      };

      return {
        /** enum */
        laborbardeviationValues,

        /** const */
        form,

        /** computed */
        fieldsDisabled,
        referenceText,
        showValueLabel,
        showUnitLabel,
        showDeviationLabel,
        showDateLabel,

        /** function */
        updateValue,
        updateUnit,
        showDeleteConfirmDialog,
        updateDate,
      };
    },
  };
</script>
