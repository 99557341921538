<template>
  <component-breadcrumb class="mt-2" :links="[{label: 'Kontakte'}]" />

  <section class="mt-6">
    <div class="p-4 bg-white shadow-sm">
      <div class="flex items-center justify-between">
        <h2 class="text-2xl font-semibold">Kontakte</h2>
        <component-link-button label="Neuer Kontakt" :href="$route('contacts.create')" class="p4umc-primary self-end" />
      </div>

      <!-- filter :: start -->
      <form @submit.prevent="submitFilter">
        <div class="flex justify-between items-baseline">
          <component-input v-model="form.q.search" autocomplete="off" class="max-w-52" placeholder="Suche">
            <template #icon><component-icon class="text-xl text-gray-600">search</component-icon></template>
          </component-input>

          <component-filter-flags v-model="form.flags" :available-flags="availableFlags" />
        </div>
      </form>
      <!-- filter :: end -->

      <!-- table listing :: start -->
      <article class="mt-4 pt-4">
        <table v-if="contacts.data.length" class="table-auto w-full">
          <thead>
            <contacts-contact-head v-model="form.order" />
          </thead>
          <tbody>
            <template v-if="form.processing">
              <tr>
                <td colspan="12">
                  <div class="w-full grid place-content-center min-h-32">
                    <component-spinner class="h-8 w-8" />
                  </div>
                </td>
              </tr>
            </template>
            <template v-else>
              <contacts-contact-row
                v-for="contact in contacts.data"
                :key="contact.id"
                :contact="contact"
                @open:delete-contact="openDialog(contact.id, 'delete')"
              />
            </template>
          </tbody>
        </table>
        <component-empty-state v-else>
          Speichern Sie Adressen von Ärzten, Pflegediensten oder Heimen, mit denen Sie in Kontakt stehen.
        </component-empty-state>

        <!-- Paginator -->
        <component-pagination v-if="!form.processing && contacts.meta" :links="contacts.meta.links" />
      </article>
      <!-- table listing :: end -->
    </div>
  </section>

  <component-dialog
    ref="deleteDialog"
    dialog-title="Sind Sie sicher?"
    dialog-content="Die ausgewählte Zeile wird gelöscht."
    primary-label="ok"
    @primary-button-clicked="deleteRow"
    @cancel-button-clicked="closeDialog('delete')"
  />
</template>

<script>
  import {ref, watch} from "vue";
  import {router, useForm, usePage} from "@inertiajs/vue3";
  import {debounce} from "lodash";

  import {doctorProfession} from "./Enums/Enums.js";

  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentBreadcrumb from "@components/Breadcrumb/Breadcrumb.vue";
  import ComponentInput from "@components/Inputs/Input.vue";
  import ContactsContactRow from "@pages/Contacts/Components/ContactRow.vue";
  import ComponentPagination from "@components/Pagination/Pagination.vue";
  import ContactsContactHead from "@pages/Contacts/Components/ContactHead.vue";
  import ComponentSpinner from "@components/Spinner.vue";
  import ComponentFilterFlags from "@components/Filter/FilterFlags.vue";
  import ComponentLinkButton from "@components/Buttons/LinkButton.vue";
  import ComponentEmptyState from "@components/EmptyState/EmptyState.vue";

  export default {
    name: "PagesContactsIndex",

    components: {
      ComponentEmptyState,
      ComponentLinkButton,
      ComponentFilterFlags,
      ComponentSpinner,
      ContactsContactHead,
      ComponentPagination,
      ContactsContactRow,
      ComponentInput,
      ComponentBreadcrumb,
      ComponentDialog,
      ComponentIcon,
    },

    props: {
      contacts: {
        type: Object,
        required: true,
      },
      filters: {
        type: Object,
        required: true,
      },
    },

    setup() {
      const page = usePage();

      const deleteDialog = ref(null);
      const selectedId = ref(null);

      const availableFlags = {
        "no-patients": "Keinem Patienten zugeordnet",
      };

      let form = useForm({
        order: page.props?.filters?.order || "lastname",
        filter: typeof page.props?.filters?.filter !== "function" ? page.props?.filters?.filter : null,
        q: {
          search: page.props?.filters?.q?.search || null,
        },
        flags: page.props?.filters?.flags || [],
      });

      watch(
        () => form.q.search,
        debounce(function () {
          submitFilter();
        }, 500),
      );

      watch(
        () => form.flags,
        debounce(function () {
          submitFilter();
        }, 1000),
      );

      watch(
        () => form.order,
        debounce(function () {
          submitFilter();
        }, 500),
      );

      function submitFilter() {
        form.get(route("contacts.index"), {preserveState: true, preserveScroll: true, replace: true});
      }

      const openDialog = (id, dialogType) => {
        selectedId.value = id;

        if (dialogType === "delete") deleteDialog.value.open();
      };

      const closeDialog = (dialogType) => {
        selectedId.value = null;

        if (dialogType === "delete") deleteDialog.value.close();
      };

      const deleteRow = () => {
        router.delete(route("contacts.destroy", {contact: selectedId.value}));
      };

      return {
        deleteDialog,
        doctorProfession,
        form,
        availableFlags,
        submitFilter,
        openDialog,
        closeDialog,
        deleteRow,
      };
    },
  };
</script>
