<template>
  <component-card
    id="patient-contacts-data"
    title="Kontakte"
    subtitle="Ärzte, Pflegedienste oder Heime"
    class="rounded-lg border-2 border-gray-200"
    :class="{'!animate-none': patient?.contacts?.length}"
  >
    <div class="flex justify-end items-baseline space-x-4">
      <component-button label="Hinzufügen" @click="openAddressbook()" />
    </div>

    <ol v-if="patient?.contacts?.length" class="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-8">
      <li v-for="contact in patient.contacts" :key="contact.id" class="relative group">
        <component-contact-card class="h-full min-h-44 hover:bg-gray-100" :contact="contact" />

        <div class="absolute hidden group-hover:flex right-2 bottom-2 flex-row-reverse space-x-4 space-x-reverse">
          <component-icon-button class="p4umc-primary" icon="edit" @click="edit(contact)" />
          <component-icon-button icon="delete" @click="confirmRemove(contact.id)" />
        </div>

        <div v-if="activeEditAddress === contact.id" class="p-2 absolute top-0 left-0 bg-white w-full h-full">
          <component-select
            v-model="editForm.role"
            class="mt-4"
            label="Funktion (optional)"
            :options="assignmentRole"
            :nullable="true"
            placeholder-text=""
            helper-text="Welche Rolle nimmt dieser Kontakt für den Patienten ein?"
          />
          <div class="mt-4 flex flex-row-reverse space-x-reverse space-x-4">
            <component-button
              class="p4umc-primary"
              :disabled="editForm.processing"
              label="Speichern"
              @click="update()"
            />
            <component-button label="Abbrechen" @click="cancelEdit()" />
          </div>
        </div>
      </li>
    </ol>
    <component-empty-state v-else>
      Sie können bestehende Adressen von Ärzten, Pflegediensten oder Heimen aus Ihrem Adressbuch zu diesem Patienten
      hinzufügen.
    </component-empty-state>

    <component-patient-contact-dialog ref="patientContactDialog" :patient="patient" />

    <component-confirmation-dialog
      ref="confirmationDialog"
      title="Kontakt entfernen?"
      content="Die Adresse bleibt unverändert in Ihrem Adressbuch."
      @confirmed="(contactId) => remove(contactId)"
    />
  </component-card>
</template>

<script>
  import {router, useForm} from "@inertiajs/vue3";
  import {inject, ref} from "vue";

  import {assignmentRole} from "@pages/Contacts/Enums/Enums.js";

  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentCard from "@components/Cards/Card.vue";
  import ComponentContactCard from "@components/Cards/ContactCard.vue";
  import ComponentIconButton from "@components/Buttons/IconButton.vue";
  import ComponentSelect from "@components/Selects/Select.vue";
  import ComponentConfirmationDialog from "@components/Dialogs/ConfirmationDialog.vue";
  import ComponentEmptyState from "@components/EmptyState/EmptyState.vue";
  import ComponentPatientContactDialog from "@components/Dialogs/PatientContactDialog.vue";

  export default {
    name: "PatientsPatientContactsData",
    components: {
      ComponentPatientContactDialog,
      ComponentEmptyState,
      ComponentConfirmationDialog,
      ComponentSelect,
      ComponentIconButton,
      ComponentContactCard,
      ComponentCard,
      ComponentButton,
    },
    props: {
      patient: {
        type: Object,
        required: true,
      },
      contacts: {
        type: Object,
        default: () => {},
      },
    },

    setup(props) {
      const broadcast = inject("$broadcast");

      const patientContactDialog = ref(null);
      const confirmationDialog = ref(null);

      const activeEditAddress = ref();

      const form = useForm({
        role: null,
      });

      const editForm = useForm({
        role: null,
      });

      function confirmRemove(contactId) {
        confirmationDialog.value.open(contactId);
      }
      function remove(contactId) {
        form.delete(route("assignment.destroy", {patient: props.patient.id, contact: contactId}), {
          preserveScroll: true,
          onSuccess: () => {
            broadcast.patient.postMessage(props.patient.id, {action: "updated"});
          },
        });
      }

      function openAddressbook() {
        patientContactDialog.value.open();
      }

      function edit(contact) {
        editForm.role = contact.role;
        activeEditAddress.value = contact.id;
      }

      function cancelEdit() {
        activeEditAddress.value = null;
      }

      function update() {
        editForm.patch(route("assignment.update", {patient: props.patient.id, contact: activeEditAddress.value}), {
          preserveScroll: true,
          onSuccess: () => {
            activeEditAddress.value = null;
            broadcast.patient.postMessage(props.patient.id, {action: "updated"});
          },
        });
      }

      return {
        edit,
        cancelEdit,
        openAddressbook,
        update,
        editForm,
        confirmRemove,
        remove,
        patientContactDialog,
        confirmationDialog,
        assignmentRole,
        activeEditAddress,
      };
    },
  };
</script>
