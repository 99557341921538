<template>
  <div ref="dropdown" class="relative">
    <button
      :class="$attrs['class']"
      type="button"
      :aria-controls="id"
      :aria-expanded="visible"
      @click="visible = !visible"
    >
      <slot name="button" />
    </button>

    <!-- Dropdown section, show/hide based on state. -->
    <div
      v-if="visible"
      :id="id"
      class="shadow-md absolute z-10 right-0 mt-1 overflow-visible"
      :class="['bg-white', innerClass, {'right-0': alignment === 'right', 'left-0': alignment === 'left'}]"
    >
      <slot name="dropdown" />
    </div>
  </div>
</template>
<script>
  import {onMounted, onUnmounted, ref} from "vue";

  export default {
    name: "ComponentDropdown",
    inheritAttrs: false,
    props: {
      alignment: {
        type: String,
        default: "right",
        validator: (value) => ["left", "right"].includes(value),
      },
      innerClass: {
        type: String,
        default: "",
      },
    },
    setup() {
      const visible = ref(false);

      const dropdown = ref(null);

      const id = "dropdown-" + Math.random().toString(16).slice(2);

      onMounted(() => {
        window.addEventListener("click", closeDropdownOnOutsideClick);
      });

      onUnmounted(() => {
        window.addEventListener("click", closeDropdownOnOutsideClick);
      });

      function close() {
        visible.value = false;
      }

      const closeDropdownOnOutsideClick = (event) => {
        if (visible.value && !dropdown.value?.contains(event.target)) visible.value = false;
      };

      return {id, dropdown, visible, close};
    },
  };
</script>
