<template>
  <div class="grid grid-cols-12 gap-x-6 text-xs text-gray-700 uppercase font-bold pb-2">
    <div class="col-span-4 ml-2" :class="{'ml-8': isEditable}">Problem / Symptom</div>
    <div class="col-span-2">Seit (Datum; opt.):</div>
    <div class="col-span-2">Vermutung:</div>
    <div class="col-span-3">Warum? / Was?:</div>
    <div class="col-span-1" />
  </div>

  <ul ref="parent">
    <template v-for="symptom in symptoms" :key="symptom.row">
      <template v-if="isLoading">
        <symptom-skeleton-row />
      </template>

      <template v-else>
        <symptom-table-row :is-editable="isEditable" :symptom="symptom" :is-dragging="isDragging" />
      </template>
    </template>
  </ul>
</template>

<script>
  import {ref, watch} from "vue";
  import {usePage, router} from "@inertiajs/vue3";
  import {useDragAndDrop} from "@formkit/drag-and-drop/vue";
  import {isEqual} from "lodash";
  import {handleDragstart, handleTouchstart, swap} from "@formkit/drag-and-drop";

  import SymptomTableRow from "@pages/Records/Components/Sections/SymptomBar/Components/Row.vue";
  import SymptomSkeletonRow from "@pages/Records/Components/Sections/SymptomBar/Components/SkeletonRow.vue";

  export default {
    name: "SymptomTable",

    components: {SymptomSkeletonRow, SymptomTableRow},

    props: {
      isEditable: {
        type: Boolean,
        required: true,
      },
      recordData: {
        type: Object,
        required: true,
      },
      isLoading: {
        type: Boolean,
        required: true,
      },
    },

    setup(props) {
      const page = usePage();
      const isDragging = ref(false);

      const config = {
        dragHandle: "[drag_handle]",
        dropZoneClass: "ring-2 ring-mcred-300",
        plugins: [swap()],
        handleDragstart: (data) => {
          isDragging.value = true;
          handleDragstart(data);
        },
        handleTouchstart: (data) => {
          isDragging.value = true;
          handleTouchstart(data);
        },
      };

      const [parent, symptoms] = useDragAndDrop(props.recordData.symptoms, config);

      watch(
        () => props.recordData.symptoms,
        () => {
          symptoms.value = props.recordData.symptoms;
        },
        {deep: true},
      );

      watch(
        () => symptoms.value,
        (value, oldValue) => {
          if (value.length === oldValue.length) {
            const oldSortOrder = oldValue.map((symptom) => symptom.id);
            const newSortOrder = value.map((symptom) => symptom.id);

            if (!isEqual(oldSortOrder, newSortOrder)) {
              router.post(
                route("sort.store", {
                  patient: page.props.patient.id,
                  record: page.props.record.id,
                  relation: "symptoms",
                }),
                {
                  ids: newSortOrder,
                },
                {
                  preserveScroll: true,
                  only: ["record", "flash"],
                  onFinish: () => {
                    isDragging.value = false;
                  },
                },
              );
            }
          }
        },
      );

      return {symptoms, parent, isDragging};
    },
  };
</script>
