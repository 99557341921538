<template>
  <tr class="border-b-2 border-gray-400 leading-tight">
    <th class="px-0.5 py-2 font-normal text-left align-top">
      <div class="text-xs">Fachrichtung</div>
      <div>
        <button class="flex items-center space-x-2 text-left" @click.prevent="orderBy('lastname')">
          <span>Nachname, Vorname</span>
          <component-icon v-if="!order.includes('lastname')">unfold_more</component-icon>
          <component-icon v-if="order === 'lastname'">expand_less</component-icon>
          <component-icon v-if="order === '-lastname'">expand_more</component-icon>
        </button>
      </div>
    </th>

    <th class="px-0.5 py-2 font-normal text-left align-top">Firma / Praxisname</th>

    <th class="px-0.5 py-2 font-normal text-left align-top">Information</th>

    <th class="hidden 2xl:table-cell px-0.5 py-2 font-normal text-left align-top">E-Mail</th>

    <th class="hidden 2xl:table-cell px-0.5 py-2 font-normal text-left align-top">Tel.-Nr.</th>

    <th class="hidden lg:table-cell px-0.5 py-2 font-normal text-left align-top">
      <button class="flex items-center space-x-2 text-left" @click.prevent="orderBy('city')">
        <span>Adresse</span>
        <component-icon v-if="!order.includes('city')">unfold_more</component-icon>
        <component-icon v-if="order === 'city'">expand_less</component-icon>
        <component-icon v-if="order === '-city'">expand_more</component-icon>
      </button>
    </th>

    <th><!-- Aktionen --></th>
  </tr>
</template>

<script>
  import {ref} from "vue";
  import ComponentIcon from "@components/Icons/Icon.vue";

  export default {
    name: "ContactsContactHead",
    components: {
      ComponentIcon,
    },
    props: {
      modelValue: {
        type: String,
        required: true,
      },
    },
    emits: ["update:modelValue"],

    setup(props, {emit}) {
      const order = ref(props.modelValue || "");

      function orderBy(by) {
        if (order.value.includes(by)) {
          // already sorted by this colum, so flip direction
          if (by.slice(0, 1) === "-") {
            // asc
            order.value = by.slice(1); // remove "-"
          } else {
            // desc
            order.value = "-" + by; // add "-"
          }
        } else {
          // not already sorted by this column, asc is default
          order.value = by;
        }
        emit("update:modelValue", order.value);
      }

      return {orderBy, order};
    },
  };
</script>
