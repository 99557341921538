<template>
  <tr class="border-b-2 border-gray-200 leading-tight">
    <!-- patient -->
    <td class="px-0.5 py-2">
      <div class="font-semibold">{{ patient.lastname }}, {{ patient.firstname }}</div>

      <div>
        <component-tooltip tooltip="Geburtsdatum" :force-inline="true">
          <span class="text-xs" v-text="patient.birthdate" />
        </component-tooltip>
      </div>
    </td>

    <!-- status -->
    <td class="px-0.5 py-2">
      <patients-patient-information :patient="patient" />
    </td>

    <!-- last updates -->
    <td class="px-0.5 py-2">
      {{ patient.deleted_at }}
    </td>

    <!-- actions -->
    <td class="px-0.5 py-2">
      <div class="flex justify-end space-x-4">
        <component-tooltip v-if="$page.props.can.delete_patients" tooltip="Wiederherstellen">
          <component-icon-button class="p4umc-primary" icon="sync" @click="confirmRecover" />
        </component-tooltip>
        <component-tooltip v-if="$page.props.can.delete_patients" tooltip="Löschen">
          <component-icon-button icon="delete" @click="confirmForceDelete" />
        </component-tooltip>
      </div>
    </td>
  </tr>
</template>

<script>
  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";
  import ComponentIconButton from "@components/Buttons/IconButton.vue";
  import PatientsPatientInformation from "@pages/Patients/Components/PatientInformation.vue";

  export default {
    name: "PatientsDeletedPatientRow",

    components: {
      PatientsPatientInformation,
      ComponentIconButton,
      ComponentTooltip,
    },

    props: {
      patient: {
        type: Object,
      },
    },

    emits: ["confirm:forceDelete", "confirm:recover"],

    setup(props, {emit}) {
      function confirmForceDelete() {
        emit("confirm:forceDelete");
      }

      function confirmRecover() {
        emit("confirm:recover");
      }
      return {confirmForceDelete, confirmRecover};
    },
  };
</script>
