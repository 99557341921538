<template>
  <ul class="z-30 fixed flex flex-col bottom-16 right-5 space-y-2 max-w-96">
    <transition-group name="fade">
      <li
        v-for="toast in toasts"
        :key="toast.id"
        class="px-2 py-2 shadow-md animate-fade-in-up self-end border-l-4"
        :class="`${bgColor(toast.type)}`"
      >
        {{ toast.message }}
      </li>
    </transition-group>
  </ul>
</template>

<script>
  import {ref} from "vue";

  export default {
    name: "ComponentToastMessage",

    setup() {
      const toasts = ref([]);

      const bgColor = (type = "info") => {
        const colors = {
          success: "bg-mcgreen-100 border-mcgreen text-mcgreen-900",
          warning: "bg-orange-100 border-orange-400 text-orange-900",
          error: "bg-mcred-100 border-mcred text-mcred-900",
          info: "bg-blue-100 border-blue-600 text-blue-900",
        };

        return colors[type];
      };

      const addToastMessage = (message, type, duration) => {
        const toast = {id: Date.now(), message, type: type};
        toasts.value.push(toast);

        setTimeout(() => {
          removeToast(toast.id);
        }, duration);

        if (toasts.value.length > 5) {
          toasts.value.shift();
        }
      };

      const removeToast = (id) => {
        toasts.value = toasts.value.filter((toast) => toast.id !== id);
      };

      return {toasts, bgColor, addToastMessage};
    },
  };
</script>

<style scoped>
  .fade-leave-active {
    transition:
      transform 0.5s ease-out,
      opacity 0.5s;
  }
  .fade-leave-to {
    opacity: 0;
    transform: translateY(-30px);
  }
</style>
