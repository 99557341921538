<template>
  <form @submit.prevent="save">
    <component-card class="relative group hover:bg-gray-100">
      <div class="flex items-baseline justify-between">
        <h6 class="text-xl">Heimpatient</h6>
        <component-spinner v-if="form.processing" class="size-4" />
        <component-unsaved-changes v-else-if="form.isDirty || form.recentlySuccessful" :form="form" />
        <span v-else class="text-xs text-gray-500">Aktualisiert {{ attribute.updated_at }}</span>
      </div>
      <template v-if="activeEdit">
        <p class="text-sm text-gray-600">
          Die Anschrift der Einrichtung können Sie unten
          <a href="#patient-contacts-data">im Bereich „Kontakte“</a>
          separat verknüpfen.
        </p>

        <div v-if="!patient.is_fake" class="mt-4 grid grid-cols-4 gap-8">
          <component-input
            v-model="form.payload.nursing_home_name"
            :encrypted="true"
            class="col-span-4 md:col-span-2"
            label="Name der Pflegeeinrichtung"
            :validation="form.errors['payload.nursing_home_name']"
          />

          <component-input
            v-model="form.payload.nursing_home_ward"
            :encrypted="true"
            class="col-span-2 md:col-span-1"
            label="Station"
            :validation="form.errors['payload.nursing_home_ward']"
          />

          <component-input
            v-model="form.payload.nursing_home_room"
            :encrypted="true"
            class="col-span-2 md:col-span-1"
            label="Zimmer"
            :validation="form.errors['payload.nursing_home_room']"
          />

          <component-input
            v-model="form.payload.nursing_home_carer"
            :encrypted="true"
            class="col-span-4 md:col-span-2"
            label="Zuständige Pflegefachkraft"
            :validation="form.errors['payload.nursing_home_carer']"
          />
        </div>
        <div v-else>
          Wird in einem Heim betreut.
          <div class="text-sm text-gray-600">
            Details zur Einrichtung können bei geteilten Patienten nicht übermittelt werden.
          </div>
        </div>
      </template>

      <template v-else>
        <div class="mt-0.5">
          <template v-if="patient.is_fake">
            <div class="text-sm text-gray-600">
              Details zur Einrichtung können bei geteilten Patienten nicht übermittelt werden.
            </div>
          </template>
          <template
            v-else-if="
              attribute.payload?.nursing_home_name ||
              attribute.payload?.nursing_home_ward ||
              attribute.payload?.nursing_home_room ||
              attribute.payload?.nursing_home_carer
            "
          >
            <span v-if="attribute.payload?.nursing_home_name">
              <span class="text-gray-500">Name der Pflegeeinrichtung:</span>
              <span :data-encrypted="attribute.payload?.nursing_home_name" />
              <br />
            </span>
            <span v-if="attribute.payload?.nursing_home_ward">
              <span class="text-gray-500">Station:</span>
              <span :data-encrypted="attribute.payload?.nursing_home_ward" />
              &nbsp;
            </span>
            <span v-if="attribute.payload?.nursing_home_room">
              <span class="text-gray-500">Zimmer:</span>
              <span :data-encrypted="attribute.payload?.nursing_home_room" />
            </span>
            <span v-if="attribute.payload?.nursing_home_ward || attribute.payload?.nursing_home_room"><br /></span>
            <span v-if="attribute.payload?.nursing_home_carer">
              <span class="text-gray-500">Zuständige Pflegekraft:</span>
              <span :data-encrypted="attribute.payload?.nursing_home_carer" />
            </span>
          </template>
        </div>

        <div class="absolute hidden group-hover:flex right-2 bottom-2 flex-row-reverse space-x-4 space-x-reverse">
          <component-icon-button type="button" class="p4umc-primary" icon="edit" @click="activeEdit = true" />
          <component-icon-button type="button" icon="delete" @click="confirmRemove()" />
        </div>
      </template>

      <template v-if="activeEdit" #actions>
        <component-button class="p4umc-primary" label="Speichern" :disabled="form.processing" />
        <component-button type="button" label="Abbrechen" :disabled="form.processing" @click="cancelEdit()" />
      </template>
    </component-card>
  </form>

  <component-confirmation-dialog
    ref="confirmationDialog"
    title="Sind Sie sicher?"
    content="Die Angabe „Heimpatient“ wird gelöscht."
    @confirmed="remove()"
  />
</template>

<script>
  import ComponentCard from "@components/Cards/Card.vue";
  import {useForm} from "@inertiajs/vue3";
  import ComponentUnsavedChanges from "@components/Alerts/UnsavedChanges.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import {computed, inject, ref, watch} from "vue";
  import ComponentConfirmationDialog from "@components/Dialogs/ConfirmationDialog.vue";
  import ComponentInput from "@components/Inputs/Input.vue";
  import ComponentIconButton from "@components/Buttons/IconButton.vue";
  import ComponentSpinner from "@components/Spinner.vue";

  export default {
    name: "PatientsPatientAttributesNursingHomeAttribute",
    components: {
      ComponentSpinner,
      ComponentIconButton,
      ComponentInput,
      ComponentConfirmationDialog,
      ComponentButton,
      ComponentUnsavedChanges,
      ComponentCard,
    },
    props: {
      attribute: Object,
      patient: Object,
      recentlyAdded: {
        type: Boolean,
        default: false,
      },
    },

    setup(props) {
      const broadcast = inject("$broadcast");

      const confirmationDialog = ref(null);
      const activeEdit = ref(props.recentlyAdded);

      watch(
        () => props.recentlyAdded,
        (newValue) => {
          activeEdit.value = newValue;
        },
      );

      const form = useForm({
        type: props.attribute.type,
        payload: {
          nursing_home_name: props.attribute?.payload?.nursing_home_name,
          nursing_home_ward: props.attribute?.payload?.nursing_home_ward,
          nursing_home_room: props.attribute?.payload?.nursing_home_room,
          nursing_home_carer: props.attribute?.payload?.nursing_home_carer,
        },
      });

      function cancelEdit() {
        // was recently added AND was NOT saved once
        if (props.recentlyAdded && !form.wasSuccessful) {
          remove();
        } else {
          form.reset();
          activeEdit.value = false;
        }
      }

      function save() {
        form.put(route("attributes.update", {patient: props.patient.id, attribute: props.attribute.id}), {
          preserveScroll: true,
          onSuccess: () => {
            activeEdit.value = false;
            broadcast.patient.postMessage(props.patient.id, {action: "updated"});
          },
        });
      }

      function confirmRemove() {
        confirmationDialog.value.open();
      }

      function remove() {
        form.delete(route("attributes.destroy", {patient: props.patient.id, attribute: props.attribute.id}), {
          preserveScroll: true,
          onSuccess: () => {
            broadcast.patient.postMessage(props.patient.id, {action: "updated"});
          },
        });
      }

      return {
        activeEdit,
        cancelEdit,
        form,
        save,
        confirmRemove,
        remove,
        confirmationDialog,
      };
    },
  };
</script>
