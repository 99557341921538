<template>
  <patientinput-component-step title="Wünsche und Ziele">
    <p>Was sind Ihre Wünsche und Ziele für die Medikationsberatung und das Gespräch?</p>

    <component-checkbox
      v-for="(label, key, index) in wishesAims"
      :key="index"
      large
      :model-value="isSelected(key)"
      color="primary"
      @change="(event) => checkboxChanged(key, event)"
    >
      {{ label }}
    </component-checkbox>
  </patientinput-component-step>
</template>

<script>
  import {wishesAims} from "@pages/Patientinput/Enums.js";

  import ComponentCheckbox from "@components/Checkboxes/Checkbox.vue";
  import PatientinputComponentStep from "@pages/Patientinput/Components/Step.vue";

  export default {
    name: "PatientinputComponentStepWishesAims",

    components: {
      ComponentCheckbox,
      PatientinputComponentStep,
    },

    props: {
      form: {
        type: Object,
        required: true,
      },
    },

    setup(props) {
      const isSelected = (key) => props.form.wishes_aims.some((iKey) => iKey === key);

      const checkboxChanged = (key, selected) => {
        props.form.wishes_aims = props.form.wishes_aims.filter((iKey) => iKey !== key);

        if (selected) {
          props.form.wishes_aims.push(key);
        }
      };

      return {wishesAims, isSelected, checkboxChanged};
    },
  };
</script>
