import {isEqual, isEqualWith, omit, pick} from "lodash";
import additionalInformationModel from "@models/additionalInformationModel.js";
import deviantMedicationModel from "@models/deviantMedicationModel.js";
import dosageAlternativeModel from "@models/dosageAlternativeModel.js";

export function checkAllInfos(medicationForm) {
  if (medicationForm.additionalinformation !== null && medicationForm.additionalinformation.discontinued) {
    return false;
  }

  if (medicationForm.dosage) {
    const checkDosageInfoResult = checkDosageInfo(medicationForm.dosage);

    if (checkDosageInfoResult) {
      return true;
    }
  }

  if (medicationForm.additionalinformation !== null) {
    const checkAdditionalInformationResult = checkAdditionalInformation(medicationForm.additionalinformation);

    if (checkAdditionalInformationResult) {
      return true;
    }
  }

  if (medicationForm.deviantmedication !== null && medicationForm.additionalinformation !== null) {
    const checkDeviantMedicationResult = checkDeviantMedication(
      medicationForm.deviantmedication,
      medicationForm.additionalinformation,
    );

    if (checkDeviantMedicationResult) {
      return true;
    }
  }

  if (medicationForm.deviantmedication !== null && medicationForm.deviantmedication.dosage !== null) {
    const checkDeviantMedicationDosageResult = checkDeviantMedicationDosageAlternative(
      medicationForm.deviantmedication.dosage,
    );

    if (checkDeviantMedicationDosageResult) {
      return true;
    }
  }

  return false;
}

export function checkMedicationDosageDetailDialog(medicationForm) {
  if (
    medicationForm.dosage !== null &&
    medicationForm.dosage.dosagealternative !== null &&
    !isEqual(omit(medicationForm.dosage.dosagealternative, ["id"]), omit(dosageAlternativeModel, ["id"]))
  ) {
    return true;
  }

  return medicationForm.additionalinformation !== null && medicationForm.additionalinformation.discontinued;
}

export function checkAdditionalInformationDetailDialog(medicationForm) {
  if (medicationForm.dosage) {
    const checkDosageInfoResult = checkDosageInfo(medicationForm.dosage);

    if (checkDosageInfoResult) {
      return true;
    }
  }

  if (medicationForm.additionalinformation !== null) {
    const checkAdditionalInformationResult = checkAdditionalInformation(medicationForm.additionalinformation);

    if (checkAdditionalInformationResult) {
      return true;
    }
  }

  return false;
}

export function checkDeviantMedicationDetailDialog(medicationForm) {
  if (medicationForm.deviantmedication !== null) {
    const checkDeviantMedicationResult = checkDeviantMedication(
      medicationForm.deviantmedication,
      medicationForm.additionalinformation,
    );

    if (checkDeviantMedicationResult) {
      return true;
    }
  }

  if (medicationForm.deviantmedication !== null && medicationForm.deviantmedication.dosage !== null) {
    const checkDeviantMedicationDosageResult = checkDeviantMedicationDosageAlternative(
      medicationForm.deviantmedication.dosage,
    );

    if (checkDeviantMedicationDosageResult) {
      return true;
    }
  }

  return false;
}

function checkDosageInfo(medicationDosage) {
  return medicationDosage.info !== null;
}

function checkAdditionalInformation(additionalInformation) {
  if (Array.isArray(additionalInformation.source) && additionalInformation.source.length > 0) {
    return true;
  }

  if (Array.isArray(additionalInformation.sideeffects) && additionalInformation.sideeffects.length > 0) {
    return true;
  }

  if (
    !isEqual(
      pick(additionalInformation, ["decreed", "requirements", "since", "to"]),
      pick(additionalInformationModel, ["decreed", "requirements", "since", "to"]),
    )
  ) {
    return true;
  }

  return false;
}

function checkDeviantMedication(deviantMedication, additionalInformation) {
  if (additionalInformation.satisfaction !== null || additionalInformation.satisfactioncomment !== null) {
    return true;
  }

  if (!additionalInformation.compliance) {
    return true;
  }

  if (
    !isEqualWith(
      omit(deviantMedication, [
        "id",
        "dosage.id",
        "dosage.selected_unit",
        "dosage.selected_unit_key_doe",
        "dosage.dosagealternative",
      ]),
      omit(deviantMedicationModel, [
        "id",
        "dosage.id",
        "dosage.selected_unit",
        "dosage.selected_unit_key_doe",
        "dosage.dosagealternative",
      ]),
      customizer,
    )
  ) {
    return true;
  }

  return false;
}

function checkDeviantMedicationDosageAlternative(deviantMedication) {
  return (
    deviantMedication.dosagealternative !== null &&
    !isEqual(omit(deviantMedication.dosagealternative, ["id"]), omit(dosageAlternativeModel, ["id"]))
  );
}

function customizer(objValue, other, key) {
  if (key === "morning" || key === "noon" || key === "evening" || key === "night") {
    return objValue === 0 && other === null;
  }
}
