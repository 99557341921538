<template>
  <inertia-link
    v-if="href !== null"
    :href="href"
    class="uppercase font-semibold inline-block px-4 py-3 rounded-t-md hover:no-underline"
    :class="{
      'disabled:bg-gray-200 disabled:text-gray-400 bg-mcred text-white hover:bg-mcred-450': active,
      'disabled:text-gray-400 bg-white text-mcred hover:bg-gray-100': !active
    }"
    :disabled="disabled"
  >
    <slot/>
  </inertia-link>
  <button
    v-else
    class="uppercase font-semibold inline-block px-4 py-3 rounded-t-md"
    :class="{
      'disabled:bg-gray-200 disabled:text-gray-400 bg-mcred text-white hover:bg-mcred-450': active,
      'disabled:text-gray-400 bg-white text-mcred hover:bg-gray-100': !active
    }"
    :disabled="disabled"
    @click="click"
  >
    <slot/>
  </button>
</template>

<script>
  import {Link as InertiaLink} from "@inertiajs/vue3";
  export default {
    name: "TabButton",
    components: { InertiaLink },
    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
      active: {
        type: Boolean,
        default: false,
      },
      href: {
        type: String,
        default: null,
      },
    },

    emits: ["click"],

    setup(props, {emit}) {
      function click() {
        emit("click");
      }

      return {click};
    },
  };
</script>
