<template>
  <div
    v-if="!form.processing && (form.isDirty || form.recentlySuccessful)"
    class="text-sm self-center flex items-center space-x-1">
    <template v-if="form.isDirty">
      <component-icon class="text-mcred">warning_amber</component-icon>
      <span>Ungespeicherte Änderungen</span>
    </template>
    <template v-else>
      <component-icon class="text-mcgreen">check_circle</component-icon>
      <span>Gespeichert</span>
    </template>
  </div>
</template>

<script>
  import ComponentIcon from "@components/Icons/Icon.vue";

  export default {
    name: "ComponentUnsavedChanges",
    components: {ComponentIcon},
    props: {
      form: {
        type: Object,
        default: () => {
          return {
            processing: false,
            isDirty: false,
            recentlySuccessful: false,
          };
        },
      },
    },
  };
</script>
